import React, { useEffect, useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import BasicModal from 'components/Templates/BasicModal/BasicModal';
import { sleep } from 'utils/utils';

interface AttendancePhotoModalSchema {
  isModalOpen: boolean;
  closeModal: () => void;
  photoUri: string;
  title: string;
}

function AttendancePhotoModal({
  isModalOpen,
  closeModal,
  title,
  photoUri,
}: AttendancePhotoModalSchema) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [isModalOpen]);

  const handleImageLoad = async () => {
    await sleep(150);
    setImageLoaded(true);
  };

  return (
    <Modal
      modalClassName="modal__main modal__attendance__photo"
      id="attendance-photo-modal"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <BasicModal title="Visor de galería" closeModal={closeModal}>
        <div className="attendance__photo__modal__container">
          <img
            src={photoUri}
            alt="No se encontró la foto en la BD"
            onLoad={handleImageLoad}
          />

          <div
            className={`attendance__photo__subtitle ${
              imageLoaded ? 'show' : ''
            }`}
          >
            {title}
          </div>
        </div>
      </BasicModal>
    </Modal>
  );
}

export default AttendancePhotoModal;
