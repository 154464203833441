import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import settingsReducer from 'redux/reducers/settingsReducer';
import usersReducer from 'redux/reducers/usersReducer';
import taskersReducer from 'redux/reducers/taskersReducer';
import tasksReducer from 'redux/reducers/tasksReducer';
import authReducer from 'redux/reducers/authReducer';
import alertMessageReducer from 'redux/reducers/alertMessage/reducer';
import helperReducer from 'redux/reducers/helper/reducer';
import campaignsReducer from './campaignsReducer';

const rootReducer = (history: any) =>
  combineReducers({
    campaigns: campaignsReducer,
    settings: settingsReducer,
    users: usersReducer,
    taskers: taskersReducer,
    tasks: tasksReducer,
    auth: authReducer,
    alert: alertMessageReducer,
    helper: helperReducer,
    router: createRouterReducer(history),
  });

export default rootReducer;
