/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import useForm from 'hooks/useForm';
import TextInput from 'components/Atoms/TextInput/TextInput';
import Select from 'components/Atoms/Select/Select';
import { AdditionalProperty, ITypesList, RUT_TYPES } from 'utils/utils';
import TaskersService from '../services/TaskersService';
import { toast } from 'react-toastify';
import FieldConfigSchema from 'models/AdditionalFieldsVechile';
import i18next from 'i18next';
import CustomTextInput from './CustomTextInput';
import CustomFileInput from './CustomFileInput';
import CustomDateInput from './CustomDateInput';

interface VehicleFormInterface {
  data: any;
  vehiclesTypeList: ITypesList[];
  additionalFieldsVehicle: any;
  additionalProperties: AdditionalProperty[];
  setAdditionalProperties: (additionalProperties: AdditionalProperty[]) => void;
  uploadFile: (file: File) => Promise<string | null>;
  onSubmit: (e: any) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  transport_type: {
    text: '',
    value: '',
  },
  model: '',
  brand: '',
  year: '',
  patent: '',
  userId: '',
};

const menuOptions = [
  {
    label: 'Ver',
    onClick: () => console.log('Descargar clicked!'),
  },
  {
    label: 'Adjuntar',
    onClick: () => console.log('Eliminar clicked!'),
  },
];
const VehicleForm = ({
  vehiclesTypeList,
  data = {},
  additionalFieldsVehicle,
  additionalProperties = [],
  setAdditionalProperties,
  uploadFile,
  onSubmit,
}: VehicleFormInterface) => {
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  let vechileTypeLista: any;
  if (data.vehicle) {
    vechileTypeLista = vehiclesTypeList.find(
      (vehiclesTypeList: any) =>
        vehiclesTypeList.value === data.vehicle.vehicleTypeId
    );
  }
  const { form, onChange, validFields } = useForm(
    data
      ? {
          transport_type: {
            text: data.vehicle ? vechileTypeLista.text : '',
            value: data.vehicle ? data.vehicle.vehicleTypeId : '',
          },
          brand: data.vehicle ? data.vehicle.brand : '',
          model: data.vehicle ? data.vehicle.model : '',
          year: data.vehicle ? data.vehicle.year : '',
          patent: data.vehicle ? data.vehicle.year : '',
          additionalFieldsVehicle: data.additionalFields,
        }
      : INITIAL_EMPTY_FORM_VALUES
  );
  const renderAdditionalField = (fieldConfigSchema: FieldConfigSchema) => {
    const label =
      i18next.language === 'es'
        ? fieldConfigSchema.titleEs
        : fieldConfigSchema.titleEn;
    const placeholder =
      i18next.language === 'es'
        ? fieldConfigSchema.placeholderEs
        : fieldConfigSchema.placeholderEn;
    const evalValidation = fieldConfigSchema.evalScriptJsValidation || '';
    const evalErrorMessage =
      (i18next.language === 'es'
        ? fieldConfigSchema.errorMessageEs
        : fieldConfigSchema.errorMessageEn) || '';

    switch (fieldConfigSchema.fieldTypeId) {
      case 'TEXT_INPUT':
        return (
          <CustomTextInput
            key={`${fieldConfigSchema.id}`}
            id={fieldConfigSchema.id}
            label={label}
            placeholder={placeholder}
            additionalProperties={additionalProperties}
            errors={errors}
            setAdditionalProperties={setAdditionalProperties}
            setErrors={setErrors}
            isRequired={fieldConfigSchema.isRequired}
            evalValidation={evalValidation}
            evalErrorMessage={evalErrorMessage}
            disabled={false}
          />
        );
      case 'DATE':
        return (
          <CustomDateInput
            key={`${fieldConfigSchema.id}`}
            label={label}
            id={fieldConfigSchema.id}
            placeholder={placeholder}
            disabled={false}
            errors={errors}
            setErrors={setErrors}
            additionalProperties={additionalProperties}
            setAdditionalProperties={setAdditionalProperties}
            evalValidation={evalValidation}
            evalErrorMessage={evalErrorMessage}
            isRequired={fieldConfigSchema.isRequired}
          />
        );
      case 'FILE':
        return (
          <CustomFileInput
            id={fieldConfigSchema.id}
            key={`${fieldConfigSchema.id}`}
            additionalProperties={additionalProperties}
            text={label}
            
            uploadFile={uploadFile}
            setAdditionalProperties={setAdditionalProperties}
          />
        );
      default:
        return (
          <div
            key={`${fieldConfigSchema.id}`}
            style={{
              textAlign: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            Input not supported: [ID]{' '}
            {`${fieldConfigSchema.id} - [NAME] ${label}`}
          </div>
        );
    }
  };
  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const additionalFields: { fieldId: number; data: string | null }[] =
        additionalProperties.map((additionalProperty) => {
          return {
            fieldId: additionalProperty.fieldSchemaId,
            data: additionalProperty.value,
          };
        });
      const sendData = {
        vehicle: {
          userId: data.profile.userId,
          vehicleTypeId: form.transport_type.value,
          brand: form.brand,
          model: form.model,
          vehicleYear: form.year,
        },
        additionalFields,
      };
      let isSuccessfully = false;
      if (data) {
        const response = await TaskersService.updateTasker(
          data.profile.userId,
          sendData
        );
        if (response.statusCode === 200) {
          isSuccessfully = true;
        }
      }
      if (isSuccessfully) {
        toast.success('Se actualizaron los datos del tasker correctamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit(form);
        //history('/tasks');
      } else {
        toast.warn(
          '¡UPS! No se pudo actualizar el tasker, inténtalo nuevamente por favor',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }
    }
  };
  return (
    <div className="tasker-form__main">
      <form method="POST" onSubmit={onSubmitForm} id="tasker-selected-form-1">
        <div className="fields-group fields-group--cols-2 fields-group--rows-2">
          <div className="field-item">
            <Select
              data={vehiclesTypeList}
              errors={errors}
              label={'Tipo de transporte'}
              name="transport_type"
              onChange={({ value }) => {
                if (value) onChange(value, 'transport_type');
                else onChange('', 'transport_type');
              }}
              value={form.transport_type}
              placeholder={'Selecciona un tipo de transporte'}
              required
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Marca'}
              name="brand"
              onChange={({ target }) => onChange(target.value, 'brand')}
              placeholder={'Escribe la marca'}
              value={form.brand}
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Modelo'}
              name="model"
              onChange={({ target }) => onChange(target.value, 'model')}
              placeholder={'Escribe el modelo'}
              value={form.model}
            />
          </div>
          {/*<div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Año'}
              name="year"
              onChange={({ target }) => onChange(target.value, 'year')}
              placeholder={'Escribe el año'}
              value={form.year}
            />
            </div>*/}
          {additionalFieldsVehicle.map((fieldConfigSchema: any) =>
            renderAdditionalField(fieldConfigSchema)
          )}

          {/*           {showAddtionFields().map((element, index) => {
            if (element[index].fieldTypeId === 'TEXT_INPUT') {
              setValue(element[index].dataValue);
              return (
                <div className="field-item" key={element[index].id}>
                  <TextInput
                    errors={errors}
                    inputProps={{ required: true }}
                    label={element[index].titleEs}
                    name={element[index].titleEs}
                    onChange={({ target }) => {
                      handleChange(target.value, element[index].id);
                    }}
                    placeholder={element[index].placeholderEs}
                    value={value}
                  />
                </div>
              );
            } else if (element[index].fieldTypeId === 'DATE') {
              return (
                <div className="field-item" key={element[index].id}>
                  <DatePicker
                    label={element[index].titleEs}
                    placeholder={element[index].placeholderEs}
                    value={
                      element[index].dataValue ? element[index].dataValue : null
                    }
                    onChange={(newValue?: Date | null) => {
                      if (newValue) onChange(newValue.toString(), 'year');
                      else onChange('', 'year');
                    }}
                  />
                </div>
              );
            } else if (element[index].fieldTypeId === 'FILE') {
              return (
                <div className="field-item" key={element[index].id}>
                  <DocItem
                    text={element[index].titleEs}
                    menuOptions={menuOptions}
                  />
                </div>
              );
            }
          })} */}
        </div>
        {/*         <div className="documents__main">
          <h2>Documentos</h2>
          <div className="fields-group fields-group--cols-2 fields-group--rows-2">
            <div className="field-item">
              <DocItem
                text="Licencia de conducir clase B"
                menuOptions={menuOptions}
              />
            </div>
            <div className="field-item">
              <DocItem
                text="Licencia de conducir clase C"
                menuOptions={menuOptions}
              />
            </div>
            <div className="field-item">
              <DocItem
                text="Permiso de circulación"
                menuOptions={menuOptions}
              />
            </div>
            <div className="field-item">
              <DocItem text="Revisión técnica" menuOptions={menuOptions} />
            </div>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default VehicleForm;
