import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import { UpdateCheckStatesManually } from 'scenes/Kit/Attendances/components/EditAttendanceModal';

const AttendancesService = {
  getAttendanceForTitaskByUserTurnKey: async (
    userId: number,
    turnId: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-data-for-titask-by-user-turn-key`,
        { userId, turnId }
      );
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendanceByUserTurnKey: async (
    userId: number,
    turnId: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-data-for-kit-by-user-turn-key`,
        { userId, turnId }
      );
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadAttendanceTemplateWithParams: async (
    countryCode: string,
    startDate: string,
    endDate: string,
    storeId?: number,
    taskId?: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-report-for-kit/${countryCode}`,
        {
          startDate,
          endDate,
          storeId,
          taskId,
        },
        { responseType: 'blob' }
      );
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadAttendanceTemplate: async (
    countryCode: string
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-report-for-kit/${countryCode}`,
        {
          startDate: '2022-01-01',
          endDate: '2022-12-31',
          page: 1,
          pageSize: 20,
          sortField: 'STARTED_DATE',
          sortDirection: 'DESC',
        },
        { responseType: 'blob' }
      );
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendances: async (
    countryCode: string,
    startDate: string,
    endDate: string,
    page: number,
    pageSize: number,
    term?: string,
    storeId?: number,
    taskId?: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-data-for-kit/${countryCode}`,
        { startDate, endDate, page, pageSize, term, storeId, taskId }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendancesList: async (countryCode: string): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user-turn/get-attendance-data-for-kit/${countryCode}`,
        {
          startDate: '2022-01-01',
          endDate: '2022-12-31',
          page: 1,
          pageSize: 20,
          sortField: 'STARTED_DATE',
          sortDirection: 'DESC',
        }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateTimeCheck: async (
    payload: UpdateCheckStatesManually
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.put(
        `/user-turn/update-check-states-manually`,
        payload
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
};

export default AttendancesService;
