import React from 'react';
import { Link } from '@mui/material';
import FileUpload from 'components/Atoms/FileUpload/FileUpload';
import Text from 'components/Atoms/Text/Text';

import './ImportTaskerModal.scss';

interface ImportTaskerModalInterface {
  fileOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  importOnClick?: () => void;
  downloadTemplateOnClick: () => void;
  downloadRegisteredUsersReportOnClick: () => void;
  acceptFiles?: string;
}

const ImportTaskerModal = ({
  fileOnChange,
  importOnClick,
  downloadTemplateOnClick,
  downloadRegisteredUsersReportOnClick,
  acceptFiles = '*',
}: ImportTaskerModalInterface) => {
  return (
    <div className="import-schedule__main">
      <Text
        className="import-schedule-title"
        text="1. Descargar reporte de usuarios inscritos"
      />
      <Text
        className="import-schedule-text"
        text={
          <>
            Una vez descargado el reporte, seleccione los identificadores (ids)
            de los taskers y los turnos a los cuales tiene interés por aprobar.
            {'  '}
            <Link
              onClick={downloadRegisteredUsersReportOnClick}
              className={'cursor-pointer-link'}
            >
              Descargar reporte
            </Link>
          </>
        }
      />
      <Text className="import-schedule-title" text="2. Descargar plantilla" />
      <Text
        className="import-schedule-text"
        text={
          <>
            Una vez descargada la plantilla excel, remplazar los datos de prueba
            con los ids de los taskers y turnos previamente seleccionados en el
            paso 1.{'  '}
            <Link
              onClick={downloadTemplateOnClick}
              className={'cursor-pointer-link'}
            >
              Descargar plantilla
            </Link>
          </>
        }
      />
      <Text className="import-schedule-title" text="3. Cargar Archivo" />
      <Text
        className="import-schedule-text"
        text="Seleccione el archivo con los datos ingresados, luego de clic en subir información."
      />
      <FileUpload
        onChange={fileOnChange}
        withTextField
        importOnClick={importOnClick}
        acceptFiles={acceptFiles}
      />
    </div>
  );
};

export default ImportTaskerModal;
