import { get } from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';

const PaymentService = {
  getPaymentsList: async (): Promise<BffApiResponse> => {
    try {
      const response = await get(`payment-type`);
      return new BffApiResponse(true, response.data.data);
    } catch (error: any) {
      return formattingTitaskBffResponseErrors(error);
    }
  },
};

export default PaymentService;
