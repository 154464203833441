import React from 'react';
import { ReactComponent as HomeImage } from 'assets/images/menu/Vector 8538.svg';
import { ReactComponent as SettingsImage } from 'assets/images/menu/setting.svg';
import { ReactComponent as Megaphone } from 'assets/images/menu/megaphone.svg';
import { ReactComponent as Employee } from 'assets/images/menu/employee.svg';

export interface ItemsListInterface {
  text: string;
  icon?: any;
  path: string;
  show?: any;
  allSimilarRoutes?: boolean;
}

export interface NavbarInferface {
  auth: {
    country_iso: string;
  };
  withIcon?: boolean;
  notAuth?: boolean;
  logOutOnClick: () => void;
}

export const ITEM_LIST: ItemsListInterface[] = [
  {
    text: 'Inicio',
    path: 'home',
    show: [],
  },
  {
    text: 'Convocatorias',
    path: 'go/announcements',
    show: ['GBT_SHOW_ANNOUNCEMENTS'],
    allSimilarRoutes: true,
  },
  {
    text: 'Empleados',
    path: 'go/assigned-users',
    show: ['GBT_SHOW_ASSIGNED_APPLICANTS'],
    allSimilarRoutes: true,
  },
  {
    text: 'Ajustes',
    path: 'go/settings',
    show: ['GBT_SHOW_SETTINGS'],
    allSimilarRoutes: true,
  },
];

export const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'home':
      return <HomeImage />;
    case 'go/announcements':
      return <Megaphone />;
    case 'go/assigned-users':
      return <Employee />;
    default:
      return <SettingsImage />;
  }
};
