import { AxiosError, AxiosRequestConfig } from 'axios';
import instance from './axios';
import i18n from 'i18next';
import moment from 'moment/moment';
import 'moment-timezone';

const setUpInterceptor = (store: any) => {
  const handleError = async (error: AxiosError) => {
    return Promise.reject(error);
  };

  instance.interceptors.request.use(
    async (config: any | AxiosRequestConfig) => {
      config.headers.lang = i18n.language.toLowerCase();
      config.headers.timezone = moment.tz.guess();

      const storeToken = store.getState().auth.token;
      const token = storeToken ? storeToken : localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }
  );

  instance.interceptors.response.use((response) => response, handleError);
};

export default setUpInterceptor;
