import { DataInterface } from 'components/Atoms/Select/Select';
import { useSelector } from 'react-redux';

export const getPositionText = (positionId: string) => {
  const positionsList = useSelector((state: any) => state.helper.positions);
  const newPosition = positionsList.find(
    (position: any) => position.id === positionId
  );
  return newPosition?.titleEs ? newPosition.titleEs : '';
};

export const transformDataForSelect = (data: any): DataInterface[] => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.nameEs,
      value: row.key,
    };
    newData.push(newRow);
  });
  console.log(newData);
  return newData;
};
