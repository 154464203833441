import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AdditionalProperty } from './AdditionalProperty';
import { t } from '../../../utils/i18n';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';

interface CustomDateInputSchema {
  label: string;
  id: number;
  placeholder: string;
  disabled: boolean;
  isRequired?: boolean;
  errors: any;
  setErrors: (errors: any) => void;
  additionalProperties: AdditionalProperty[];
  setAdditionalProperties: (additionalProperties: AdditionalProperty[]) => void;
  evalValidation: string;
  evalErrorMessage: string;
}

const CustomDateInput = ({
  id,
  label,
  placeholder,
  disabled,
  isRequired,
  errors,
  setErrors,
  additionalProperties,
  setAdditionalProperties,
  evalValidation,
  evalErrorMessage,
}: CustomDateInputSchema): JSX.Element => {
  const name = `additional_input_${id}`;
  const [value, setValue] = useState<Date | null>(null);

  useEffect(() => {
    const property = additionalProperties.find((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });

    if (property && property.value) {
      const date = new Date(property.value);
      setValue(date);
      /*const currentValue = moment(property.value, 'YYYY-MM-DD');

            if(currentValue.isValid()){
                setValue(currentValue);
            }else{
                console.error("Invalid date => ", id, label, property.value);
            }*/
    }
  }, []);

  const handleFromDate = (newValue: Date | null) => {
    setValue(newValue);

    const propertyIdx = additionalProperties.findIndex((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });
    if (propertyIdx < 0) {
      additionalProperties.push({
        fieldSchemaId: id,
        value: newValue ? newValue.toLocaleDateString() : null,
      });
      const newAdditionalProperties: AdditionalProperty[] = [
        ...additionalProperties,
      ];
      setAdditionalProperties(newAdditionalProperties);
    } else {
      const newAdditionalProperties: AdditionalProperty[] = [
        ...additionalProperties,
      ];
      newAdditionalProperties[propertyIdx] = {
        fieldSchemaId: id,
        value: newValue ? newValue.toLocaleDateString() : null,
      };

      setAdditionalProperties(newAdditionalProperties);
    }
  };

  return (
    <DatePicker
      label={label}
      placeholder={placeholder}
      value={value ? value : null}
      onChange={handleFromDate}
    />
  );
};

export default CustomDateInput;
