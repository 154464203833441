import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnInterface } from 'components/Organisms/Table/Table.utils';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'components/Atoms/Button/Button';
import { DataInterface } from 'components/Atoms/Select/Select';

export const columns: ColumnInterface[] = [
  {
    id: 'name',
    prop: 'name',
    sort: false,
    label: 'Nombre y Apellido',
  },
  {
    id: 'role',
    prop: 'role',
    sort: false,
    label: 'Rol',
  },
  {
    id: 'charge',
    prop: 'charge',
    sort: false,
    label: 'Puesto',
  },
  {
    id: 'status',
    prop: 'status',
    sort: false,
    label: 'Estado',
    type: 'status',
  },
];

export const OptionsTable = () => {
  const history = useNavigate();
  const createUsersOnClick = () => {
    history('/task/create');
  };

  return (
    <div className="users-options">
      <Button
        onClick={createUsersOnClick}
        text={'Crear Usuario'}
        type="primary"
        startIcon={<AddIcon />}
        variant="contained"
        border="square"
      />
    </div>
  );
};

export const transformDataForSelect = (data: any) => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.name,
      value: row.id,
    };
    newData.push(newRow);
  });

  return newData;
};
