import React from 'react';

import './Container.scss';
import { Container as MaterialContainer } from '@material-ui/core';

interface ContainerInterface {
  maxWidth?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined;
  children: any;
}

export const Container = ({
  maxWidth = 'lg',
  children,
}: ContainerInterface) => {
  return <MaterialContainer maxWidth={maxWidth}>{children}</MaterialContainer>;
};
