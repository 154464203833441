import {
  SAVE,
  FETCH,
  UPDATE,
  REMOVE,
} from '../../actions/usersActions/actionTypes';
import { IAction } from '../../models/action.model';

const defaultStore = {
  create_user: null,
  modal_data: null,
};

export default function user(state = defaultStore, action: IAction) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
      };
    case FETCH:
      return {
        ...state,
      };
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE:
      return {
        ...state,
        ...defaultStore,
      };
    default:
      return state;
  }
}
