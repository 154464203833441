import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//  Components
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AlertModal, {
  ButtonI,
} from 'components-kit/Atoms/AlertModal/AlertModal';
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';

//  Resources
import './Navbar.scss';
import LogoPng from 'assets/images/kit/logo-white.svg';

import {
  CL_ITEMS_LIST,
  getIcon,
  ItemsListInterface,
  NavbarInferface,
} from './Navbar.utils';

const toggleDrawer: any = () => (event: any) => {
  if (
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }

  //setOpenDrawer(open);
};

const verifyPermissions = (positionId: string, show: any) => {
  if (show.length === 0) return true;
  return show.find((key: string) => key === positionId);
};

const ListItems = ({ items, withIcon, positionId }: any) => {
  const isActivePath = (item: ItemsListInterface) => {
    let currentPath = location.pathname.toLowerCase();
    currentPath = currentPath.startsWith('/')
      ? currentPath.substring(1, currentPath.length)
      : currentPath;
    let path = item.path.toLowerCase();
    path = path.startsWith('/') ? path.substring(1, path.length) : path;

    if (!item.allSimilarRoutes) {
      return currentPath === path;
    }

    return currentPath.startsWith(path);
  };

  return (
    <div
      className="top"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List className="list-kit-main">
        {items.length > 0 &&
          items.map((item: any, index: number) => {
            /*
            const isEnabledItem = verifyPermissions(
              positionId,
              item.show ?? []
            );
            */
            /*
            if (positionId === POSITIONS.LOCAL_ADMINISTRATOR || isEnabledItem) {

            }
            return null;
            */
            return (
              <Link key={index} to={`/${item.path}`}>
                <ListItem
                  className={`list-kit-main__item${
                    isActivePath(item) ? ' list-kit-main__item--active' : ''
                  }`}
                  button
                >
                  {withIcon && (
                    <ListItemIcon
                      className="list-kit-main__item--icon"
                      style={{ width: '24px', height: '24px' }}
                    >
                      {getIcon(item.path)}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className="list-kit-main__item--text"
                    primary={item.text}
                  />
                </ListItem>
              </Link>
            );
          })}
      </List>
    </div>
  );
};

export const Navbar = ({
  withIcon = false,
  notAuth = false,
  logOutOnClick,
}: NavbarInferface) => {
  const { avatarS3Key, positionId } = useSelector((state: any) => state.auth);
  const { isModalOpen, openModal, closeModal } = useModal();
  const alertButtons: ButtonI[] = [
    {
      text: 'Cancelar',
      onClick: () => closeModal(),
      type: 'primary',
      color: 'slow',
    },
    {
      text: 'Aceptar',
      onClick: () => logOut(),
      type: 'primary',
      color: 'hard',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useNavigate();
  const items: ItemsListInterface[] = CL_ITEMS_LIST;

  const logOut = async () => {
    closeModal();
    logOutOnClick();
    handleClose();
    history('/');
  };

  return (
    <div className="toolbar-kit__main">
      <Drawer
        variant="persistent"
        anchor="left"
        className="drawer-kit__main"
        open={true}
        onClose={toggleDrawer(false)}
      >
        <div className="header-kit_drawer">
          <div className="header-kit_drawer__profile">
            <div className="header-kit_drawer__profile-content">
              <Link to="/home">
                <img
                  className="toolbar-kit__main__content__img"
                  src={LogoPng}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="menu_items-kit__container">
          {
            <ListItems
              items={items}
              withIcon={withIcon}
              positionId={positionId}
            />
          }
        </div>
        <div className="footer-kit_drawer" onClick={openModal}>
          <ListItem
            className={`list-kit-main__item list-kit-main__item--footer`}
            button
          >
            {withIcon && (
              <ListItemIcon className="list-kit-main__item--icon">
                {getIcon('out')}
              </ListItemIcon>
            )}
            <ListItemText
              className="list-kit-main__item--text"
              primary="Cerrar sesión"
            />
          </ListItem>
        </div>
      </Drawer>
      <Modal
        modalClassName="modal__main certifications__main"
        id="certifications-modal"
        isOpen={isModalOpen}
        transition={ModalTransition.SCALE}
      >
        <AlertModal
          title="Cerrar Sesión"
          buttons={alertButtons}
          closeModal={closeModal}
          type="warning"
        >
          <p>¿Estas seguro que deseas cerrar sesión?</p>
        </AlertModal>
      </Modal>
    </div>
  );
};
