import React, { Fragment } from 'react';
import BasicBreadcrumbs, { ItemInterface } from '../Breadcrumbs/Breadcrumbs';
import './Title.scss';

interface TitleInterface {
  className?: string;
  disabled?: boolean;
  large?: boolean;
  text: string | React.ReactNode;
  type: string;
  breadcrumbsItems?: ItemInterface[];
  enabledBreadcrumbs?: boolean;
  separator?: string;
}

const Title = ({
  className,
  disabled = false,
  large = false,
  text,
  type,
  breadcrumbsItems = [],
  enabledBreadcrumbs = true,
  separator = '/',
}: TitleInterface) => {
  if (className) className = ` ${className}`;

  return large ? (
    <h1
      className={`title${
        type === 'primary' ? ' title--primary ' : ' title--secondary '
      }${disabled ? 'title--disabled' : ''}${className ? className : ''}`}
    >
      {text}
    </h1>
  ) : (
    <Fragment>
      {enabledBreadcrumbs && (
        <div className="breadcrumb-title">
          <BasicBreadcrumbs items={breadcrumbsItems} separator={separator} />
        </div>
      )}
      <h1
        className={`title${
          type === 'primary' ? ' title--primary ' : ' title--secondary '
        }${disabled ? 'title--disabled' : ''}${className}`}
      >
        {text}
      </h1>
    </Fragment>
  );
};

export default Title;
