import React from 'react';
//resources
import './BasicModal.scss';
import CloseIcon from '@material-ui/icons/Close';

interface ButtonInterface {
  buttonText?: string;
  children: React.ReactNode;
  closeColor?: string;
  title: string | React.ReactChild;
  closeModal: () => void;
  buttonOnClick?: () => void;
  openModal?: () => void;
  loading?: boolean;
}

const BasicModal = ({
  children,
  title,
  closeModal,
  loading,
}: ButtonInterface) => {
  return (
    <>
      <div className="modal_main__header">
        <p className="modal_main__header__title">{title}</p>
        <p
          className={`modal_main__header__close${
            loading ? ' loading__basic__modal' : ''
          }`}
          onClick={closeModal}
        >
          <CloseIcon className="modal_main__header__close__icon" />
        </p>
      </div>
      <div className="modal_main__content">{children}</div>
    </>
  );
};

export default BasicModal;
