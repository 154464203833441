import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import BasicModal from 'components/Templates/BasicModal/BasicModal';
import { Map, Marker } from 'pigeon-maps';
import { sleep } from 'utils/utils';

interface AttendanceMapModalSchema {
  isModalOpen: boolean;
  closeModal: () => void;
  currentCoordinate: [number, number];
  zoom: number;
}

function AttendanceMapModal({
  isModalOpen,
  closeModal,
  currentCoordinate,
  zoom,
}: AttendanceMapModalSchema) {
  const [showMap, setShowMap] = useState(false);

  const handleMapClick = () => {
    console.log('handleMapClick');
  };

  useEffect(() => {
    if (isModalOpen) {
      showMapWrapper();
    } else {
      setShowMap(false);
    }
  }, [isModalOpen]);

  const showMapWrapper = async () => {
    await sleep(200);
    setShowMap(true);
  };

  return (
    <Modal
      modalClassName="modal__main modal__attendance__map"
      id="attendance-map-modal"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <BasicModal title="Ubicación" closeModal={closeModal}>
        <div className="attendance__map__modal__container">
          {showMap && (
            <Map
              height={600}
              center={currentCoordinate}
              zoom={zoom}
              onClick={handleMapClick}
            >
              <Marker anchor={currentCoordinate} color={'#00AA80'} />
            </Map>
          )}
        </div>
      </BasicModal>
    </Modal>
  );
}

export default AttendanceMapModal;
