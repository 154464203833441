import { ReducerInterface } from '../index.utils';

export const INITIAL_STATE = {
  certifications: null,
  tasks: null,
  stores: null,
  payments_schedule: null,
};

export default (state = INITIAL_STATE, { type, payload }: ReducerInterface) => {
  switch (type) {
    default:
      return { ...state, payload };
  }
};
