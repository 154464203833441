import { createTheme } from '@mui/material';
import { MAIN_COLOR } from 'utils/utils';
import { esES } from '@mui/material/locale';

/** Material UI main theme for this app */
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: MAIN_COLOR,
        light: MAIN_COLOR,
        dark: MAIN_COLOR,
      },
      secondary: {
        main: '#330033',
      },
      info: {
        main: MAIN_COLOR,
        light: MAIN_COLOR,
        dark: MAIN_COLOR,
      },
    },
  },
  esES
);
