import React from 'react';
import { Button as MaterialButton, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './Button.scss';

interface ButtonInterface {
  backgroundColor?: string;
  border?: 'circular' | 'square';
  component?: any;
  className?: string;
  disabled?: boolean;
  endIcon?: any;
  fromModal?: boolean;
  large?: boolean;
  linkTo?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  size?: 'large' | 'medium' | 'small' | undefined;
  submit?: boolean;
  startIcon?: any;
  text: string;
  type?: string;
  variant?: 'outlined' | 'contained';
  form?: string;
}

export const Button = ({
  backgroundColor,
  border = 'circular',
  component = 'button',
  className = '',
  disabled = false,
  onClick,
  submit = false,
  text,
  type = 'primary',
  variant = 'outlined',
  linkTo = '',
  large = false,
  endIcon = null,
  startIcon = null,
  form = '',
}: ButtonInterface) => {
  const MaterialButtonComponent = () => (
    <MaterialButton
      component={component}
      className={`btn-kit ${`btn-kit--${border}`} ${
        type === 'primary' ? ' btn-kit--primary ' : ' btn-kit--secondary '
      }${className}${backgroundColor ? ` btn-kit--${backgroundColor}` : ''}
        ${disabled ? ' btn-kit--disabled' : ''}
        ${variant === 'outlined' ? ' btn-kit--outlined' : ''}
        `}
      style={{ backgroundColor }}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      type={submit ? 'submit' : 'default'}
      variant={variant}
      {...(form ? { form } : null)}
    >
      {text}
    </MaterialButton>
  );

  const IsLarge = () =>
    large ? (
      <MaterialButtonComponent />
    ) : (
      <Container maxWidth="lg">
        <MaterialButtonComponent />
      </Container>
    );

  return linkTo ? (
    <Link to={linkTo}>
      <IsLarge />
    </Link>
  ) : (
    <IsLarge />
  );
};
