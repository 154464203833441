import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import './Breadcrumbs.scss';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface ItemInterface {
  text: string;
  link: string;
}

interface BreadcrumbsInterface {
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  items: ItemInterface[];
  color?: 'primary' | 'secondary' | 'third';
  separator?: string;
}

function handleClickEvent(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
}

export default function BasicBreadcrumbs({
  handleClick = handleClickEvent,
  items = [],
  color = 'primary',
  separator = '/',
}: BreadcrumbsInterface) {
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname.split('/');
  const itemOnClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className={`breadcrumb__container breadcrumb__container--${color}`}
      role="presentation"
      onClick={handleClick}
    >
      <Breadcrumbs aria-label="breadcrumb" separator={separator}>
        {path?.length > 0 &&
          items?.length === 0 &&
          path.map((item, index) => {
            if (index === 0) return null;
            return index === path.length - 1 ? (
              <Typography
                key={index}
                className="last-breadcrumb"
                color="text.primary"
              >
                {t(`breadcrumbs.${item}`)}
              </Typography>
            ) : (
              <div onClick={itemOnClick} key={index}>
                <Link
                  key={index}
                  className="link"
                  color="inherit"
                  to={`/${item}`}
                >
                  {t(`breadcrumbs.${item}`)}
                </Link>
              </div>
            );
          })}
        {items?.length > 0 &&
          items.map((item, index) => {
            if (index === 0) return null;
            return index === path.length - 1 ? (
              <Typography
                key={index}
                className="last-breadcrumb"
                color="text.primary"
              >
                {item.text}
              </Typography>
            ) : (
              <div onClick={itemOnClick} key={index}>
                <Link
                  key={index}
                  className="link"
                  color="inherit"
                  to={item.link}
                >
                  {item.text}
                </Link>
              </div>
            );
          })}
      </Breadcrumbs>
    </div>
  );
}
