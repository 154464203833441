import {
  ColumnInterface,
  StatusOptionsInterface,
} from 'components/Organisms/Table/Table.utils';

import { DataInterface } from 'components/Atoms/Select/Select';

export const TaskersListColumns: ColumnInterface[] = [
  {
    id: 'status',
    prop: 'status',
    sort: false,
    label: 'Estado',
    type: 'status',
    customStyle: {
      width: '60px',
    },
  },
  {
    id: 'name',
    prop: 'name',
    sort: true,
    label: 'Nombre y Apellido',
    type: 'custom',
  },
  {
    id: 'documentType',
    prop: 'documentType',
    sort: true,
    label: 'Tipo de documento',
    size: 'small',
    customStyle: {
      maxWidth: '90px',
    },
  },
  {
    id: 'rut',
    prop: 'rut',
    sort: true,
    label: 'Número de documento',
    size: 'small',
    customStyle: {
      maxWidth: '90px',
    },
  },
  {
    id: 'email',
    prop: 'email',
    sort: false,
    label: 'Correo',
    customStyle: {
      minWidth: '240px',
    },
  },
  {
    id: 'state',
    prop: 'state',
    sort: false,
    label: 'Región',
    customStyle: {
      width: '75px',
    },
  },
  {
    id: 'commune',
    prop: 'commune',
    sort: false,
    label: 'Comuna',
    customStyle: {
      width: '140px',
    },
  },
  {
    id: 'rowId',
    prop: 'rowId',
    sort: false,
    label: 'rowId',
    hiddenColumn: true,
  },
];

export interface ITasker {
  rut: string;
  name: string;
  commune: string;
  datetime: Date | string;
  state: string;
  status: number;
}
export interface IProfile {
  id: number;
  name: string;
  lastname: string;
  birthDate: Date | null;
  phone: string | null;
  address: string | null;
  documentType: string | null;
  documentNumber: string | null;
  userId: number;
}
export interface ITaskerDetail {
  profile: IProfile;
}
export interface FieldConfigSchema {
  id: number;
  fieldTypeId: 'FILE' | 'TEXT_INPUT' | 'DATE';
  titleEn: string;
  titleEs: string;
  placeholderEn: string;
  placeholderEs: string;
  isRequired: boolean;
  extensionsSupported: string[];
  evalScriptJsValidation: string | null;
  errorMessageEn: string | null;
  errorMessageEs: string | null;
}

export const taskerStatusOptions: StatusOptionsInterface[] = [
  {
    value: '0',
    label: 'Cancelado',
    backgroundColor: 'red',
    fontColor: 'red',
    type: 'filled',
  },
  {
    value: '1',
    label: 'En proceso',
    backgroundColor: 'yellow',
    fontColor: 'white',
    type: 'filled',
  },
];

export const transformDataForSelect = (data: any): DataInterface[] => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.nameEs,
      value: row.key,
    };
    newData.push(newRow);
  });

  return newData;
};
export const transformDataVehicleTypeForSelect = (
  data: any
): DataInterface[] => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.nameEs,
      value: row.id,
    };
    newData.push(newRow);
  });

  return newData;
};

export const transformDataBankForSelect = (data: any): DataInterface[] => {
  const newData: DataInterface[] = [];
  data.forEach((row: any) => {
    const newRow = {
      text: row.name,
      value: row.id,
    };
    newData.push(newRow);
  });

  return newData;
};

export const transformDataAccountForSelectNew = (
  data: any
): DataInterface[] => {
  const newData: DataInterface[] = [];
  data.forEach((row: any) => {
    const newRow = {
      text: row.name,
      value: row.id,
    };
    newData.push(newRow);
  });
  return newData;
};
export const transformDataAdditionalForSelectNew = (
  data: any
): FieldConfigSchema[] => {
  const newData: FieldConfigSchema[] = [];
  data.forEach((row: any) => {
    const newRow = {
      fieldTypeId: row.fieldTypeId,
      id: row.id,
      titleEn: row.titleEn,
      titleEs: row.titleEs,
      placeholderEn: row.placeholderEn,
      placeholderEs: row.placeholderEs,
      isRequired: row.isRequired,
      extensionsSupported: row.extensionsSupported,
      evalScriptJsValidation: row.evalScriptJsValidation,
      errorMessageEn: row.errorMessageEn,
      errorMessageEs: row.errorMessageEs,
    };
    newData.push(newRow);
  });
  return newData;
};
