import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup } from '@mui/material';
import './RadioButton';

export interface RadioButtonItemsInterface {
  text: string;
  value: string | undefined;
}

interface RadioButtonInterface {
  items: RadioButtonItemsInterface[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemsForSelect: string[];
}

const RadioButton = ({
  items,
  handleChange,
  itemsForSelect,
}: RadioButtonInterface) => {
  const [checkboxItems, setCheckboxItems] =
    useState<RadioButtonItemsInterface[]>();
  const [recentValue, setRecentValue] = useState({});

  useEffect(() => {
    if (items?.length > 0) setCheckboxItems(items);
    if (itemsForSelect.length === 0) setRecentValue({});
  }, [items, itemsForSelect]);

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecentValue((event.target as HTMLInputElement).value);
    handleChange(event);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-button-group"
        value={recentValue}
        onChange={onHandleChange}
      >
        {checkboxItems &&
          checkboxItems?.length > 0 &&
          checkboxItems.map(({ text, value }) => (
            <FormControlLabel
              key={text}
              value={value}
              control={<Radio />}
              label={text}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
