import React, { useEffect, useState } from 'react';
import { FooterSimpleTableSchema } from 'components-kit/Organisms/SimpleTable/SimpleTable.types';
import { FormControl, MenuItem, Select } from '@mui/material';
import IconButton from 'components/Atoms/IconButton/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface SimplePage {
  pageNumber: string;
  isNumber: boolean;
  isActive: boolean;
}

const SimpleFooterTable = ({
  data,
  currentPage,
  pageSize,
  totalRows,
  availablePageSizes = [5, 10, 25, 50, 100],
  grayColor = false,
  handleRowsPerPageChange,
  handleChangePage,
  additionalFooterText,
}: FooterSimpleTableSchema): JSX.Element => {
  const totalPages = totalRows === 0 ? 0 : totalRows / pageSize;
  const [formattedPages, setFormattedPages] = useState<SimplePage[]>([]);

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(event, currentPage - 1 < 0 ? 0 : currentPage - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(
      event,
      currentPage + 1 > totalRows ? totalRows : currentPage + 1
    );
  };

  const formatPages = (): SimplePage[] => {
    const totalPagesLocal = Math.ceil(totalPages);
    const currentLocalPage = currentPage;

    const maxPagesShowed = 7;
    const formattedPages: SimplePage[] = [];

    if (totalRows === 0) {
      formattedPages.push({
        pageNumber: '1',
        isNumber: true,
        isActive: true,
      });
    } else if (totalPagesLocal <= maxPagesShowed) {
      for (let i = 1; i <= totalPagesLocal; i++) {
        formattedPages.push({
          pageNumber: `${i}`,
          isNumber: true,
          isActive: currentLocalPage === i,
        });
      }
    } else {
      let haveLeftDots = false;
      let haveRightDots = false;

      for (let i = 1; i <= totalPagesLocal; i++) {
        if (currentLocalPage <= 4) {
          if (i <= 5) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: currentLocalPage === i,
            });
          } else if (i === totalPagesLocal) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: false,
            });
          } else if (!haveRightDots) {
            formattedPages.push({
              pageNumber: `...`,
              isNumber: false,
              isActive: false,
            });
            haveRightDots = true;
          }
        } else if (
          currentLocalPage >= totalPagesLocal - 3 &&
          currentLocalPage <= totalPagesLocal
        ) {
          if (i === 1) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: false,
            });
          } else if (totalPagesLocal - 4 <= i) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: currentLocalPage === i,
            });
          } else if (!haveLeftDots) {
            formattedPages.push({
              pageNumber: `...`,
              isNumber: false,
              isActive: false,
            });
            haveLeftDots = true;
          }
        } else {
          if (i === 1 || i === totalPagesLocal) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: false,
            });
          } else if (i >= currentLocalPage - 1 && i <= currentLocalPage + 1) {
            formattedPages.push({
              pageNumber: `${i}`,
              isNumber: true,
              isActive: currentLocalPage === i,
            });
          } else if (i < currentLocalPage - 1 && !haveLeftDots) {
            formattedPages.push({
              pageNumber: `...`,
              isNumber: false,
              isActive: false,
            });
            haveLeftDots = true;
          } else if (i > currentLocalPage + 1 && !haveRightDots) {
            formattedPages.push({
              pageNumber: `...`,
              isNumber: false,
              isActive: false,
            });
            haveRightDots = true;
          }
        }
      }
    }

    return formattedPages /*.slice(0, maxPagesShowed)*/;
  };

  const changePagination = (
    event: React.MouseEvent<HTMLDivElement>,
    pageNumber: number
  ) => {
    handleChangePage(event, pageNumber);
  };

  const countText = (): string => {
    const totalShow = pageSize * (currentPage - 1) + data.length;

    return `Mostrando ${totalShow} de ${totalRows} ${
      totalRows === 1 ? 'registro' : 'registros'
    }${additionalFooterText ? ` ${additionalFooterText}` : ''}`;
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={`sfooter__main${grayColor ? ' footer__main--gray' : ''}`}>
        <div className="left-side">
          <p>{countText()}</p>
        </div>
        <div className="right-side">
          <p>Mostrar</p>

          {/* Selector cambio de páginas */}

          <FormControl fullWidth>
            <Select
              className="row-per-page-select"
              labelId="row-per-page-label"
              id="row-per-page-select-custom-simple-footer"
              value={`${pageSize}`}
              label=""
              onChange={handleRowsPerPageChange}
            >
              {availablePageSizes?.length > 0 &&
                availablePageSizes.map((availablePageSize, index) => (
                  <MenuItem value={`${availablePageSize}`} key={index}>
                    {availablePageSize}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Paginado */}

          <IconButton
            onClick={handleBackButtonClick}
            disabled={currentPage - 1 === 0 || totalRows === 0}
            className={`table-button${
              currentPage - 1 === 0 || totalRows === 0
                ? ' table-button--disabled'
                : ''
            }`}
            ariaLabel="previous page"
          >
            <ArrowLeftIcon />
          </IconButton>

          <div className="pages__main">
            {formatPages().map((formatPage, idx) => (
              <div
                key={idx}
                className={`pages__content${
                  formatPage.isActive ? ' pages__content--active' : ''
                }${!formatPage.isNumber ? ' pages__content--no-hover' : ''}`}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  if (formatPage.isNumber) {
                    changePagination(e, parseInt(formatPage.pageNumber));
                  }
                }}
              >
                <p className="pages__number">{formatPage.pageNumber}</p>
              </div>
            ))}
          </div>

          <IconButton
            onClick={handleNextButtonClick}
            disabled={
              currentPage - 1 >= Math.ceil(totalRows / pageSize) - 1 ||
              totalRows === 0
            }
            className={`table-button${
              currentPage - 1 >= Math.ceil(totalRows / pageSize) - 1 ||
              totalRows === 0
                ? ' table-button--disabled'
                : ''
            }`}
            ariaLabel="next page"
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default SimpleFooterTable;
