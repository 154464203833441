import {
  ADD_ALERT,
  REMOVE_ALERT,
  ADD_ALERT_SUCCESS,
  ADD_ALERT_DANGER,
} from '../../actions/alertMessage/actionTypes';
import { IAlertAction } from '../../models/action.model';
import { IAlertMessagePayload } from '../../actions/alertMessage/actions';

interface AlertReducerResponse {
  open: boolean;
  icon?: string;
  payload?: IAlertMessagePayload;
}

export default function alert(
  state: any = { open: false },
  action: IAlertAction
): AlertReducerResponse {
  switch (action.type) {
    case ADD_ALERT:
      return {
        open: true,
        payload: { ...action.payload },
      };
    case ADD_ALERT_SUCCESS:
      return {
        open: true,
        icon: 'success',
        payload: { ...action.payload },
      };
    case ADD_ALERT_DANGER:
      return {
        open: true,
        icon: 'danger',
        payload: { ...action.payload },
      };
    case REMOVE_ALERT:
      return { open: false };
    default:
      return { open: false };
  }
}
