import {
  SAVE,
  FETCH,
  UPDATE,
  REMOVE,
  SAVE_DOCUMENT_TYPES,
  SAVE_ROLES,
  SAVE_POSITIONS,
} from '../../actions/helper/actionTypes';
import IHelper from '../../models/helper.models';
import { ReducerInterface } from '../index.utils';

const defaultStore: IHelper = {
  localCountry: {
    code: '',
    alpha3Code: '',
    dialCode: '',
    aditionalData: '{}',
    additionalData: '{}',
    language: '',
    specificLanguage: '',
    name: '',
    termsFileUrl: null,
    flag: '',
  },
  countries: [],
  document_types: [],
  roles: [],
  positions: [],
};

export default (state = defaultStore, { type, payload }: ReducerInterface) => {
  switch (type) {
    case SAVE:
    case UPDATE:
      return {
        ...state,
        ...payload,
      };
    case SAVE_DOCUMENT_TYPES:
      return {
        ...state,
        document_types: payload,
      };
    case SAVE_POSITIONS:
      return {
        ...state,
        positions: payload,
      };
    case SAVE_ROLES:
      return {
        ...state,
        roles: payload,
      };
    case FETCH:
    case REMOVE:
    default:
      return state;
  }
};
