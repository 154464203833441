import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import IconButton from 'components/Atoms/IconButton/IconButton';
import { Button } from 'components/Atoms/Button/Button';
import Accordion from 'components/Atoms/Accordion/Accordion';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select, { DataInterface } from 'components-kit/Atoms/Select/Select';

interface AttendanceTabFilterOptionSchema {
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
  grayColor?: boolean;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  currentStartDate: string;
  currentEndDate: string;
  submit: (startDate: string, endDate: string) => void;
}

const AttendanceTabFilterOption = ({
  startDate,
  endDate,
  isFilterOpen,
  setIsFilterOpen,
  grayColor = false,
  setStartDate,
  setEndDate,
  currentStartDate,
  currentEndDate,
  submit,
}: AttendanceTabFilterOptionSchema): JSX.Element => {
  const actionClickFilterContainer = () => {
    if (!isFilterOpen) {
      //Asignar los valores actuales
      setStartDate(moment(currentStartDate, 'YYYY-MM-DD').toDate());
      setEndDate(moment(currentEndDate, 'YYYY-MM-DD').toDate());
    }

    setIsFilterOpen(!isFilterOpen);
  };

  const cancelOnClick = () => {
    setIsFilterOpen(false);
  };

  const submitOnClick = async () => {
    const startDateParam = moment(startDate).format('YYYY-MM-DD');
    const endDateParam = moment(endDate).format('YYYY-MM-DD');

    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error(
        'La primera fecha del rango no debe de ser mayor a la fecha final',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return;
    }

    await submit(startDateParam, endDateParam);
    setIsFilterOpen(false);
  };

  const startOnChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const endOnChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  return (
    <>
      {/* Button */}
      <IconButton
        className={`filter-button${grayColor ? ' filter-button--gray' : ''}`}
        onClick={actionClickFilterContainer}
      >
        <FilterIcon />
      </IconButton>

      {/* Container */}
      <div
        id="filter-menu"
        className={`filter-menu${isFilterOpen ? ' filter-menu--open' : ''}${
          !actionClickFilterContainer ? ' filter-menu--no-handle' : ''
        }`}
      >
        <div className="label-filter-menu-item">
          <p>Filtros adicionales</p>
        </div>

        {/* Filter: dates */}
        <div className="filter-menu-item" key="filter-dates">
          <Accordion
            startExpanded
            items={[
              {
                label: 'Fecha',
                children: (
                  <>
                    <div className="dates__main">
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={startDate}
                          onChange={startOnChange}
                        />
                      </div>
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={endDate}
                          onChange={endOnChange}
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Botones: submit */}
        <div className="filter-footer">
          <Button
            onClick={cancelOnClick}
            text={'Cerrar'}
            type="primary"
            variant="outlined"
            border="square"
            large
          />
          <Button
            onClick={submitOnClick}
            text={'Aplicar'}
            type="primary"
            variant="contained"
            border="square"
            large
          />
        </div>
      </div>
    </>
  );
};

export default AttendanceTabFilterOption;
