import { useState } from 'react';
import { DataInterface } from '../components/Atoms/Select/Select';

const useForm = <T extends Record<string, unknown>>(form: T) => {
  interface UseFormChangeSchema {
    value: any;
    field: keyof T;
  }

  const [state, setState] = useState(form);

  const onChange = (
    value: string | undefined | DataInterface | string[] | FileList | null,
    field: keyof T
  ) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const onMultipleChange = async (newData: UseFormChangeSchema[]) => {
    let newState = { ...state };

    for (const propData of newData) {
      newState = {
        ...newState,
        [propData.field]: propData.value,
      };
    }

    await setState(newState);
  };

  const validFields = (): any => {
    let newErrors: any = {};
    Object.entries(state).forEach(([key, value]: any) => {
      if (!value || value === null || value === '') {
        newErrors = {
          ...newErrors,
          [key]: true,
        };
      }
    });
    return newErrors;
  };

  const resetForm = (newState: any): any => {
    setState(newState);
  };

  return {
    form: state,
    onChange,
    validFields,
    resetForm,
    onMultipleChange,
  };
};

export default useForm;
