import React from 'react';
import { ReactComponent as HomeImage } from 'assets/images/kit/menu/home.svg';
import { ReactComponent as AttendanceImage } from 'assets/images/kit/menu/attendance.svg';
import { ReactComponent as TaskerImage } from 'assets/images/kit/menu/tasker.svg';
import { ReactComponent as ProfileImage } from 'assets/images/kit/menu/profile.svg';
import { ReactComponent as CampaignImage } from 'assets/images/kit/menu/campaign.svg';
import { ReactComponent as RaisingImage } from 'assets/images/kit/menu/raising.svg';
import { ReactComponent as SettingsImage } from 'assets/images/kit/menu/settings.svg';
import { ReactComponent as ExitImage } from 'assets/images/kit/menu/exit.svg';

export interface ItemsListInterface {
  text: string;
  icon?: any;
  path: string;
  show?: any;
  allSimilarRoutes?: boolean;
}

export interface NavbarInferface {
  auth: {
    country_iso: string;
  };
  withIcon?: boolean;
  notAuth?: boolean;
  logOutOnClick: () => void;
}

export const CL_ITEMS_LIST: ItemsListInterface[] = [
  {
    text: 'Inicio',
    path: 'kit/home',
    show: [],
  },
  {
    text: 'Perfil',
    path: 'kit/profile',
    show: [],
  },
  {
    text: 'Taskers',
    path: 'kit/taskers',
    show: [],
  },
  {
    text: 'Campañas',
    path: 'kit/campaign',
    allSimilarRoutes: true,
    show: [],
  },
  {
    text: 'Asistencias',
    path: 'kit/attendances',
    show: [],
  },
  {
    text: 'Levantamientos',
    path: 'kit/lifts',
    show: [],
    allSimilarRoutes: true,
  },
  {
    text: 'Ajustes',
    path: 'kit/settings',
    allSimilarRoutes: true,
    show: [],
  },
];

export const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'kit/home':
      return <HomeImage />;
    case 'kit/profile':
      return <ProfileImage />;
    case 'kit/attendances':
      return <AttendanceImage />;
    case 'kit/taskers':
      return <TaskerImage />;
    case 'kit/campaign':
      return <CampaignImage />;
    case 'kit/lifts':
      return <RaisingImage />;
    case 'out':
      return <ExitImage />;
    case 'kit/settings':
    default:
      return <SettingsImage />;
  }
};
