import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './Switch.scss';
import Tooltip from '../Tooltip/Tooltip';

interface SwitchInterface {
  label?: string;
  tooltipText?: string;
  placement?:
    | 'top'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | undefined;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  withoutTooltip?: boolean;
  disabled?: boolean;
}

const SwitchOption = ({
  checked,
  label = '',
  onChange,
  withoutTooltip,
  tooltipText = '',
  placement = 'top',
  disabled,
}: SwitchInterface) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Tooltip
            disableHoverListener={withoutTooltip}
            title={
              tooltipText ? tooltipText : checked ? 'Desactivar' : 'Activar'
            }
            placement={placement}
          >
            <Switch
              className="switch-kit__main"
              sx={{ m: 1 }}
              checked={checked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={disabled}
            />
          </Tooltip>
        }
        label={label}
        labelPlacement="start"
      />
    </FormGroup>
  );
};

export default SwitchOption;
