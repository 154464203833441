import React, { useEffect, useState } from 'react';
import Select, { DataInterface } from 'components/Atoms/Select/Select';
import { OnChangeValueType } from 'utils/utils';
import useForm from 'hooks/useForm';

interface PermissionsDataFormInterface {
  onSubmit: (e: any) => void;
  positions: DataInterface[];
  roles: DataInterface[];
  setdisabledNextButton: (value: boolean) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  role_type: {
    text: '',
    value: '',
    roleId: 0,
  },
  charge_type: {
    text: '',
    value: '',
  },
};

const PermissionsDataForm = ({
  onSubmit,
  positions,
  roles,
  setdisabledNextButton,
}: PermissionsDataFormInterface) => {
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields } = useForm(INITIAL_EMPTY_FORM_VALUES);
  const [currentPositions, setCurrentPositions] =
    useState<DataInterface[]>(positions);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const newForm = {
        roleId: form.role_type.roleId,
        positionId: form.charge_type.value,
      };
      onSubmit(newForm);
    }
  };

  const changePositionData = (newValue: OnChangeValueType) => {
    form.charge_type = INITIAL_EMPTY_FORM_VALUES.charge_type;
    const newPositions: DataInterface[] = [];
    if (newValue) {
      positions.forEach((position: any) => {
        if (
          typeof newValue === 'object' &&
          newValue.roleId === position.roleId
        ) {
          newPositions.push({
            text: position.text,
            value: position.value,
          });
        }
      });
    }
    setCurrentPositions(newPositions);
  };

  const onChangeField = (
    value: string | OnChangeValueType,
    field: 'role_type' | 'charge_type'
  ) => {
    const newErrors: any = validFields();
    if (typeof form.role_type === 'object' && typeof value === 'object')
      setdisabledNextButton(false);
    else {
      setdisabledNextButton(true);
      setErrors(newErrors);
    }
    onChange(value, field);
  };

  return (
    <>
      <h2>Permisos</h2>
      <p>Selecciona los permisos para este usuario</p>
      <div className="user-form__main">
        <form method="POST" onSubmit={onSubmitForm} id="active-form-2">
          <div className="fields-group">
            <div className="field-item">
              <Select
                data={roles}
                errors={errors}
                label={'Rol'}
                name="role_type"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChangeField(newValue, 'role_type');
                  else onChangeField('', 'role_type');
                  changePositionData(newValue || '');
                }}
                placeholder={'Selecciona un rol'}
                required
              />
            </div>
            <div className="field-item">
              <Select
                data={currentPositions}
                errors={errors}
                label={'Puesto'}
                name="charge_type"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChangeField(newValue, 'charge_type');
                  else onChangeField('', 'charge_type');
                }}
                placeholder={'Selecciona un puesto'}
                required
                disabled={form.role_type.text === ''}
              />
            </div>
          </div>
        </form>
        {/*
        <div className="modules__main">
          {form.charge_type && (
            <table>
              <thead>
                <tr>
                  <th className="enabled__modules__title">
                    Módulos hablitados
                  </th>
                  <th>Ver</th>
                  <th>Crear</th>
                  <th>Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="item__modules__title">Taskers</td>
                  <td>
                    <div className="block block--green" />
                  </td>
                  <td>
                    <div className="block block--gray" />
                  </td>
                  <td>
                    <div className="block block--gray" />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        */}
      </div>
    </>
  );
};

export default PermissionsDataForm;
