import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import './MultipleSelect.scss';
import Tooltip from '@material-ui/core/Tooltip';

export interface MultipleDataInterface {
  text: string;
  value: string;
  type?: string;
}

interface MultipleSelectInterface {
  data: MultipleDataInterface[];
  label: string;
  placeholder?: string;
  required?: boolean;
  onChange: (
    event: SelectChangeEvent<any>,
    newValue?: MultipleDataInterface | string | undefined | string[]
  ) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = ({
  data = [],
  label,
  onChange,
  placeholder = '',
}: MultipleSelectInterface) => {
  const [listValues, setListValues] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof listValues>) => {
    const {
      target: { value },
    } = event;
    setListValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    onChange(event, value);
  };

  return (
    <React.Fragment>
      <p className="textfield__label">{`${label}`}</p>
      <FormControl>
        <Select
          className="multiple-select"
          labelId={`${label}-label`}
          id={`${label}-checkbox`}
          multiple
          displayEmpty
          value={listValues}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            const dataForShow: string[] = [];
            if (selected.length === 0) {
              return `${placeholder}`;
            }
            data.forEach((item) => {
              if (selected.includes(item.value)) {
                dataForShow.push(item.text);
              }
            });
            return dataForShow.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {data?.length > 0 &&
            data.map(({ text, value }) =>
              text.length > 34 ? (
                <MenuItem className="multiselect-item" key={text} value={value}>
                  <Checkbox checked={listValues.indexOf(value) > -1} />
                  <Tooltip title={text}>
                    <ListItemText primary={text} />
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem className="multiselect-item" key={text} value={value}>
                  <Checkbox checked={listValues.indexOf(value) > -1} />
                  <ListItemText primary={text} />
                </MenuItem>
              )
            )}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default MultipleSelect;
