import React from 'react';
import './ErrorMessage.scss';

interface ErrorMessageInterface {
  message?: string;
  className?: string;
}

const ErrorMessage = ({ message = '', className }: ErrorMessageInterface) => {
  return (
    <div className="error_message__main">
      <span className={`error_message ${className}`}>{message}</span>
    </div>
  );
};

export default ErrorMessage;
