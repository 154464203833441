import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './Switch.scss';
import Tooltip from '../Tooltip/Tooltip';

interface SwitchInterface {
  label?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  withoutTooltip?: boolean;
  labelClass?: string;
  disabled?: boolean;
}

const SwitchOption = ({
  checked,
  label = '',
  onChange,
  withoutTooltip,
  labelClass = '',
  disabled,
}: SwitchInterface) => {
  return (
    <FormGroup>
      <FormControlLabel
        className={labelClass}
        control={
          <Tooltip
            disableHoverListener={withoutTooltip}
            title={checked ? 'Desactivar' : 'Activar'}
            placement="top"
          >
            <Switch
              className="switch__main"
              sx={{ m: 1 }}
              checked={checked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={disabled}
            />
          </Tooltip>
        }
        label={label}
        labelPlacement="start"
      />
    </FormGroup>
  );
};

export default SwitchOption;
