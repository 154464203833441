import React, { useState } from 'react';

import { ReactComponent as FileUploadIcon } from '../../../assets/images/icons/upload.svg';
import { ReactComponent as FileImportIcon } from 'assets/images/icons/file-import.svg';
import { ReactComponent as ExcelIcon } from 'assets/images/icons/excel.svg';
import { ReactComponent as PdfIcon } from 'assets/images/icons/pdf.svg';
import './FileUpload.scss';
import { Button } from '../Button/Button';
import { TextField } from '@mui/material';

interface FileUploadInterface {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withTextField?: boolean;
  importOnClick?: () => void;
  acceptFiles?: string;
  textfile?: string;
  textInput?: string;
  typeFile?: any;
  withIcon?: any;
  loading?: boolean;
}

const FileUpload = ({
  onChange,
  withTextField = false,
  importOnClick,
  acceptFiles = '*',
  textfile,
  textInput,
  typeFile,
  withIcon,
  loading,
  ...rest
}: FileUploadInterface) => {
  const [file, setFile] = useState<string>('');
  const [label, setLabel] = useState<string>(
    textInput ? textInput : 'Seleccionar Archivo'
  );
  const fileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      let filename = e.target.files[0].name;

      if (filename.length > 27) {
        filename = filename.substring(0, 17) + '...' + filename.slice(-7);
      }

      setLabel(filename);
      onChange(e);
    }
  };

  const handleImportOnClick = () => {
    if (importOnClick) importOnClick();
  };

  const getImage = () =>
    file ? (
      <img src={file} />
    ) : (
      <div className="empty-file">
        <FileUploadIcon />
      </div>
    );
  return (
    <div className="file-upload__main">
      {withTextField ? (
        <div className="with-textfield__main">
          <label htmlFor="text-field__upload" className="text-field__label">
            {label}
          </label>
          {withIcon ? (
            <div className="textfield__section"></div>
          ) : (
            <div className="textfield__section">
              <div className="excel-icon__main">
                {typeFile ? <PdfIcon /> : <ExcelIcon />}
              </div>
            </div>
          )}
          <TextField
            className="text-field__upload"
            id={
              loading ? 'text-field__upload______loading' : 'text-field__upload'
            }
            name="text-field__upload"
            type="file"
            onChange={fileOnChange}
            variant="outlined"
            inputProps={{ accept: acceptFiles }}
            {...rest}
          />
          {importOnClick && (
            <>
              <Button
                onClick={handleImportOnClick}
                text={
                  loading ? 'Cargando...' : textfile ? textfile : 'Importar'
                }
                type="primary"
                startIcon={<FileImportIcon />}
                variant="contained"
                border="square"
                disabled={loading}
              />
            </>
          )}
        </div>
      ) : (
        <div className="image-upload">
          <label htmlFor="file-input">{getImage()}</label>
          <input
            id="file-input"
            type="file"
            onChange={fileOnChange}
            accept={acceptFiles}
          />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
