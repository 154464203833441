import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppTemplate from 'components/Templates/App/App';
import { Container } from 'components/Atoms/Container/Container';
import Title from 'components/Atoms/Title/Title';

import './TasksActions.scss';
import BasicTabs from 'components/Molecules/Tabs/Tabs';
import Link from 'components/Atoms/Link/Link';
import CertificationsTable from './CertificationsTable';
import InscribedTable from './InscribedTable';
import AttendaceTable from './AttendaceTable';
import TurnsTable from './TurnsTable';
import MaterialsForm from './MaterialsForm';
import { Button } from 'components/Atoms/Button/Button';
import { ItemInterface } from 'components/Atoms/Breadcrumbs/Breadcrumbs';
import TasksService from '../services/TasksService';
import TaskDataForm from '../Create/TaskDataForm';
import LoaderLocal from 'components/Atoms/LoaderLocal/LoaderLocal';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';

const TasksActions = () => {
  const { id } = useParams<{ id: string }>();
  const [taskId, setTaskId] = useState(0);
  const [taskSelected, setTaskSelected] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [materials, setMaterials] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const permissions = useSelector((state: any) =>
    state.auth?.position?.permissions ? state.auth?.position?.permissions : []
  );
  const history = useNavigate();

  useEffect(() => {
    if (permissions.length === 0) history('/');
    const taskId = parseInt(id || '') || 0;
    setTaskId(taskId);
    loadData(taskId);
  }, []);

  const onSubmit = () => {
    console.log('on submit');
  };

  const changeCurrentTab = (tab: number) => {
    if (tab < 2) loadData(taskId);
    setCurrentTab(tab);
  };

  const loadData = async (taskId: number) => {
    const [getTaskResponse, getMaterialsResponse] = await Promise.all([
      TasksService.getTaskById(taskId),
      TasksService.getMaterialsByTask(taskId),
    ]);

    if (getTaskResponse.success) {
      setTaskSelected(getTaskResponse.data);
    }

    if (getMaterialsResponse.success) {
      setMaterials(getMaterialsResponse.data);
    }

    setLoading(false);
  };

  const materialOnSubmit = async (filter: number[]) => {
    if (id) {
      const response = await TasksService.saveMaterialsToTask(
        Number.parseInt(id),
        filter
      );
      if (response.statusCode === 200) {
        toast.success('Se actualizaron los materiales correctamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.warn('¡UPS! ocurrió un error, por favor intentálo nuevamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
  };

  const tabsData = [
    {
      label: 'General',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_TASK_DATA
      ) ? (
        <TaskDataForm
          taskSelected={taskSelected}
          onSubmit={onSubmit}
          permissions={permissions}
          formId={`form-id-${currentTab}`}
        />
      ) : undefined,
    },
    {
      label: 'Materiales',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_MATERIAL_TASK_DATA
      ) ? (
        <MaterialsForm
          data={materials}
          onSubmit={materialOnSubmit}
          formId={`form-id-${currentTab}`}
        />
      ) : undefined,
    },
    {
      label: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_TURN_DATA
      )
        ? 'Turnos'
        : '',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_TURN_DATA
      ) ? (
        <TurnsTable taskId={taskId} permissions={permissions} />
      ) : undefined,
    },
    {
      label: 'Certificados',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_CERTIFICATE_USER_TASK
      ) ? (
        <CertificationsTable taskId={taskId} permissions={permissions} />
      ) : undefined,
    },
    {
      label: 'Inscritos',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_SUBSCRIBED_USER_TURN
      ) ? (
        <InscribedTable taskId={taskId} permissions={permissions} />
      ) : undefined,
    },
    {
      label: 'Asistencia',
      children: verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.LOOK_ASISTANCE_LIST_TURN
      ) ? (
        <AttendaceTable taskId={taskId} permissions={permissions} />
      ) : undefined,
    },
  ];

  const breadcrumbsItems: ItemInterface[] = [
    {
      text: 'Tareas',
      link: '/tasks',
    },
    {
      text: 'Tareas',
      link: '/tasks',
    },
    {
      text: tabsData[currentTab]?.label ?? '',
      link: '/create-certification',
    },
  ];

  const cancelOnClick = () => {
    console.log('cancel');
  };

  const validSaveButton = () => {
    return (
      verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.EDIT_TASK_DATA) ||
      verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.tasks.EDIT_MATERIAL_TASK_DATA
      )
    );
  };

  return (
    <AppTemplate>
      {taskSelected && !loading ? (
        <Container>
          <div className="title__main">
            <Title
              text={`Tarea ${taskSelected.name}`}
              type="primary"
              breadcrumbsItems={breadcrumbsItems}
            />
          </div>
          <div className="extra__link">
            <Link text="Cerrar Tarea" onClick={() => history('/tasks')} />
          </div>
          <div className="tasks_actions__main">
            <BasicTabs data={tabsData} changeCurrentTab={changeCurrentTab} />
          </div>
          <div className="extra__buttons__main">
            {[0, 1].includes(currentTab) && (
              <div className="general-buttons__main">
                <Button
                  onClick={cancelOnClick}
                  text={'Cancelar'}
                  type="primary"
                  size="large"
                  border="circular"
                />
                {validSaveButton() && (
                  <Button
                    text={'Guardar'}
                    type="submit"
                    size="large"
                    variant="contained"
                    border="circular"
                    submit
                    form={`form-id-${currentTab}`}
                  />
                )}
              </div>
            )}
          </div>
        </Container>
      ) : (
        <LoaderLocal />
      )}
    </AppTemplate>
  );
};

export default TasksActions;
