import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';

const ProfileService = {
  updateUserProfile: async (
    userId: number,
    payload: any
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.put(`/user/update-user/${userId}`, {
        ...payload,
      });
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateAvatar: async (payload: any): Promise<BffApiResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', payload);
      const response = await api.post(`/user/upload-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAvatar: async (payload: any): Promise<BffApiResponse> => {
    try {
      const response = await api.post(`/file`, payload);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
};

export default ProfileService;
