import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export interface MulticheckboxItemsInterface {
  text: string;
  value: string | undefined;
  id?: number;
}

interface MultiCheckboxInterface {
  items: MulticheckboxItemsInterface[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemsForSelect: string[];
}

const MultiCheckbox = ({
  items,
  handleChange,
  itemsForSelect,
}: MultiCheckboxInterface) => {
  const [checkboxItems, setCheckboxItems] =
    useState<MulticheckboxItemsInterface[]>();
  useEffect(() => {
    if (items?.length > 0) setCheckboxItems(items);
  }, [items, itemsForSelect]);
  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        {checkboxItems &&
          checkboxItems?.length > 0 &&
          checkboxItems.map(({ text, value }) => (
            <FormControlLabel
              key={text}
              control={
                <Checkbox
                  checked={
                    itemsForSelect?.length > 0 &&
                    itemsForSelect.includes(value ? value : '')
                  }
                  onChange={handleChange}
                  name={value}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={text}
              labelPlacement="top"
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};

export default MultiCheckbox;
