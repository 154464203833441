export const NODE_ENV = process.env.NODE_ENV || 'development';
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const API_TITASK_URL = process.env.REACT_APP_API_TITASK_URL;
export const API_GEOLOCATION_URL =
  process.env.REACT_APP_API_GEOLOCATION_URL || '';
export const API_INTEGRATIONS_URL =
  process.env.REACT_APP_API_INTEGRATIONS_URL || '';
export const API_INTEGRATIONS_BEARER_KEY =
  process.env.REACT_APP_API_INTEGRATIONS_BEARER_KEY || '';
export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || 'CL';
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE || 'es';
export const HUBSPOT_WORKFLOW_CHAT_ID =
  process.env.REACT_APP_HUBSPOT_WORKFLOW_CHAT_ID || '21110321';
export const CACHE_FILE_STORAGE = 'cache_files_storage';
