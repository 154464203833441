import React from 'react';
import Box from '@mui/material/Box';
import { Stepper as StepperMaterialUi } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import './Stepper.scss';

interface StepperInterface {
  steps: string[];
  activeStep?: number;
}

const Stepper = ({ steps, activeStep = 0 }: StepperInterface) => {
  const StepIcon = (props: any) => {
    return <div className="step__icon">{String(props.icon)}</div>;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StepperMaterialUi activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </StepperMaterialUi>
    </Box>
  );
};

export default Stepper;
