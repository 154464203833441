import React, { useEffect, useState } from 'react';
import useForm from 'hooks/useForm';
import { SelectChangeEvent } from '@mui/material';
import Table from 'components/Organisms/Table/Table';
import MultipleSelect, {
  MultipleDataInterface,
} from 'components/Atoms/MultipleSelect/MultipleSelect';
import { Container } from 'components/Atoms/Container/Container';
import { ColumnInterface } from 'components/Organisms/Table/Table.utils';
import Select, { DataInterface } from 'components/Atoms/Select/Select';
import LoaderLocal from 'components/Atoms/LoaderLocal/LoaderLocal';
import UsersService from '../services/UsersService';
import {
  ITasksStores,
  taskStoreColumns,
  transformTasksDataForSelect,
} from '../Users.utils';
import { Button } from 'components/Atoms/Button/Button';

interface StoresTasksFormInterface {
  onSubmit: (e: any) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  tasks_list: [],
  stores_list: [],
};

const StoresTasksForm = ({ onSubmit }: StoresTasksFormInterface) => {
  const [tasksList, setTasksList] = useState([]);
  const [rowsFromTable, setRowsFromTable] = useState([]);
  const [taskStoresForSent, setTaskStoresForSent] = useState<any>([]);
  const [tasksListForSelect, setTasksListForSelect] = useState<DataInterface[]>(
    []
  );
  const [storeListForSelect, setStoreListForSelect] = useState<DataInterface[]>(
    []
  );
  const [storeList, setStoreList] = useState<any>([]);
  const [recentTask, setRecentTask] = useState<any>();
  const [refreshTable, setRefreshTable] = useState(false);
  const [rows, setRows] = useState<ITasksStores[]>([]);
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields, resetForm } = useForm(
    INITIAL_EMPTY_FORM_VALUES
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const responseTasks = await UsersService.getTasksList();
    if (responseTasks.data?.length > 0) {
      setTasksListForSelect(transformTasksDataForSelect(responseTasks.data));
      setTasksList(responseTasks.data);
    }
  };

  const formOnSubmit = (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const tasksForSent: any = [];
      rows.forEach((row) => {
        tasksForSent.push({
          taskId: row.number,
          isDisabled: false,
        });
      });
      const storesForSent: any = [];
      taskStoresForSent.forEach((taskStore: any) => {
        if (taskStore.stores.length > 0) {
          taskStore.stores.forEach((store: number) => {
            storesForSent.push({
              storeId: store,
              taskId: taskStore.task.id,
            });
          });
        }
      });
      onSubmit({
        tasks: tasksForSent,
        stores: storesForSent,
      });
    }
  };

  const onChangeTasksSelect = async (
    newValue?: MultipleDataInterface | string | undefined | string[]
  ) => {
    onChange(newValue, 'tasks_list');
    //let index = 0;
    if (
      !Array.isArray(newValue) &&
      typeof newValue === 'object' &&
      Object.keys(newValue).length > 0
    ) {
      setRecentTask(newValue);
      storeTasksOnChange([]);
      const stores = await getStoresByTasks(newValue);
      setStoreListForSelect(transformTasksDataForSelect(stores));
    }
  };

  const getStoresByTasks = async (tasks: any) => {
    if (Object.keys(tasks).length > 0) {
      const response = await UsersService.getStoresListByTask(tasks.value);
      if (response.data) {
        return response.data;
      }
    }
    return [];
  };

  const getRowsData = (rowsData: any) => {
    setRowsFromTable(rowsData);
  };

  const storeTasksOnChange = (
    newValue?: MultipleDataInterface | string | undefined | string[]
  ) => {
    onChange(newValue, 'stores_list');
  };

  const getStorestasksOnClick = () => {
    setRefreshTable(true);
    if (recentTask) {
      const newRows: ITasksStores[] = [];
      const task: any = tasksList.find(
        (taskRow: any) => taskRow.id === recentTask.value
      );
      if (task) {
        const newStores: any = [];
        if (form.stores_list.length > 0) {
          form.stores_list.forEach((store: number) => {
            if (!storeList.includes(store)) newStores.push(store);
          });
        }
        if (rows.length > 0) {
          const valueExists = newRows.findIndex(
            (row) => row.number === recentTask.value
          );
          newRows.push(...rows);
          if (valueExists < 0) {
            newRows.push({
              number: task.id,
              task_name: task.name,
              client: task.companyName,
              stores: newStores.length,
            });
          } else {
            rows.forEach((row: ITasksStores) => {
              if (row.number === task.id) {
                newRows[valueExists] = {
                  ...row,
                  stores: newStores.length,
                };
              }
            });
          }
        } else {
          newRows.push({
            number: task.id,
            task_name: task.name,
            client: task.companyName,
            stores: newStores.length,
          });
        }
        const newtaskStoresForSent = taskStoresForSent;
        newtaskStoresForSent.push({
          task,
          stores: newStores,
        });
        setTaskStoresForSent(newtaskStoresForSent);
      }
      setRows(newRows);
    }
    setStoreList(form.stores_list);
    storeTasksOnChange([]);
    onChangeTasksSelect([]);
    resetForm(INITIAL_EMPTY_FORM_VALUES);
    setTimeout(() => {
      setRefreshTable(false);
    }, 1);
  };

  return (
    <>
      <h2>Asignación de tareas y tiendas</h2>
      <p>Elige las tareas y tiendas que podrá visualizar el usuario</p>
      <div className="user-form__main user-form__main--store-tasks">
        <Container maxWidth="md">
          <form method="POST" onSubmit={formOnSubmit} id="active-form-3">
            <div className="fields-group fields-group--cols-2">
              <div className="field-item">
                <Select
                  name="task_selected"
                  data={tasksListForSelect}
                  label={'Tareas'}
                  onChange={(
                    _event: SelectChangeEvent<any>,
                    newValue?:
                      | MultipleDataInterface
                      | string
                      | undefined
                      | string[]
                  ) => onChangeTasksSelect(newValue || [])}
                  placeholder={'Seleccionar tarea'}
                  required
                />
              </div>
              <div className="field-item">
                <MultipleSelect
                  data={storeListForSelect}
                  label={'Tiendas'}
                  onChange={(
                    _event: SelectChangeEvent<any>,
                    newValue?:
                      | MultipleDataInterface
                      | string
                      | undefined
                      | string[]
                  ) => {
                    storeTasksOnChange(newValue || []);
                  }}
                  placeholder={'Seleccionar tienda'}
                  required
                />
              </div>
            </div>
            <div className="fields-group">
              <div className="field-item">
                <Button
                  text={'Cargar'}
                  onClick={getStorestasksOnClick}
                  size="large"
                  variant="contained"
                  disabled={storeListForSelect.length === 0}
                  border="circular"
                  submit
                  large
                />
              </div>
            </div>
          </form>
        </Container>
        <div className="tasks__main">
          {!refreshTable ? (
            <Table
              columns={taskStoreColumns}
              rows={rows}
              getRowsData={getRowsData}
            />
          ) : (
            <LoaderLocal />
          )}
        </div>
      </div>
    </>
  );
};

export default StoresTasksForm;
