import React, { useState, useEffect, createRef } from 'react';
import { Container } from 'components/Atoms/Container/Container';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-green.svg';
import { ReactComponent as CancelIcon } from 'assets/images/icons/cancel-red.svg';
import TasksService from '../services/TasksService';
import moment from 'moment';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import SimpleTable from 'components-kit/Organisms/SimpleTable/SimpleTable';
import {
  ColumnSimpleTableSchema,
  CustomSimpleTableRef,
  DataSourceSimpleTableSchema,
} from 'components-kit/Organisms/SimpleTable/SimpleTable.types';
import { ReactComponent as SignatureIcon } from 'assets/images/icons/signature.svg';
import Tooltip from 'components/Atoms/Tooltip/Tooltip';
import CountryService from '../../../services/CountryService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataInterface } from 'components/Atoms/Select/Select';
import FilterDetail from 'components-kit/Organisms/FilterDetail/FilterDetail';
import { FilterDetailData } from 'components-kit/Organisms/FilterDetail/FilterDetail.types';
import CertificationsTabFilterOption from 'scenes/Tasks/Actions/components/CertificationsTabFilterOption';
import CertificationsTabLoading from 'scenes/Tasks/Actions/components/CertificationsTabLoading';

export interface CertificationsTableSchema {
  taskId: number;
  permissions: any;
}

const CertificationsTable = ({
  taskId,
  permissions,
}: CertificationsTableSchema) => {
  const mainTableRef = createRef<CustomSimpleTableRef<any>>();
  const [tableLoading, setTableLoading] = useState(false);
  const [columns, setColumns] = useState<ColumnSimpleTableSchema[]>([]);
  const navigate = useNavigate();
  const userAuth = useSelector((state: any) =>
    state?.auth ? state?.auth : []
  );
  const [regionTitle, setRegionTitle] = useState('Región');
  const [cityTitle, setCityTitle] = useState('Ciudad');

  const [loading, setLoading] = useState(true);

  /* Filters */
  const [startDateFilter, setStartDateFilter] = useState(
    moment().add(-6, 'month').format('YYYY-MM-DD')
  );
  const [startDateFilterTemp, setStartDateFilterTemp] = useState(
    moment().add(-6, 'month').toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
    moment().add(1, 'day').format('YYYY-MM-DD')
  );
  const [endDateFilterTemp, setEndDateFilterTemp] = useState(
    moment().add(1, 'day').toDate()
  );

  const [regionIdFilter, setRegionIdFilter] = useState<string | undefined>(
    undefined
  );
  const [regionIdFilterTemp, setRegionIdFilterTemp] =
    useState<DataInterface | null>(null);
  const [cityIdFilter, setCityIdFilter] = useState<string | undefined>(
    undefined
  );
  const [cityIdFilterTemp, setCityIdFilterTemp] =
    useState<DataInterface | null>(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [regions, setRegions] = useState<DataInterface[]>([]);
  const [cities, setCities] = useState<DataInterface[]>([]);

  useEffect(() => {
    loadInitData();
  }, []);

  useEffect(() => {
    mainTableRef.current?.fetchData(1);
  }, [`${startDateFilter}-${endDateFilter}`, regionIdFilter, cityIdFilter]);

  const loadInitData = async () => {
    setLoading(true);

    const [citiesApiResponse, countryResponse] = await Promise.all([
      CountryService.getCitiesByCountry(userAuth.countryCode),
      CountryService.getCountryByCode(userAuth.countryCode),
    ]);

    if (citiesApiResponse.success && countryResponse.success) {
      const localApiCities = citiesApiResponse.data;

      const localRegions = localApiCities.reduce(
        (regions: DataInterface[], city: any) => {
          const { regionId, regionName } = city;
          const existingRegion = regions.find(
            (region) => region.value === regionId
          );

          if (!existingRegion) {
            regions.push({ value: regionId, text: regionName });
          }

          return regions;
        },
        []
      );

      const localCities = localApiCities.map((city: any) => {
        return {
          value: city.cityId,
          text: `${city.cityName} - [${city.regionName}]`,
        };
      });

      let localRegionTitle = 'Región';
      let localCityTitle = 'Ciudad';

      if (
        countryResponse.data?.data?.additionalData?.city_data?.region?.name?.es
      ) {
        localRegionTitle =
          countryResponse.data?.data?.additionalData?.city_data?.region?.name
            ?.es;
        setRegionTitle(localRegionTitle);
      }

      if (
        countryResponse.data?.data?.additionalData?.city_data?.city?.name?.es
      ) {
        localCityTitle =
          countryResponse.data?.data?.additionalData?.city_data?.city?.name?.es;
        setCityTitle(localCityTitle);
      }

      setColumns([
        {
          title: 'Fecha',
          index: 'approvedAtFormatted',
          align: 'left',
        },
        {
          title: 'Nombre y Apellido',
          index: 'fullnameTaskerFormat',
          align: 'left',
        },
        {
          title: (
            <Tooltip title="Contrato firmado" placement="top">
              <SignatureIcon />
            </Tooltip>
          ),
          index: 'signedContractAtFormatted',
          align: 'left',
        },
        {
          title: localRegionTitle,
          index: 'regionName',
          align: 'left',
        },
        {
          title: localCityTitle,
          index: 'provinceName',
          align: 'left',
        },
      ]);

      setRegions(localRegions);
      setCities(localCities);
      setLoading(false);
    }
  };

  const fetchData = async (
    pageSize: number,
    currentPage: number
  ): Promise<DataSourceSimpleTableSchema<any>> => {
    setTableLoading(true);

    const certificationsResponse =
      await TasksService.getCertificationsByIdWithFilters(
        taskId,
        currentPage,
        pageSize,
        startDateFilter,
        endDateFilter,
        regionIdFilter ? [regionIdFilter] : [],
        cityIdFilter ? [cityIdFilter] : []
      );

    setTableLoading(false);

    if (certificationsResponse.success) {
      const certificationsData = certificationsResponse.data.data.map(
        (row: any) => {
          const signedContractCss = row.signedContractAt
            ? 'circle-signed-contract--check'
            : 'circle-signed-contract--cancel';

          return {
            ...row,
            approvedAtFormatted: moment(row.approvedAt).format('DD-MM-YYYY'),
            fullnameTaskerFormat: (
              <p
                className="hyperlink-row"
                onClick={() => {
                  navigate(`/taskers/tasker-selected/${row.userId}`);
                }}
              >{`${row.name} ${row.lastname}`}</p>
            ),
            signedContractAtFormatted: (
              <div className={`circle-signed-contract ${signedContractCss}`}>
                {row.signedContractAt ? <CheckIcon /> : <CancelIcon />}
              </div>
            ),
          };
        }
      );

      return {
        items: certificationsData,
        totalRows: parseInt(certificationsResponse.data.totalRowsFiltered),
      };
    }

    return {
      items: [],
      totalRows: 0,
    };
  };

  const executeAdditionalFilters = async (
    startDateParam: string,
    endDateParam: string,
    regionParam: string | undefined,
    cityParam: string | undefined
  ) => {
    setStartDateFilter(startDateParam);
    setEndDateFilter(endDateParam);
    setRegionIdFilter(regionParam);
    setCityIdFilter(cityParam);
  };

  const getActiveFilters = (): FilterDetailData[] => {
    const sd = moment(startDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const ed = moment(endDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');

    const filters: FilterDetailData[] = [
      {
        key: 'DATE_RANGE_FILTER',
        label: 'Fecha',
        detail: `Del ${sd} al ${ed}`,
        disableRemove: true,
      },
    ];

    if (regionIdFilter) {
      const regionFilter = regions.find((regionLocal) => {
        return regionLocal.value === regionIdFilter;
      });

      if (regionFilter) {
        filters.push({
          key: 'REGION_FILTER',
          label: regionTitle,
          detail: regionFilter.text,
        });
      }
    }

    if (cityIdFilter) {
      const cityFilter = cities.find((cityLocal) => {
        return cityLocal.value === cityIdFilter;
      });

      if (cityFilter) {
        filters.push({
          key: 'CITY_FILTER',
          label: cityTitle,
          detail: cityFilter.text,
        });
      }
    }

    return filters;
  };

  const clearFilters = () => {
    setRegionIdFilter(undefined);
    setCityIdFilter(undefined);
  };

  const removeFilter = (key: string) => {
    switch (key) {
      case 'REGION_FILTER':
        setRegionIdFilter(undefined);
        break;
      case 'CITY_FILTER':
        setCityIdFilter(undefined);
        break;
    }
  };

  return (
    <>
      <Container>
        <div className="certifications__main">
          {!loading &&
            verifyPermissions(
              permissions,
              ROLE_PERMISSIONS.tasks.LOOK_CERTIFICATE_USER_TASK
            ) && (
              <>
                <div className="header__options" style={{ marginBottom: '0' }}>
                  <div
                    className="first__options"
                    style={{ visibility: 'hidden' }}
                  >
                    <div className="searchbar__main">
                      <div className="searchbar__content">
                        <input />
                      </div>
                    </div>
                  </div>
                  <div className="second__options">
                    <CertificationsTabFilterOption
                      isFilterOpen={isFilterOpen}
                      setIsFilterOpen={setIsFilterOpen}
                      startDate={startDateFilterTemp}
                      currentStartDate={startDateFilter}
                      setStartDate={setStartDateFilterTemp}
                      endDate={endDateFilterTemp}
                      currentEndDate={endDateFilter}
                      setEndDate={setEndDateFilterTemp}
                      regionId={regionIdFilterTemp}
                      setRegionId={setRegionIdFilterTemp}
                      currentRegionId={regionIdFilter}
                      cityId={cityIdFilterTemp}
                      setCityId={setCityIdFilterTemp}
                      currentCityId={cityIdFilter}
                      submit={executeAdditionalFilters}
                      regions={regions}
                      cities={cities}
                      regionTitle={regionTitle}
                      cityTitle={cityTitle}
                    />
                  </div>
                </div>

                {/* filter details */}
                <FilterDetail
                  filters={getActiveFilters()}
                  cleanFilters={clearFilters}
                  removeFilter={removeFilter}
                  loading={tableLoading}
                />

                <SimpleTable
                  ref={mainTableRef}
                  columns={columns}
                  request={fetchData}
                  initialDefaultPageSize={5}
                  loading={tableLoading}
                />
              </>
            )}
          {loading && <CertificationsTabLoading />}
        </div>
      </Container>
    </>
  );
};

export default CertificationsTable;
