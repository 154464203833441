import React from 'react';
import { Skeleton } from '@mui/material';
import { Container } from 'components/Atoms/Container/Container';

const CertificationsTabLoading = (): JSX.Element => {
  return (
    <div>
      <h1
        className={`title primary`}
        style={{
          marginBottom: '5px',
          borderRadius: '9px',
          display: 'flex',
          marginTop: '0',
        }}
      >
        <div
          style={{
            height: 'inherit',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: '1',
          }}
        >
          <Skeleton
            variant="circular"
            width={'48px'}
            height={48}
            style={{ marginLeft: '20px' }}
          />
        </div>
      </h1>

      <h1
        className={`title primary`}
        style={{ marginBottom: '15px', marginTop: '0' }}
      >
        <Skeleton
          variant="text"
          width={'63px'}
          height={32}
          style={{ marginBottom: '-5px' }}
        />
        <Skeleton variant="text" width={'183px'} height={32} />
      </h1>

      <Container maxWidth="xl">
        <div className="skeleton-center">
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={60}
            style={{
              marginBottom: '4px',
              borderStartStartRadius: '8px',
              borderStartEndRadius: '8px',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={120}
            style={{ marginBottom: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={60}
            style={{
              borderEndStartRadius: '8px',
              borderEndEndRadius: '8px',
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default CertificationsTabLoading;
