export const tasksListData = [
  {
    id: '',
    name: 'Unimarc',
    client: 'Unimarc',
    vertical: 'Shopper',
    certification: 'Shopper Unimarc',
    creation_date: '17-02-2022',
    status: 0,
  },
  {
    id: '',
    name: 'Concha y Toro',
    client: 'Concha y Toro',
    vertical: 'Operario',
    certification: 'Operario',
    creation_date: '17-02-2022',
    status: 0,
  },
  {
    id: '',
    name: 'San Isabel',
    client: 'Cencosud',
    vertical: 'Driver',
    certification: 'Driver Cencosud',
    creation_date: '17-02-2022',
    status: 0,
  },
  {
    id: '',
    name: 'SPID35',
    client: 'Cencosud',
    vertical: 'Driver',
    certification: 'Driver Spid35',
    creation_date: '17-02-2022',
    status: 0,
  },
];
