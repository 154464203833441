import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import { TaskersListData } from 'data/Taskers/TaskersList';
import { RUT_TYPES } from 'utils/utils';

const TaskersService = {
  getTaskerById: async (id: number): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/user/get-detail-by-tasker-user/${id}`);
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTaskers: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user/get-tasker-users-from-current-country`,
        {
          page: 1,
          pageSize: 100,
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTaskersByPage: async (
    page: number,
    pageSize = 5,
    term?: string,
    regionId?: string,
    cityId?: string
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user/get-tasker-users-from-current-country`,
        {
          page,
          pageSize,
          term,
          regionId,
          cityId,
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTypeAccountsList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTransportTypesList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getYearsList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTaskersList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTransportsTypeList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getYearsTypeList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadTaskersList: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateUserData: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.put(`/taskers/list`, { payload });
            const responseData = response.data.data;
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateVehicleData: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.put(`/taskers/list`, { payload });
            const responseData = response.data.data;
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  uploadVehicleFile: async (
    file: File
  ): Promise<BffApiResponse<{ s3Key: string }>> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await api.post(`/vehicle/upload-file`, formData, config);

      return new BffApiResponse<{ s3Key: string }>(true, {
        s3Key: response.data.data.s3key,
      });
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateDocument: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.patch(`/taskers/list`, { payload });
            const responseData = response.data.data;
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDocumentByType: async (): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.get(`/taskers/list`);
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  addDocument: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
            const response = await api.post(`/taskers/list`, { payload });
            const responseData = response.data.data;
        */
      return new BffApiResponse(true, TaskersListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateTasker: async (id: any, payload: any): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user/update-tasker-user-by-id/${id}`,
        payload
      );
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getContract: async (id: any): Promise<BffApiResponse> => {
    try {
      const response = await api.get(
        `/tasker-contract/regular-type/find-last/${id}`
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAvailablesAccountType: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/account-type/get-by-current-country`);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAvailablesBank: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/bank/get-by-current-country`);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAvailablesVehicleType: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/vehicle-type/`);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAdditionalFieldsVechile: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(
        `/additional-vehicle-field-schema/get-by-country`
      );

      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDoc: async (payload: any): Promise<BffApiResponse> => {
    try {
      const response = await api.post(`/file`, payload);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  uploadContractTasker: async (
    file: File,
    contractDate: string,
    id: number
  ): Promise<BffApiResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contractDate', contractDate);
      const payload = {
        contractDate: contractDate,
        formData,
      };
      const response = await api.post(
        `/tasker-contract/regular-type/manual-provider/create/` + id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAllTaskersForKitByCountry: async (
    countryCode: string,
    page = 1,
    pageSize = 10,
    term?: string
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/user/get-tasker-users-by-country-for-kit/${countryCode}`,
        { page, pageSize, term }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
};

export default TaskersService;
