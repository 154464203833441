import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { toast } from 'react-toastify';
import useForm from 'hooks/useForm';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'components/Atoms/Button/Button';
import Select from 'components/Atoms/Select/Select';
import TextInput from 'components/Atoms/TextInput/TextInput';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import InputNumber from 'components/Molecules/InputNumber/InputNumber';
import TimePicker from 'components/Molecules/TimePicker/TimePicker';
import TurnsService from 'scenes/Tasks/services/TurnsService';
import { transformDataForSelect } from '../../Task.utils';
import { OnChangeValueType } from 'utils/utils';
import { INITIAL_EMPTY_FORM_VALUES, TIMEZONE_LIST } from './forms.utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface UpdateTurnInterface {
  disabledFields: boolean;
  cancelOnClick: () => void;
  onSubmit: () => void;
  taskId: number;
  storesList: any;
  paymentMethodsList: any;
  data: any;
  regionTitle?: string;
  cityTitle?: string;
  defaultCountryTimezone: string;
}

const UpdateTurnForm = ({
  disabledFields,
  cancelOnClick,
  onSubmit,
  taskId,
  storesList,
  paymentMethodsList,
  data,
  regionTitle = 'Región',
  cityTitle = 'Ciudad',
  defaultCountryTimezone,
}: UpdateTurnInterface) => {
  const [loading, setLoading] = useState(true);
  const timezone = data.store_city_timezone || defaultCountryTimezone;
  const [commune, setCommune] = useState('');
  const [region, setRegion] = useState('');
  const [turn, setTurn] = useState('');
  const [paymentMethods, setPaymentMethods] = useState<any>(data.paymentTypes);
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields, resetForm, onMultipleChange } = useForm({
    store: data.store,
    start_date: new Date(
      dayjs(data.raw_start_date).tz(timezone).format('YYYY-MM-DDTHH:mm:00')
    ),
    start_time: dayjs(data.raw_start_date).tz(timezone),
    end_time: dayjs(data.raw_end_date).tz(timezone),
    people_quantity: data.people_quantity,
    turn: {
      text: data.turn.toUpperCase() == 'PM' ? 'PM' : 'AM',
      value: data.turn.toLowerCase() == 'pm' ? 'pm' : 'am',
    },
    original_quantity_required: data.original_quantity_required
      ? data.original_quantity_required
      : '',
  });
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    setLoading(true);
    setIsPrivate(false);

    if (data) {
      onMultipleChange([
        { field: 'store', value: data.store },
        {
          field: 'start_date',
          value: new Date(
            dayjs(data.raw_start_date)
              .tz(timezone)
              .format('YYYY-MM-DDTHH:mm:00')
          ),
        },
        {
          field: 'start_time',
          value: dayjs(data.raw_start_date).tz(timezone),
        },
        {
          field: 'end_time',
          value: dayjs(data.raw_end_date).tz(timezone),
        },
        { field: 'people_quantity', value: data.people_quantity },
        {
          field: 'turn',
          value: data.turn
            ? {
                text: data.turn.toUpperCase() == 'PM' ? 'PM' : 'AM',
                value: data.turn.toLowerCase() == 'pm' ? 'pm' : 'am',
              }
            : '',
        },
        {
          field: 'original_quantity_required',
          value: data.original_quantity_required
            ? data.original_quantity_required
            : '',
        },
      ]);

      setPaymentMethods(data.paymentTypes);
      setRegionFields({ text: '', value: data.storeId });
      setIsPrivate(data.isPrivate);
    }
    setLoading(false);
  }, [data?.id]);

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();

    if ('original_quantity_required' in newErrors) {
      delete newErrors.original_quantity_required;
    }

    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const paymentTypes: any[] = [];
      if (paymentMethods.length > 0) {
        paymentMethods.forEach((payment: any) => {
          paymentTypes.push({
            paymentTypeId: payment.paymentTypeId.value,
            amount: Number.parseInt(payment.amount),
          });
        });
      }
      const payload = {
        taskId: taskId,
        storeId: form.store,
        executionDate: moment(form.start_date).format('YYYY-MM-DD'),
        executionStartTime: moment(`${form.start_time}`).format('HH:mm'),
        executionEndTime: moment(`${form.end_time}`).format('HH:mm'),
        availableTurns: Number.parseInt(form.people_quantity),
        lowCapacityAlertTurns: Number.parseInt(form.people_quantity),
        turnMode: form.turn.text
          ? form.turn.text == 'AM'
            ? 'am'
            : 'pm'
          : form.turn,
        paymentTypes: paymentTypes,
        originalQuantityRequired: form.original_quantity_required
          ? Number.parseInt(form.original_quantity_required)
          : undefined,
        isPrivate: isPrivate,
      };
      const response = await TurnsService.updateTurnById(data.id, payload);
      if (response.statusCode === 200) {
        toast.success('Se Actualizó el turno satisfactoriamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit();
      } else {
        toast.warning('Ocurrió un error actualizando el turno', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
  };

  const setRegionFields = (newValue?: OnChangeValueType | undefined) => {
    let newRegion = '';
    let newCommune = '';
    if (
      !Array.isArray(newValue) &&
      typeof newValue === 'object' &&
      Object.keys(newValue).length > 0
    ) {
      const findStoreSelected: any = storesList.find(
        (store: any) => store.id === Number.parseInt(newValue.value)
      );
      if (findStoreSelected) {
        newCommune = findStoreSelected.provinceName;
        newRegion = findStoreSelected.regionName;
      }
    }
    setCommune(newCommune);
    setRegion(newRegion);
  };

  const paymentMethodOnChange = (
    newValue: OnChangeValueType,
    name: string,
    index: number
  ) => {
    if (name == 'amount') {
      paymentMethods[index][name] = newValue;
    } else {
      paymentMethods[index]['paymentTypeId'] = newValue;
    }
    onChange(form.store, 'store');
  };

  const addPaymentTypeOnClick = () => {
    const index = paymentMethods.length;
    const newPaymentMethods = [...paymentMethods];
    newPaymentMethods.push({
      [`payment_method-${index}`]: '',
      [`amount`]: '',
      [`paymentTypes`]: '',
    });

    setPaymentMethods(newPaymentMethods);
  };

  const cancelFormOnClick = () => {
    resetForm(INITIAL_EMPTY_FORM_VALUES);
    cancelOnClick();
  };

  const activateOnChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivate(_event.target.checked);
  };

  return data && !loading ? (
    <form method="POST" onSubmit={onSubmitForm}>
      <div
        className="fields-group fields-group--cols-2 fields-group--rows-2"
        style={{ marginBottom: '0' }}
      >
        <div className="field-item">
          <Select
            data={transformDataForSelect(storesList || [])}
            errors={errors}
            label={'Tienda'}
            name="store"
            onChange={(_e: any, newValue?: OnChangeValueType) => {
              onChange(newValue || '', 'store');
              setRegionFields(newValue || '');
            }}
            placeholder={'Selecciona una tienda'}
            disabled={disabledFields}
            required
            value={form.store}
            isValueNumeric
          />
        </div>
        <div className="field-item">
          <TextInput
            className="view__task__turn__input"
            label={regionTitle}
            name={`region`}
            value={!loading ? region : ''}
            disabled
            placeholder={`${regionTitle} de la tienda`}
          />
        </div>
        <div className="field-item">
          <TextInput
            className="view__task__turn__input"
            label={cityTitle}
            name={`commune`}
            value={!loading ? commune : ''}
            disabled
            placeholder={`${cityTitle} de la tienda`}
          />
        </div>
        <div className="field-item">
          <DatePicker
            label="Fecha"
            onChange={(newValue?: Date | null) => {
              if (newValue) onChange(newValue.toString(), 'start_date');
              else onChange('', 'start_date');
            }}
            value={form.start_date}
            disabled={disabledFields}
          />
        </div>
        <div className="field-item">
          <TimePicker
            label="Hora inicio"
            name="start_time"
            value={dayjs(form.start_time)}
            placeholder={'Selecciona una hora de inicio'}
            onChange={(newValue?: Dayjs | null) => {
              if (newValue) onChange(newValue.toString(), 'start_time');
              else onChange('', 'start_time');
            }}
            errors={errors}
            disabled={disabledFields}
          />
        </div>
        <div className="field-item">
          <TimePicker
            label="Hora final"
            name="end_time"
            value={dayjs(form.end_time)}
            placeholder={'Selecciona una hora final'}
            onChange={(newValue?: Dayjs | null) => {
              if (newValue) onChange(newValue.toString(), 'end_time');
              else onChange('', 'end_time');
            }}
            errors={errors}
            disabled={disabledFields}
          />
        </div>
        <div className="field-item">
          <InputNumber
            className="view__task__turn__input"
            errors={errors}
            label={'Cantidad de personas'}
            name="people_quantity"
            onChange={(value: string, field: any) => {
              if (value) onChange(value, field || 'people_quantity');
              else onChange('', field || 'people_quantity');
            }}
            disabled={disabledFields}
            defaultValue={form.people_quantity}
          />
        </div>
        <div className="field-item">
          <Select
            data={TIMEZONE_LIST}
            errors={errors}
            label={'Turno'}
            name="turn"
            onChange={(_e: any, newValue?: OnChangeValueType) => {
              if (newValue) {
                onChange(newValue, 'turn');
              } else onChange('', 'turn');
            }}
            placeholder={'Selecciona un turno'}
            value={form.turn}
            disabled={disabledFields}
          />
        </div>
        <div className="field-item">
          <InputNumber
            className="view__task__turn__input"
            label={'Requerimiento del cliente'}
            name="original_quantity_required"
            required={false}
            onChange={(value: string, field: any) => {
              if (value) onChange(value, field || 'original_quantity_required');
              else onChange('', field || 'original_quantity_required');
            }}
            defaultValue={form.original_quantity_required as any}
          />
        </div>
        <div className="field-item" style={{ paddingTop: '18px' }}>
          <FormControlLabel
            key={'input-private-label'}
            control={
              <Checkbox
                color="primary"
                checked={isPrivate}
                onChange={activateOnChange}
              />
            }
            label={
              <p className="text__main" style={{ fontSize: '14px' }}>
                El turno tendrá visibilidad privada (No se mostrará a los
                taskers)
              </p>
            }
            labelPlacement="start"
            style={{ width: '100%' }}
          />
        </div>
        {paymentMethods?.length > 0 &&
          paymentMethods.map((paymentMethod: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className="field-item">
                  <Select
                    data={paymentMethodsList}
                    errors={errors}
                    label={'Tipo de pago'}
                    name={`payment_method-${index}`}
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) {
                        paymentMethodOnChange(
                          newValue,
                          `payment_method-${index}`,
                          index
                        );
                      } else
                        paymentMethodOnChange(
                          '',
                          `payment_method-${index}`,
                          index
                        );
                    }}
                    placeholder={'Selecciona un turno'}
                    value={paymentMethod.paymentTypeId}
                    disabled={disabledFields}
                  />
                </div>
                <div className="field-item">
                  <TextInput
                    className="view__task__turn__input"
                    errors={errors}
                    label={'Monto'}
                    name={`mount-${index}`}
                    onChange={({ target }) =>
                      paymentMethodOnChange(target.value, `amount`, index)
                    }
                    placeholder={'Escribe el monto del método de pago'}
                    defaultValue={paymentMethod.amount}
                    disabled={disabledFields}
                  />
                </div>
              </React.Fragment>
            );
          })}
        <div className="field-item" />
        {!disabledFields && (
          <div className="field-item">
            <div className="select__button" onClick={addPaymentTypeOnClick}>
              <p>Agregar tipo de pago</p>
              <AddIcon />
            </div>
          </div>
        )}
      </div>
      {!disabledFields && (
        <div
          className="extra__buttons__main"
          style={{ marginRight: '0', marginTop: '18px' }}
        >
          <Button
            onClick={cancelFormOnClick}
            text={'Cancelar'}
            type="primary"
            size="large"
            border="circular"
          />
          <Button
            text={'Guardar'}
            type="submit"
            size="large"
            variant="contained"
            border="circular"
            submit
          />
        </div>
      )}
    </form>
  ) : null;
};

export default UpdateTurnForm;
