import React, { useEffect, useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import AlertModal from 'components-kit/Atoms/AlertModal/AlertModal';
import moment from 'moment';
import 'moment/locale/es';
import SwitchOption from 'components-kit/Atoms/Switch/Switch';
import AttendancesService from 'scenes/Kit/Attendances/services/AttendancesService';
import Tooltip from 'components-kit/Atoms/Tooltip/Tooltip';
import { toast } from 'react-toastify';
import { getMainDateConfiguration } from 'utils/utils';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface EditAttendanceModalSchema {
  isModalOpen: boolean;
  closeModal: () => void;
  loading?: boolean;
  submit: (params: UpdateCheckStatesManually) => void;
  userTurnId: string;
}

interface UserTurnDataSchema {
  userId: number;
  turnId: number;
  startTurnDate: Date;
  captorName: string;
  captorLastname: string;
  documentType: string;
  documentNumber: string;
  campaign: string;
  salePoint: string;
  checkIn: Date | null;
  checkInBy: number | null;
  checkInByString: string | null;
  checkOut: Date | null;
  checkOutBy: number | null;
  checkOutByString: string | null;
}

export interface UpdateCheckStatesManually {
  userId: number;
  turnId: number;
  checkIn: {
    edit: boolean;
    date: string | null;
  };
  checkOut: {
    edit: boolean;
    date: string | null;
  };
}

const toastOptions: any = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

const EditAttendanceModal = ({
  isModalOpen,
  closeModal,
  loading = false,
  submit,
  userTurnId,
}: EditAttendanceModalSchema): JSX.Element => {
  const [localLoading, setLocalLoading] = useState(true);
  const [userTurnData, setUserTurnData] = useState<
    UserTurnDataSchema | undefined
  >(undefined);

  const [checkInSwitch, setCheckInSwitch] = useState(false);
  const [checkOutSwitch, setCheckOutSwitch] = useState(false);
  const [checkinTime, setCheckinTime] = useState<Date | null>(null);
  const [checkoutTime, setCheckoutTime] = useState<Date | null>(null);

  const userAuth = useSelector((state: any) =>
    state?.auth ? state?.auth : []
  );

  const [startDate, setStartDate] = useState();

  const mainDateFormat = getMainDateConfiguration(userAuth);
  const mainDateFormatForDatePicker = mainDateFormat
    .replaceAll('D', 'd')
    .replaceAll('Y', 'y');

  useEffect(() => {
    if (isModalOpen) {
      loadData();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (checkOutSwitch && !checkInSwitch && !userTurnData?.checkIn) {
      setCheckInSwitch(true);
    }
  }, [checkOutSwitch]);

  const loadData = async () => {
    const localUserId = parseInt(userTurnId.split('-')[0]);
    const localTurnId = parseInt(userTurnId.split('-')[1]);

    setLocalLoading(true);

    const userTurnDataResponse =
      await AttendancesService.getAttendanceForTitaskByUserTurnKey(
        localUserId,
        localTurnId
      );

    if (userTurnDataResponse.success) {
      const rawData = userTurnDataResponse.data;
      console.log('userTurnDataResponse', rawData);

      const userDateLoaded: UserTurnDataSchema = {
        userId: localUserId,
        turnId: localTurnId,
        startTurnDate: new Date(rawData.startOfExecutionDate),
        captorName: rawData.userName,
        captorLastname: rawData.userLastname,
        documentType: rawData.userDocumentTypeEs,
        documentNumber: rawData.userDocumentNumber,
        campaign: rawData.taskName,
        salePoint: rawData.storeName,
        checkIn: rawData.startedAt ? new Date(rawData.startedAt) : null,
        checkInBy: rawData.startedUserId || null,
        checkInByString: rawData.startedUser || null,
        checkOut: rawData.endedAt ? new Date(rawData.endedAt) : null,
        checkOutBy: rawData.endedUserId || null,
        checkOutByString: rawData.endedUser || null,
      };

      if (!userDateLoaded.checkIn) {
        userDateLoaded.checkInBy = null;
        userDateLoaded.checkInByString = null;
      }

      if (!userDateLoaded.checkOut) {
        userDateLoaded.checkOutBy = null;
        userDateLoaded.checkOutByString = null;
      }

      setUserTurnData(userDateLoaded);

      if (userDateLoaded.checkIn) {
        setCheckinTime(new Date(userDateLoaded.checkIn));
      }

      if (userDateLoaded.checkOut) {
        setCheckoutTime(new Date(userDateLoaded.checkOut));
      }
    }

    setLocalLoading(false);
  };

  const resetData = () => {
    setLocalLoading(true);
    setUserTurnData(undefined);
    setCheckInSwitch(false);
    setCheckOutSwitch(false);
    setCheckinTime(null);
    setCheckoutTime(null);
  };

  const closeModalOnClick = () => {
    resetData();
    closeModal();
  };

  const saveCheckOnClick = async () => {
    if (!(loading || localLoading)) {
      const localUserId = parseInt(userTurnId.split('-')[0]);
      const localTurnId = parseInt(userTurnId.split('-')[1]);

      const sendingParams: UpdateCheckStatesManually = {
        userId: localUserId,
        turnId: localTurnId,
        checkIn: {
          edit: false,
          date: userTurnData?.checkIn
            ? userTurnData?.checkIn.toISOString()
            : null,
        },
        checkOut: {
          edit: false,
          date: userTurnData?.checkOut
            ? userTurnData?.checkOut.toISOString()
            : null,
        },
      };

      if (checkInSwitch) {
        if (checkinTime === null) {
          toast.error(
            'Se debe de elegir la fecha y hora del check in',
            toastOptions
          );
          return;
        }

        sendingParams.checkIn.edit = true;
        sendingParams.checkIn.date = checkinTime.toISOString();
      }

      if (checkOutSwitch) {
        if (checkoutTime === null) {
          toast.error(
            'Se debe de elegir la fecha y hora del check out',
            toastOptions
          );
          return;
        }

        sendingParams.checkOut.edit = true;
        sendingParams.checkOut.date = checkoutTime.toISOString();
      }

      //TODO: validaciones sugerentes:
      /*
        Que las fechas nos pasen la fecha actual
        Que la fecha checkin no sea mayor o igual a la fecha checkout
       */

      submit(sendingParams);
    }
  };

  return (
    <Modal
      modalClassName="modal__main modal__main--kit"
      id="certifications-modal"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <AlertModal
        notClose
        title="Editar asistencia"
        buttons={[
          {
            text: 'Cancelar',
            onClick: () => closeModalOnClick(),
            type: 'primary',
            color: 'slow',
          },
          {
            text: loading || localLoading ? 'Cargando...' : 'Guardar',
            onClick: () => saveCheckOnClick(),
            type: 'primary',
            color: 'hard',
            loading: loading || localLoading,
            hidden: !checkInSwitch && !checkOutSwitch,
          },
        ]}
        closeModal={closeModalOnClick}
        type="success"
        withIcon={false}
      >
        <div className="attendance-modal__main">
          <div className="attendance-description__main">
            <p className="date">
              {userTurnData?.startTurnDate
                ? moment(userTurnData.startTurnDate).locale('es').format('LLL')
                : '--- --- --------- ---------'}
            </p>
            <div className="attendance-info">
              <p className="info-title">
                {loading || localLoading
                  ? 'Cargando...'
                  : userTurnData
                  ? `${userTurnData.captorName} ${userTurnData.captorLastname}`
                  : '-------------------------------'}
              </p>
              <p className="info-dni">
                {userTurnData
                  ? `${userTurnData.documentType}: ${userTurnData.documentNumber}`
                  : '-----: -------------'}
              </p>
            </div>
            <div className="attendance-campaign">
              <p className="campaign-title">
                <b>Campaña:</b>{' '}
                {userTurnData
                  ? userTurnData.campaign
                  : '----------------------'}
              </p>
              <p className="campaign-title">
                <b>Punto de venta:</b>{' '}
                {userTurnData ? userTurnData.salePoint : '--------------'}
              </p>
            </div>
          </div>
          <div className="attendance-time__main">
            <div className="check check-in">
              <div
                className={
                  loading ||
                  localLoading ||
                  !userTurnData ||
                  userTurnData.userId === userTurnData.checkInBy ||
                  !!userTurnData.checkIn
                    ? 'first-side switch__hidden__kit'
                    : 'first-side'
                }
              >
                <SwitchOption
                  checked={checkInSwitch}
                  label="Check in"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCheckInSwitch(event.target.checked)
                  }
                  withoutTooltip
                  disabled={
                    loading ||
                    localLoading ||
                    !userTurnData ||
                    userTurnData.userId === userTurnData.checkInBy ||
                    !!userTurnData.checkIn
                  }
                />
                <div className="time__main">
                  <div className="time__row">
                    <label
                      htmlFor="checkin_time"
                      style={{ marginRight: '10px' }}
                    >
                      <b>Hora:</b>
                    </label>
                    <DatePicker
                      placeholderText={
                        mainDateFormatForDatePicker.toLowerCase() + ' --:--'
                      }
                      selected={checkinTime}
                      onChange={(date) => setCheckinTime(date)}
                      dateFormat={mainDateFormatForDatePicker + ' HH:mm'}
                      showTimeSelect
                      required
                      portalId="root"
                      timeIntervals={5}
                      disabled={!checkInSwitch}
                    />
                    {checkinTime === null && checkInSwitch && (
                      <p className="time__alert">
                        Debes de completar este campo antes de guardar
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="second-side">
                <div className="attendance-role">
                  {userTurnData && (
                    <Tooltip
                      title={`Realizado por: ${
                        userTurnData.checkInByString || '__________'
                      }`}
                      placement="top"
                    >
                      <div
                        className={`role-content ${
                          userTurnData.userId === userTurnData.checkInBy
                            ? 'role-content--user'
                            : 'role-content--supervisor'
                        }`}
                        style={{ marginTop: '10px' }}
                      >
                        <p className="role">
                          {userTurnData.userId === userTurnData.checkInBy
                            ? 'Usuario'
                            : 'Supervisor'}
                        </p>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className="check check-out">
              <div
                className={
                  loading ||
                  localLoading ||
                  !userTurnData ||
                  userTurnData.userId === userTurnData.checkOutBy ||
                  !!userTurnData.checkOut
                    ? 'first-side switch__hidden__kit'
                    : 'first-side'
                }
              >
                <SwitchOption
                  checked={checkOutSwitch}
                  label="Check out"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCheckOutSwitch(event.target.checked)
                  }
                  withoutTooltip
                  disabled={
                    loading ||
                    localLoading ||
                    !userTurnData ||
                    userTurnData.userId === userTurnData.checkOutBy ||
                    !!userTurnData.checkOut
                  }
                />
                <div className="time__main">
                  <div className="time__row">
                    <label
                      htmlFor="checkout_time"
                      style={{ marginRight: '10px' }}
                    >
                      <b>Hora:</b>
                    </label>
                    <DatePicker
                      placeholderText={
                        mainDateFormatForDatePicker.toLowerCase() + ' --:--'
                      }
                      selected={checkoutTime}
                      onChange={(date) => setCheckoutTime(date)}
                      dateFormat={mainDateFormatForDatePicker + ' HH:mm'}
                      showTimeSelect
                      required
                      portalId="root"
                      timeIntervals={5}
                      disabled={!checkOutSwitch}
                    />
                    {checkoutTime === null && checkOutSwitch && (
                      <p className="time__alert">
                        Debes de completar este campo antes de guardar
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="second-side">
                <div className="attendance-role">
                  {userTurnData && (
                    <Tooltip
                      title={`Realizado por: ${
                        userTurnData.checkOutByString || '__________'
                      }`}
                      placement="top"
                    >
                      <div
                        className={`role-content ${
                          userTurnData.userId === userTurnData.checkOutBy
                            ? 'role-content--user'
                            : 'role-content--supervisor'
                        }`}
                        style={{ marginTop: '10px' }}
                      >
                        <p className="role">
                          {userTurnData.userId === userTurnData.checkOutBy
                            ? 'Usuario'
                            : 'Supervisor'}
                        </p>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AlertModal>
    </Modal>
  );
};

export default EditAttendanceModal;
