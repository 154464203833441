import React from 'react';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as TimePickerUI } from '@mui/x-date-pickers/TimePicker';

import './TimePicker.scss';
import ErrorMessage from 'components/Atoms/ErrorMessage/ErrorMessage';

interface TimepickerInferface {
  label: string;
  name: string;
  placeholder?: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  errors?: any;
  errorClassName?: string;
  disabled?: boolean;
}

export default function TimePicker({
  label,
  name,
  placeholder = 'Seleccionar hora',
  value,
  onChange,
  errors = null,
  errorClassName = '',
  disabled = false,
}: TimepickerInferface) {
  const dateOnChange = (date: Dayjs | null) => {
    onChange(date);
  };
  return (
    <div className="time-picker__main">
      {label !== '' && <p className={`textfield__label`}>{`${label}`}</p>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePickerUI
          value={value}
          onChange={dateOnChange}
          disabled={disabled}
          renderInput={(params: any) => (
            <TextField
              className="text-field"
              {...params}
              placeholder={placeholder}
            />
          )}
        />
      </LocalizationProvider>
      {errors && errors[name] && <ErrorMessage className={errorClassName} />}
    </div>
  );
}
