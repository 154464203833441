import { TASK_SELECTED } from 'redux/actions/actionTypes';
import { ReducerInterface } from '../index.utils';

export const INITIAL_STATE = {
  task_selected: null,
};

export default (state = INITIAL_STATE, { type, payload }: ReducerInterface) => {
  switch (type) {
    case TASK_SELECTED:
      return { ...state, task_selected: payload };
    default:
      return { ...state };
  }
};
