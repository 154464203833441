import React from 'react';
import { ReactComponent as HomeImage } from 'assets/images/menu/home.svg';
import { ReactComponent as TaskerImage } from 'assets/images/menu/tasker.svg';
import { ReactComponent as TasksImage } from 'assets/images/menu/tasks.svg';
//import { ReactComponent as CertificationsImage } from 'assets/images/menu/certifications.svg';
import { ReactComponent as QrCodeImage } from 'assets/images/menu/qr-code.svg';
//import { ReactComponent as AssistanceImage } from 'assets/images/menu/assistance.svg';
import { ReactComponent as PaymentsImage } from 'assets/images/menu/payments.svg';
import { ReactComponent as UsersImage } from 'assets/images/menu/users.svg';
import { ReactComponent as SettingsImage } from 'assets/images/menu/settings.svg';
import { POSITIONS } from 'routes/ProtectedRoutes.utils';

export interface ItemsListInterface {
  text: string;
  icon?: any;
  path: string;
  show?: any;
}

export interface NavbarInferface {
  auth: {
    country_iso: string;
  };
  withIcon?: boolean;
  notAuth?: boolean;
  logOutOnClick: () => void;
}

export const CL_ITEMS_LIST: ItemsListInterface[] = [
  {
    text: 'Inicio',
    path: 'home',
    show: [],
  },
  {
    text: 'Tasker',
    path: 'taskers',
    show: [
      POSITIONS.LOCAL_ADMINISTRATOR,
      POSITIONS.BUSINESS_PROCESS,
      POSITIONS.KAM,
      POSITIONS.CONTROL_TOWER,
      POSITIONS.COORDINATOR,
      POSITIONS.LOCAL_BUSINESS_MANAGER,
      POSITIONS.REGIONAL_ADMINISTRATOR,
    ],
  },
  {
    text: 'Tareas',
    path: 'tasks',
    show: [
      POSITIONS.LOCAL_ADMINISTRATOR,
      POSITIONS.BUSINESS_PROCESS,
      POSITIONS.KAM,
      POSITIONS.CONTROL_TOWER,
      POSITIONS.COORDINATOR,
      POSITIONS.LOCAL_BUSINESS_MANAGER,
      POSITIONS.REGIONAL_ADMINISTRATOR,
    ],
  },
  {
    text: 'Planilla de pagos',
    path: 'payments-schedule',
    show: [
      POSITIONS.LOCAL_ADMINISTRATOR,
      POSITIONS.BUSINESS_PROCESS,
      POSITIONS.KAM,
      POSITIONS.COORDINATOR,
      POSITIONS.LOCAL_BUSINESS_MANAGER,
      POSITIONS.REGIONAL_ADMINISTRATOR,
    ],
  },
  {
    text: 'Código QR',
    path: 'qr-code',
    show: [
      POSITIONS.LOCAL_ADMINISTRATOR,
      POSITIONS.CLIENT,
      POSITIONS.REGIONAL_ADMINISTRATOR,
      POSITIONS.CONTROL_TOWER,
      POSITIONS.COORDINATOR,
      POSITIONS.KAM,
      POSITIONS.LOCAL_BUSINESS_MANAGER,
    ],
  },
  {
    text: 'Usuarios',
    path: 'users',
    show: [POSITIONS.LOCAL_ADMINISTRATOR, POSITIONS.REGIONAL_ADMINISTRATOR],
  },
  {
    text: 'Ajustes',
    path: 'settings',
    show: [
      POSITIONS.LOCAL_ADMINISTRATOR,
      POSITIONS.KAM,
      POSITIONS.COORDINATOR,
      POSITIONS.LOCAL_BUSINESS_MANAGER,
      POSITIONS.REGIONAL_ADMINISTRATOR,
    ],
  },
];

export const PE_ITEMS_LIST: ItemsListInterface[] = [
  {
    text: 'Home',
    icon: '',
    path: 'home',
  },
  {
    text: 'Mi perfil',
    icon: '',
    path: 'profile',
  },
  {
    text: 'Certificaciones',
    icon: '',
    path: 'certifications',
  },
  {
    text: 'Tareas Disponibles',
    icon: '',
    path: 'available-tasks',
  },
  {
    text: 'Mis Tareas',
    icon: '',
    path: 'my-tasks',
  },
  {
    text: 'Mis pagos',
    icon: '',
    path: 'my-payments',
  },
];

export const CO_ITEMS_LIST: ItemsListInterface[] = [
  {
    text: 'Home',
    icon: '',
    path: 'home',
  },
  {
    text: 'Mi perfil',
    icon: '',
    path: 'profile',
  },
  {
    text: 'Beneficios',
    icon: '',
    path: 'bonus',
  },
  {
    text: 'Certificaciones',
    icon: '',
    path: 'certifications',
  },
  {
    text: 'Tareas Disponibles',
    icon: '',
    path: 'available-tasks',
  },
  {
    text: 'Mis Tareas',
    icon: '',
    path: 'my-tasks',
  },
  {
    text: 'Mis pagos',
    icon: '',
    path: 'my-payments',
  },
  {
    text: 'Mi Contrato',
    icon: '',
    path: 'my-contract',
  },
];

export const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'home':
      return <HomeImage />;
    case 'users':
      return <UsersImage />;
    case 'qr-code':
      return <QrCodeImage />;
    case 'payments-schedule':
      return <PaymentsImage />;
    /*case 'assistance':
        return <AssistanceImage />;
      case 'certifications':
        return <CertificationsImage />;
      */
    case 'tasks':
      return <TasksImage />;
    case 'taskers':
      return <TaskerImage />;
    default:
      return <SettingsImage />;
  }
};
