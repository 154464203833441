import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import AppTemplate from 'components/Templates/App/App';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';
import Title from 'components/Atoms/Title/Title';
import Stepper from 'components/Atoms/Stepper/Stepper';
import Link from 'components/Atoms/Link/Link';
import { DataInterface } from 'components/Atoms/Select/Select';

import './Create.scss';
import PersonalDataForm from './PersonalDataForm';
import PermissionsDataForm from './PermissionsDataForm';
import TasksForm from './TasksForm';
import UsersService from '../services/UsersService';
import StoresTasksForm from './StoresTasksForm';
import { transformDataForSelect } from '../Users.utils';

const verifyStepThree = (positionsList: any, positionId: string) => {
  if (positionsList.length === 0) return false;
  const { permissions } = positionsList.find(
    (position: any) => position.id === positionId
  );
  if (permissions.length === 0) return false;
  return (
    verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.LOOK_TASK_LIST) &&
    !(
      verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.users.LOOK_ALL_STORES_IN_TASK
      ) &&
      verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.users.LOOK_ALL_TASKS_IN_COUNTRY
      )
    )
  );
};

const CreateUser = () => {
  const permissions = useSelector(
    (state: any) => state.auth?.position?.permissions ?? []
  );
  const positionsList = useSelector(
    (state: any) => state.helper?.positions ?? []
  );
  const [formData, setFormData] = useState({});
  const [disabledNextButton, setdisabledNextButton] = useState(true);
  const [steps, setSteps] = useState(['', '']);
  const [activeStep, setActiveStep] = useState(1);
  const [showStepThree, setShowStepThree] = useState('');
  const [roles, setRoles] = useState<DataInterface[]>([]);
  const [positions, setPositions] = useState<DataInterface[]>([]);
  const { helper } = useSelector((state: any) => state);
  const history = useNavigate();
  const [maxSteps, setMaxSteps] = useState(3);

  useEffect(() => {
    if (permissions.length === 0) history('/home');
    loadData();
  }, []);

  const loadData = async () => {
    const getRoles = await UsersService.getRolesList();
    const getPositions = await UsersService.getPositionsList();
    setRoles(transformDataForSelect(getRoles.data));
    setPositions(transformDataForSelect(getPositions.data));
  };

  const onSubmit = async (payload: any) => {
    if (payload.positionId) setShowStepThree(payload.positionId);
    if (activeStep + 1 < maxSteps) {
      setFormData({
        ...formData,
        ...payload,
      });
      if (
        payload.positionId &&
        verifyStepThree(positionsList, payload.positionId)
      ) {
        setSteps(['', '', '']);
        setMaxSteps(4);
      }
      setdisabledNextButtonHandle(true);
      setActiveStep(activeStep + 1);
    } else {
      if (
        payload.positionId &&
        maxSteps !== 4 &&
        verifyStepThree(positionsList, payload.positionId)
      ) {
        setFormData({
          ...formData,
          ...payload,
        });
        setSteps(['', '', '']);
        setMaxSteps(4);
        setdisabledNextButtonHandle(false);
        setActiveStep(activeStep + 1);
      } else {
        const response = await UsersService.createUser({
          ...formData,
          ...payload,
          tasks: payload.tasks ?? [],
          stores: payload.stores ?? [],
        });

        if (response.data) {
          toast.success('Se creó el usuario correctamente😉', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          history('/users');
        } else {
          toast.warn('¡UPS! Algo ocurrió 😅, por favor contacta a soporte', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
    }
  };

  const goBack = () => {
    if (activeStep > 0) {
      if (maxSteps === 4 && activeStep === 3) {
        setMaxSteps(3);
        setSteps(['', '']);
        setShowStepThree('');
      }
      setdisabledNextButtonHandle(true);
      setActiveStep(activeStep - 1);
    }
  };

  const cancelTabOnClick = () => {
    history('/users');
  };

  const setdisabledNextButtonHandle = (value: boolean) => {
    setTimeout(() => {
      setdisabledNextButton(value);
    }, 100);
  };

  const StepThreeComponent = () => {
    return (
      <Container maxWidth="md">
        {showStepThree !== 'CONTROL_TOWER' ? (
          <TasksForm onSubmit={onSubmit} />
        ) : (
          <Container maxWidth="md">
            <StoresTasksForm onSubmit={onSubmit} />
          </Container>
        )}
      </Container>
    );
  };

  return (
    <AppTemplate>
      <Container>
        <div className="title__main">
          <Title text="Usuarios" type="primary" />
        </div>
        <div className="create__users__main">
          <div className="stepper__create__users">
            <div className="stepper__content">
              <Stepper steps={steps} activeStep={activeStep} />
            </div>
          </div>
          <div className="create__users__template">
            {activeStep === 1 && (
              <PersonalDataForm
                onSubmit={onSubmit}
                typeDocuments={helper.document_types}
                setdisabledNextButton={setdisabledNextButtonHandle}
              />
            )}
            {activeStep === 2 && (
              <Container maxWidth="sm">
                <PermissionsDataForm
                  roles={roles}
                  positions={positions}
                  onSubmit={onSubmit}
                  setdisabledNextButton={setdisabledNextButtonHandle}
                />
              </Container>
            )}
            {activeStep === 3 && <StepThreeComponent />}
          </div>
        </div>
        <div className="create__users__btn__main">
          <div className="actions__btn__main">
            <div className="left__part">
              {activeStep > 1 && (
                <Button
                  onClick={goBack}
                  text={'Anterior'}
                  type="primary"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                  border="circular"
                  large
                />
              )}
              <Link text="Cancelar" onClick={cancelTabOnClick} />
            </div>
            <div className="right__part">
              <Button
                form={`active-form-${activeStep}`}
                text={activeStep === maxSteps - 1 ? 'Enviar' : 'Siguiente'}
                type="submit"
                size="large"
                variant="contained"
                disabled={disabledNextButton}
                border="circular"
                submit
                large
              />
            </div>
          </div>
        </div>
      </Container>
    </AppTemplate>
  );
};

export default CreateUser;
