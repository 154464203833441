import api from 'utils/axios';
import axios from 'axios';
import Country from '../models/Country';
import City from '../models/City';
import nationalities from '../assets/json/nationalities.json';
import countries from '../assets/json/countries.json';
import Nationality from '../models/Nationality';
import BffApiResponse from '../models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import {
  API_GEOLOCATION_URL,
  API_TITASK_URL,
  DEFAULT_COUNTRY,
} from 'config/constants';

const URL = API_TITASK_URL;

const CountryService = {
  getAvailableCountries: async (): Promise<BffApiResponse> => {
    try {
      const response = await axios.get(`${URL}/country`);
      const countries: Country[] = response.data.data.map((country: any) => {
        return {
          code: country.code,
          name: country.name,
          alpha3Code: country.alpha3Code,
          dialCode: country.dialCode,
          language: country.language.trim().substring(0, 2).toLowerCase(),
          specificLanguage: country.language.trim(),
          termsFileUrl: country.termsFileUrl,
          aditionalData: country.aditionalData,
          flag: `/imgs/flags/4x3/${country.code.toLowerCase()}.svg`,
        };
      });

      return new BffApiResponse(true, countries);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getCitiesByCountry: async (countryCode: string): Promise<BffApiResponse> => {
    try {
      const response = await axios.get(`${URL}/country/cities/${countryCode}`);
      const cities: City[] = response.data.data.map((city: any) => {
        return {
          id: city.id,
          regionId: city.regionId,
          regionName: city.regionName,
          provinceId: city.provinceId,
          provinceName: city.provinceName,
          cityId: city.cityId,
          cityName: city.cityName,
          zone: city.zone,
        };
      });

      return new BffApiResponse(true, cities);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getCountryByCode: async (countryCode: string): Promise<BffApiResponse> => {
    try {
      const response = await axios.get(`${URL}/country/${countryCode}`);
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getNationalityByCountryCode: (countryCodeAlpha3: string): Nationality => {
    const country = countries.find(
      (country: any) =>
        country.alpha3.toLowerCase() === countryCodeAlpha3.toLowerCase()
    );

    if (!country) {
      return {
        countryCode: '',
        en: 'Unknown nationality',
        es: 'Nacionalidad desconocida',
        alpha3: 'countryCode',
      };
    }

    const nationality: Nationality | undefined = nationalities.find(
      (nationality: Nationality) =>
        nationality.countryCode.toUpperCase() === country.alpha2.toUpperCase()
    );

    if (!nationality) {
      return {
        countryCode: '',
        en: 'Unknown nationality',
        es: 'Nacionalidad desconocida',
        alpha3: 'countryCode',
      };
    }

    return nationality;
  },
  getCountryAlpha2ByAlpha3(countryCodeAlpha3: string): string {
    const country = countries.find(
      (country: any) =>
        country.alpha3.toLowerCase() === countryCodeAlpha3.toLowerCase()
    );

    if (!country) {
      return 'unknown_alpha2'.toUpperCase();
    }

    return country.alpha2.toUpperCase();
  },
  getLocalCountryFromApi: async (): Promise<string> => {
    try {
      const {
        data: { country: localCountry },
      } = await axios.get(API_GEOLOCATION_URL);
      return localCountry;
    } catch (error) {
      console.error(error);
      return DEFAULT_COUNTRY;
    }
  },
};

export default CountryService;
