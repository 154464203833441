import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface SimpleActionItem {
  text: string | React.ReactNode;
  fontColor?: string;
  action: (row: any) => void;
  hidden?: (row: any) => boolean;
}

interface SimpleActionMenuListSchema {
  index: number;
  row: any;
  actions: SimpleActionItem[];
  actionListReferentialWidth?: number;
}

const SimpleActionMenuList = ({
  index,
  row,
  actions,
  actionListReferentialWidth,
}: SimpleActionMenuListSchema): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [menuStyle, setMenuStyle] = useState<
    { top: string; left: string } | undefined
  >({ top: '0', left: '0' });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        containerRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = (event: any) => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      if (menuRef) {
        const buttonRect = event.currentTarget.getBoundingClientRect();

        let top = buttonRect.top + 0;
        let left = buttonRect.left + window.scrollX + 40;

        if (actionListReferentialWidth) {
          top = buttonRect.top + 0;
          left = buttonRect.left + window.scrollX - actionListReferentialWidth;
        }

        setMenuStyle({
          top: `${top}px`,
          left: `${left}px`,
        });

        setIsOpen(true);
      }
    }
  };

  const getActions = () => {
    return actions.filter((action) => {
      if (!action.hidden) {
        return true;
      }
      return action.hidden(row);
    });
  };

  return (
    <div ref={containerRef}>
      <Button
        onClick={(evt) => {
          toggleMenu(evt);
        }}
      >
        <MoreVertIcon />
      </Button>
      <div
        className={`actions-menu-st${isOpen ? ' actions-menu-st--open' : ''}`}
        style={menuStyle}
        ref={menuRef}
      >
        {getActions().map((el: SimpleActionItem, idx) => (
          <div
            className={`actions-menu-item${
              el.fontColor ? ` actions-menu-item--${el.fontColor}` : ''
            }`}
            key={`item__${index}__${idx}`}
            onClick={() => {
              setIsOpen(false);
              el.action(row);
            }}
          >
            {el.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleActionMenuList;
