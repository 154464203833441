import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import Select from 'components/Atoms/Select/Select';
import TextInput from 'components/Atoms/TextInput/TextInput';
import { ITypeDocuments, OnChangeValueType } from 'utils/utils';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import useForm from 'hooks/useForm';
import { transformDataForSelect } from 'scenes/Profile/Profile.utils';

interface PersonalDataFormInterface {
  onSubmit: (e: any) => void;
  typeDocuments: ITypeDocuments[];
  setdisabledNextButton: (value: boolean) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  name: '',
  lastname: '',
  email: '',
  documentType: {
    text: '',
    value: '',
  },
  documentNumber: '',
};

const PersonalDataForm = ({
  onSubmit,
  typeDocuments,
  setdisabledNextButton,
}: PersonalDataFormInterface) => {
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields } = useForm(INITIAL_EMPTY_FORM_VALUES);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      onSubmit({
        ...form,
        documentType: form.documentType.value,
      });
    }
  };

  const onChangeField = (
    value: string | OnChangeValueType,
    field: 'name' | 'lastname' | 'email' | 'documentType' | 'documentNumber'
  ) => {
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length === 0 && value !== '')
      setdisabledNextButton(false);
    else {
      setdisabledNextButton(true);
      setErrors(newErrors);
    }
    onChange(value, field);
  };

  return (
    <>
      <h2>Datos personales</h2>
      <p>Completa los campos para este usuario</p>
      <div className="user-form__main">
        <form method="POST" onSubmit={onSubmitForm} id="active-form-1">
          <div className="fields-group fields-group--cols-2 fields-group--rows-2">
            <div className="field-item">
              <TextInput
                errors={errors}
                inputProps={{ required: true }}
                label={'Nombres'}
                name="name"
                onChange={({ target }) => onChangeField(target.value, 'name')}
                placeholder={'Escribe el nombre'}
                inputPropsAdornments={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                  required: true,
                }}
              />
            </div>
            <div className="field-item">
              <TextInput
                errors={errors}
                inputProps={{ required: true }}
                label={'Apellidos'}
                name="lastname"
                onChange={({ target }) => {
                  if (target.value) onChangeField(target.value, 'lastname');
                  else onChangeField('', 'lastname');
                }}
                placeholder={'Escribe tus apellidos'}
                inputPropsAdornments={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                  required: true,
                }}
              />
            </div>
            <div className="field-item">
              <TextInput
                errors={errors}
                inputProps={{ required: true }}
                label={'Correo electrónico'}
                name="email"
                onChange={({ target }) => onChangeField(target.value, 'email')}
                placeholder={'Escribe el correo electrónico'}
                inputPropsAdornments={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                  required: true,
                }}
              />
            </div>
            <div className="field-item">
              <Select
                data={transformDataForSelect(typeDocuments)}
                errors={errors}
                label={'Tipo de documento'}
                name="documentType"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChangeField(newValue, 'documentType');
                  else onChange('', 'documentType');
                }}
                placeholder={'Selecciona un tipo de documento'}
                required
              />
            </div>
            <div className="field-item">
              <TextInput
                errors={errors}
                inputProps={{
                  required: true,
                  minLength: 8,
                }}
                label={'Documento'}
                name="documentNumber"
                onChange={({ target }) => {
                  if (target.value)
                    onChangeField(target.value, 'documentNumber');
                  else onChange('', 'documentType');
                }}
                placeholder={'Escribe el documento'}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PersonalDataForm;
