/* eslint-disable prettier/prettier */
import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import MaterialTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';

import './Table.scss';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  SelectChangeEvent,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import SwitchOption from '../../Atoms/Switch/Switch';
import FooterTable from './FooterTable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  ActionsInterface,
  ColumnInterface, CustomTableRef,
  FilterRowValueInterface,
  FilterValueInterface,
  OrderColumnInterface,
  StatusOptionsInterface,
  TableInterface,
  USERS_ACTIONS_TABLE,
  USERS_SINGLE_ACTIONS_TABLE,
} from './Table.utils';
import { MultipleDataInterface } from 'components/Atoms/MultipleSelect/MultipleSelect';
import FilterOption from './FilterOption';
import Select, { DataInterface } from 'components/Atoms/Select/Select';
import moment from 'moment';
import TextInput from 'components/Atoms/TextInput/TextInput';

/**
 *
 * @param searchFields fields prop to be assign fot the search bar option
 * @param alignText align text to the table
 * @param statusOptions the options for the status column
 * @param columns columns table
 * @param rows rows table
 * @param orderByColumns fields prop to be assign with the order option
 * @param selectedColumns enable selected checkbox option as a first column
 * @param selectedFieldsOnChange receive the onchange select checkbox event
 * @param filterColumns fields prop to be assign with the filter option
 * @param selectedColumnsName columns to be selected
 * @param selectedActionsOnChange receive the onchange select checkbox event for the columns to be selected
 * @param firstOptions React component for the left side header table options, like search bar form
 * @param secondOptions React component for the right side header table options, like filter button and download files buttons
 * @param minWidth minimum width of the table container
 * @param customRows array with the react components for each custom row
 * @returns
 */
// eslint-disable-next-line react/display-name
const Table = forwardRef<CustomTableRef, TableInterface>((props, ref) => {

  const {
    searchFields = [],
        alignText = 'left',
        statusOptions = [],
        columns = [],
        rows = [],
        orderByColumns = [],
        selectedColumns = false,
        selectedFieldsOnChange,
        filterColumns = [],
        selectedColumnsName = '',
        selectedActionsOnChange,
        firstOptions,
        secondOptions,
        minWidth = 650,
        customRows = [],
        getRowsData,
        singleUserActionsTable = USERS_SINGLE_ACTIONS_TABLE,
        multiUserActionsTable = USERS_ACTIONS_TABLE,
        pageSize,
        initFilter,
        orderDirection
  } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<OrderColumnInterface[]>([]);
  const [page, setPage] = useState(0);
  const [searched, setSearched] = useState<string>('');
  const [selected, setSelected] = useState<any[]>([]);
  const [currentRows, setCurrentRows] = useState(rows);
  const [rowsData, setRowsData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize || 5);
  const [filter, setFilter] = useState({});
  const [isFilterClean, setIsFilterClean] = useState(false);
  const [actionSelected, setActionSelected] = useState(-1);
  const [itemsForSelect, setItemsForSelect] = useState<string[]>([]);
  const numSelected = selected.length;
  const history = useNavigate();

  useEffect(() => {
    if(initFilter) {
      setFilter(initFilter);
    }
  }, []);

  useEffect(() => {
    filterData(filter);
  }, [filter]);

  useEffect(() => {
    if (orderBy.length === 0) {
      const newOrderColumn: OrderColumnInterface[] = [];
      orderByColumns.forEach((column) => {
        newOrderColumn.push({
          name: column,
          order: (orderDirection) ? orderDirection : 'asc',
        });
      });

      setOrderBy(newOrderColumn);
      newOrderColumn.forEach((column) => {
        handleRequestSort(column.name, newOrderColumn);
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    forceSetData(newRows: any) {
      setActionSelected(-1);
      setCurrentRows(newRows);
      filterData(filter, newRows);
    },
    closeActions() {
      setActionSelected(-1);
    }
  }))

  const getDataForSelect = (
    prop: string,
    search: string | null = '',
    init?: boolean,
    dates?: any
  ) => {
    let dataForSelect: any = [];
    if (prop && search) {
      currentRows.forEach((row) => {
        if (row[prop].toLowerCase().includes(search.toLowerCase())) {
          const getStatusText = row[prop] === 0 ? 'Activo' : 'Inactivo';
          const getTextValue = prop === 'status' ? getStatusText : row[prop];
          const newData = [
            ...dataForSelect,
            {
              text: getTextValue,
              value: getTextValue,
            },
          ];
          dataForSelect = newData;
        }
      });
    } else {
      currentRows.forEach((row: any) => {
        if (filterColumns.includes(prop)) {
          let statusText = '';
          let obtainStatus: StatusOptionsInterface[] = [];
          if (prop === 'status') {
            obtainStatus = statusOptions.filter(
              (option) => option.value === `${row[prop]}`
            );
            if (obtainStatus.length > 0) {
              statusText = obtainStatus[0].label;
            }
          }
          if (prop.includes('date') && dates && Object.keys(dates).length > 0) {
            const startDate = moment(dates['startDate']).startOf('day');
            const endDate = moment(dates['endDate']).endOf('day');
            const currentMomentData = moment(row[prop], 'DD-MM-YYYY');
            const validation = (currentMomentData.isBetween(startDate, endDate, 'days', '[]'));

            if (validation) {
              const newData = [
                ...dataForSelect,
                {
                  text: row[prop],
                  value: row[prop],
                },
              ];
              dataForSelect = newData;
            }
          } else {
            const newData = [
              ...dataForSelect,
              {
                text: prop === 'status' ? statusText : row[prop],
                value: prop === 'status' ? statusText : row[prop],
              },
            ];
            dataForSelect = newData;
          }
        }
      });
    }
    if (dataForSelect.length > 0) {
      return dataForSelect.filter(
        (value: MultipleDataInterface, index: number, self: any) =>
          self.findIndex(
            (valueTwo: MultipleDataInterface) => valueTwo.text === value.text
          ) === index
      );
    }
    return dataForSelect;
  };

  const filterData = (newFilter: FilterValueInterface, newRows?: any) => {
    let rowsForUse = currentRows;

    if(newRows !== undefined) {
      rowsForUse = newRows;
    }

    const filteredData: any = [];
    const newFilterLength = Object.keys(newFilter).length;
    if (newFilterLength > 0) {
      rowsForUse.forEach((row: FilterRowValueInterface) => {
        const rowMatches: boolean[] = new Array(newFilterLength).fill(false);
        Object.entries(row).forEach(([rowKey, rowValue]) => {

          Object.entries(newFilter).forEach(([key, value], index) => {
            if (
              (value.length > 0 || rowKey.includes('date')) &&
              rowKey === key
            ) {
              let compareValue = rowValue;
              if (rowKey === 'status') {
                const isFound = statusOptions.find((status: any) => rowValue == status.value);
                if (isFound) compareValue = isFound.label;
              }
              if (value.toString().includes(compareValue.toString()))
                rowMatches[index] = true;
              else rowMatches[index] = false;

              if (rowKey.includes('date')) {
                const datesValue: any = Object.assign({}, value);
                const actualValue = moment(rowValue, 'DD-MM-YYYY');
                const startDate = moment(datesValue['startDate']).startOf('day');
                const endDate = moment(datesValue['endDate']).endOf('day');
                rowMatches[index] = (actualValue.isBetween(startDate, endDate, 'days', '[]'));
              }
            } else {
              if (value.length === 0) rowMatches[index] = true;
            }
          });
        });

        if (rowMatches.findIndex((item: boolean) => item === false) < 0)
          filteredData.push(row);
      });
      if (filteredData.length > 0) refreshData(filteredData);
      else {
        // refreshData(currentRows);
        refreshData([]);
      }
    } else {
      refreshData(rowsForUse);
    }
  };

  const changeFilter = (newFilter: FilterValueInterface) => {
    setFilter(newFilter);
    setIsFilterOpen(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  const requestSearch = (searchedVal: string) => {
    let filteredRows: any = [];
    if (searchedVal != '' || searchedVal.includes(' ')) {
      setSearched(searchedVal);
      filteredRows = currentRows.filter((row) => {
        return searchFields.find((searchField) => {
          if (row[searchField]) {
            return row[searchField]
              .toString()
              .toLowerCase()
              .includes(searchedVal.toString().toLowerCase());
          } else return false;
        });
      });
    } else {
      setSearched('');
      filteredRows.push(...(currentRows.length > 0 ? currentRows : []));
    }
    if (filteredRows.length > 0) {
      setRowsData(filteredRows);
    }
  };

  const validOrderByColumn = (property: string) => {
    const isOrder = orderBy.find(
      (orderColumn: OrderColumnInterface) => orderColumn.name === property
    );
    return isOrder && isOrder.order ? true : false;
  };

  const getOrderByColumn = (property: string) => {
    const order = orderBy.find(
      (orderColumn: OrderColumnInterface) => orderColumn.name === property
    );

    return order ? order.order : undefined;
  };

  const handleRequestSort = (
    property: string,
    init: OrderColumnInterface[] = []
  ) => {
    const isOrder = orderBy.find(
      (orderColumn: OrderColumnInterface) => orderColumn.name === property
    );
    if (isOrder || init.length > 0) {
      const tempOrderBy = orderBy.length > 0 ? orderBy : init;
      const newOrderBy = tempOrderBy.map((orderColumn) => {
        if (orderColumn.name === property && init.length === 0) {
          let newOrder = isOrder ? isOrder.order : 'asc';
          if (newOrder === 'asc') newOrder = 'desc';
          else if (newOrder === 'desc') newOrder = 'asc';
          else newOrder = 'asc';
          return { ...orderColumn, order: newOrder };
        }

        return orderColumn;
      });

      if (newOrderBy.length > 0) {
        let newOrderData = rowsData;

        newOrderBy.forEach((orderByColumn: OrderColumnInterface) => {
          const isSecond = (a: any, b: any) =>
            b[orderByColumn.name] > a[orderByColumn.name] ? -1 : 0;
          const isFirst = (a: any, b: any) =>
            a[orderByColumn.name] > b[orderByColumn.name] ? 1 : isSecond(a, b);
          if (!orderByColumn.order) {
            newOrderData = rows;
          } else if (orderByColumn.order === 'asc') {
            newOrderData = newOrderData.sort((a, b) => isFirst(a, b));
          } else if (orderByColumn.order === 'desc') {
            newOrderData = newOrderData.sort((a, b) => isFirst(a, b));
            newOrderData = newOrderData.reverse();
          }
        });
        setRowsData(newOrderData);
      }
      setOrderBy(newOrderBy);
    } else {
      setRowsData(currentRows);
    }
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    setRowsPerPage(Number.parseInt(event.target.value));
  };

  const isSelectedFieldsOnChange = (newSelected: any) => {
    if (selectedFieldsOnChange) selectedFieldsOnChange(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = currentRows.map((row: any) => row);
      setSelected(newSelecteds);
      if (selectedFieldsOnChange) isSelectedFieldsOnChange(newSelecteds);
      return;
    }
    setSelected([]);
    if (selectedFieldsOnChange) isSelectedFieldsOnChange([]);
  };

  const isSelected = (row: any, index: number) => {
    return selected.findIndex((item, i) => (item.rowId ?? i) === (row.rowId ?? index)) !== -1;
  };

  const handleClick = (_event: React.MouseEvent<unknown>, row: any, index: number) => {
    const rowForSelectedList = { ...row, rowId: row.rowId ?? index };
    const selectedIndex = selected.findIndex((item: any, i: number) => (item.rowId ?? i) === (row.rowId ?? index));
    
    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowForSelectedList);
    } else {
      newSelected = selected.filter((item: any, i: number) => (item.rowId ?? i) !== (row.rowId ?? index));
    }
    setSelected(newSelected);

    if (selectedFieldsOnChange) selectedFieldsOnChange(newSelected);
  };

  const searchFieldsNames = () => {
    const newSearchFields: string[] = [];
    searchFields?.forEach((field: string) => {
      const column = columns?.find((columnItem) => columnItem.prop === field);
      if (column?.label) newSearchFields.push(column.label);
    });
    return newSearchFields.toString().replaceAll(',', ', ');
  };

  const createSortHandler = (property: string) => () => {
    handleRequestSort(property);
  };

  const getOrder = (id: string) => {
    if (getOrderByColumn(id) === 'desc') {
      return 'sorted descending';
    } else if (getOrderByColumn(id) === 'asc') {
      return 'sorted ascending';
    } else {
      return 'not sorted';
    }
  };

  const refreshData = (data: any) => {
    setRowsData(data);
    if(getRowsData !== undefined) getRowsData(data);
  };

  const filterHandleClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const removeFilterItemOnClick = (el: string) => {
    const newFilterItems: any = filter;
    const filterKeys: any = Object.keys(filter);
    const findIndex = filterKeys.findIndex((item: any) => item == el);
    delete newFilterItems[el];
    const newItems: string[] = itemsForSelect;
    if (findIndex > -1) {
      newItems.splice(findIndex, 1);
    }
    setItemsForSelect(newItems);
    setFilter(newFilterItems);
    let valueForSelect: any = '';
    Object.entries(filter).forEach(([key, value]) => {
      if (el.includes(key)) {
        valueForSelect = value;
      }
    });
    const newItemsTwice: string[] = itemsForSelect.filter(
      (item: string) => !valueForSelect.includes(item)
    );
    setItemsForSelect(newItemsTwice);
    filterData(newFilterItems);
  };

  const changeItemsForSelect = (newItems: string[] = []) => {
    setItemsForSelect(newItems);
  };

  const cleanFilter = () => {
    changeItemsForSelect([]);
    setFilter({});
    setIsFilterClean(true);

    setTimeout(() => {
      setIsFilterClean(false);
    }, 50);
  };

  const getKeyLabel = (key: string) => {
    let label = key;
    const columnFounded = columns.find((col: any) => col.prop === key);
    if (columnFounded?.label) label = columnFounded.label;

    return label;
  };

  return (
    <Fragment>
      <div className="header__options">
        <div className="first__options">
          {searchFields.length > 0 && (
            <div className="searchbar__main">
              <div className="searchbar__content">
                <TextInput
                  className="searchbar__input"
                  name="searched"
                  onChange={({ target }: any) => requestSearch(target.value)}
                  placeholder={`Buscar por ${searchFieldsNames()}`}
                  inputPropsAdornments={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="searchbar__icon__main">
                          <SearchIcon className="searchbar__icon" />
                        </div>
                      </InputAdornment>
                    ),
                    required: true,
                  }}
                  value={searched}
                />
              </div>
            </div>
          )}
          {firstOptions}
        </div>
        <div className="second__options">
          {filterColumns.length > 0 && (
            <>
              <FilterOption
                columns={columns}
                filter={filter}
                filterColumns={filterColumns}
                isFilterOpen={isFilterOpen}
                filterHandleClick={filterHandleClick}
                getDataForSelect={getDataForSelect}
                itemsForSelect={itemsForSelect}
                changeItemsForSelect={changeItemsForSelect}
                changeFilter={changeFilter}
                cleanFilter={isFilterClean}
              />
            </>
          )}
          {secondOptions}
        </div>
      </div>
      {Object.keys(filter)?.length > 0 && (
        <div className="show-filters__main">
          <div className="filters__options">
            <p className="filters-length">
              Filtros: <span>{Object.keys(filter).length}</span>
            </p>
            <div className="clean-filter__content" onClick={cleanFilter}>
              <p className="clean-filter">Limpiar Filtros</p>
            </div>
          </div>
          <div className="filters__items">
            {Object.entries(filter).map(([key, value]: any) => {
              if (value?.length > 0) {
                return (
                  <Chip
                    key={key}
                    label={
                      <>
                        <span className="key-text">{getKeyLabel(key)}:</span>
                        <span className="value-text">
                          {` ${value && value.join(', ')}`}
                        </span>
                      </>
                    }
                    onDelete={() => removeFilterItemOnClick(key)}
                  />
                );
              }
              if (typeof value === 'object') {
                const startDate = moment(value.startDate).format('DD-MM-YYYY');
                const endDate = moment(value.endDate).format('DD-MM-YYYY');
                return (
                  <Chip
                    key={key}
                    label={
                      <>
                        <span className="key-text">{getKeyLabel(key)}:</span>
                        <span className="value-text">
                          {` Del ${startDate} al ${endDate}`}
                        </span>
                      </>
                    }
                    onDelete={() => removeFilterItemOnClick(key)}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
      {selected?.length > 0 && (
        <div className="selected-actions__main">
          <div className="selected__field">
            <Select
              data={
                selected.length === 1
                  ? singleUserActionsTable
                  : multiUserActionsTable
              }
              label=""
              name="select_actions"
              onChange={(
                _event: any,
                newValue?: DataInterface | string | undefined
              ) => {
                if (selectedActionsOnChange) {
                  if (newValue) selectedActionsOnChange(newValue);
                  else selectedActionsOnChange('');
                }
              }}
              placeholder={'Acciones'}
            />
          </div>
          <p className="selected-text">
            Se han seleccionado{' '}
            <span className="selected-text--number">{selected.length}</span>
            {` ${selectedColumnsName}`}
          </p>
        </div>
      )}

      <TableContainer className="table__main" component={Paper}>
        <MaterialTable sx={{ minWidth }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {selectedColumns && (
                <TableCell align={alignText}>
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < currentRows.length}
                    checked={rows.length > 0 && numSelected === currentRows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              )}
              {columns?.length > 0 &&
                columns.map((column) => column.hiddenColumn ? null : (
                  <TableCell key={column.prop} size={column.size ?? 'medium'} style={column.customStyle}>
                    {column.customLabel ? (
                      column.customLabel
                    ) : (
                      <TableSortLabel
                        active={validOrderByColumn(column.id)}
                        direction={getOrderByColumn(column.id)}
                        onClick={createSortHandler(column.id)}
                        IconComponent={ArrowDropDownIcon}
                        hideSortIcon
                      >
                        {column.label}
                        {validOrderByColumn(column.id) ? (
                          <Box component="span" sx={visuallyHidden}>
                            {getOrder(column.id)}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 && rowsData?.length > 0
              ? rowsData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : rowsData
            ).map((row, index) => {
              const isItemSelected = isSelected(row, index);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                >
                  {selectedColumns && (
                    <TableCell
                      padding="checkbox"
                      id={`checkbox-table-${index}`}
                      onClick={(event) =>
                        selectedColumns ? handleClick(event, row, index) : ''
                      }
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': `checkbox-table-${index}`,
                        }}
                      />
                    </TableCell>
                  )}
                  {Object.entries(row).map(
                    ([key, value]: any, indexRow: number) => {
                      const recentColumn: ColumnInterface | undefined =
                        columns.find((column) => column.prop === key);
                      if (!recentColumn?.hiddenColumn) {
                        if (recentColumn?.type === 'number_list') {
                          // const idValue = index + 1;
                          const idValue = value;
                          return (
                            <TableCell
                              key={`${key}-${value}-${indexRow}`}
                              align={alignText}
                              component="th"
                              scope="row"
                            >
                              <span className="number__list__value">
                                {idValue < 10 ? `0${idValue}` : idValue}
                              </span>
                            </TableCell>
                          );
                        } else if (recentColumn?.type === 'status') {
                          const status = statusOptions?.find(
                            (statusOp) =>
                              statusOp.value.toString() === value.toString()
                          );
                          return (
                            <TableCell
                              key={`${key}-${value}-${indexRow}`}
                              align={alignText}
                              component="th"
                              scope="row"
                            >
                              {status && (
                                <div
                                  className={`status__main status__${status.type} status__${status.type}--bg-${status.backgroundColor}`}
                                >
                                  <p
                                    className={`status__text status__text--${status.fontColor}`}
                                  >
                                    {status.label}
                                  </p>
                                </div>
                              )}
                            </TableCell>
                          );
                        } else if (recentColumn?.type === 'actions') {
                          let isColumnOpen = false;
  
                          const rowHandleClose = (el?: any) => {
                            if (el.path) history(el.path);
                            if (el.onClick) el.onClick(row);
                            isColumnOpen = !isColumnOpen;
                          };
  
                          const buttonHandleClick = () => {
                            if (actionSelected === index) setActionSelected(-1);
                            else setActionSelected(index);
                          };
                          return (
                            <TableCell
                              key={`${key}-${index}-${indexRow}`}
                              align={alignText}
                              component="th"
                              scope="row"
                            >
                              <Button
                                id={`${key}-${index}-${indexRow}`}
                                onClick={buttonHandleClick}
                              >
                                <MoreVertIcon />
                              </Button>
                              <div
                                id={`${key}-${index}-${indexRow}`}
                                className={`actions-menu
                                ${index === actionSelected
                                    ? ' actions-menu--open'
                                    : ''
                                  }`}
                              >
                                {value.map((el: ActionsInterface) => (
                                  <div
                                    className={`actions-menu-item${el.fontColor
                                      ? ` actions-menu-item--${el.fontColor}`
                                      : ''
                                      }`}
                                    key={`${key}-${index}-${indexRow}-${el.label}`}
                                    onClick={() => rowHandleClose(el)}
                                  >
                                    {el.label}
                                  </div>
                                ))}
                              </div>
                            </TableCell>
                          );
                        } else if (recentColumn?.type === 'switch') {
                          const [rowStatus, setRowStatus] = useState(row.status);
                          return (
                            <TableCell
                              key={`${key}-${value}-${indexRow}`}
                              align={alignText}
                              component="th"
                              scope="row"
                            >
                              <div className="switch__main">
                                <SwitchOption
                                  checked={rowStatus}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const newData = rowsData;
                                    newData[index] = {
                                      ...row,
                                      status: event.target.checked,
                                    };
                                    refreshData(newData);
                                    setRowStatus(event.target.checked);
                                  }}
                                  withoutTooltip
                                />
                              </div>
                            </TableCell>
                          );
                        } else if (recentColumn?.type === 'stores') {
                          return (
                            <TableCell
                              key={`${key}-${index}-${indexRow}`}
                              align={alignText}
                              component="th"
                              scope="row"
                            >
                              <div className="stores__cell">
                                <p className="number__text">{value}</p>
                                <p>{` Tareas asignadas`}</p>
                              </div>
                            </TableCell>
                          );
                        } else if (recentColumn?.type === 'custom') {
                          const findCustomRow = customRows.find(
                            (el) => el.prop === key
                          );
                          if (findCustomRow) {
                            return (
                              <TableCell
                                key={`${key}-${index}-${indexRow}`}
                                align={alignText}
                                component="th"
                                scope="row"
                              >
                                {findCustomRow.row(row, value)}
                              </TableCell>
                            );
                          }
                          return null;
                        }
                        return (
                          <TableCell
                            key={`${key}-${value}-${indexRow}`}
                            align={alignText}
                            component="th"
                            scope="row"
                          >
                            <span
                              className={`table-cell${recentColumn?.fontColor
                                ? ` table-cell--${recentColumn.fontColor}`
                                : ''
                                }`}
                            >
                              {value}
                            </span>
                          </TableCell>
                        );
                      }
                      return null;
                    }
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <FooterTable
        handleChangePage={handleChangePage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rows={rows}
        rowsData={rowsData}
        rowsPerPage={rowsPerPage}
      />
    </Fragment>
  );
});

export default Table;
