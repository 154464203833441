import { DataInterface } from 'components/Atoms/Select/Select';
import React from 'react';

export interface StatusOptionsInterface {
  label: string;
  value: number | string;
  backgroundColor: string;
  fontColor: string;
  type: 'outlined' | 'filled';
}

export interface ActionsInterface {
  label: string;
  path?: string;
  onClick?: () => void;
  fontColor?: string;
}

export interface ColumnInterface {
  id: string;
  prop: string;
  sort?: boolean;
  label: string;
  fontColor?: string;
  type?: string;
  customLabel?: React.ReactNode;
  hiddenColumn?: boolean;
  size?: 'small' | 'medium' | undefined;
  customStyle?: any;
}

interface ActionsTableInterface {
  text: string;
  value: string;
}

export interface TableInterface {
  alignText?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  firstOptions?: React.ReactNode;
  secondOptions?: React.ReactNode;
  searchFields?: string[];
  statusOptions?: StatusOptionsInterface[];
  columns: ColumnInterface[];
  rows: any[];
  orderByColumns?: string[];
  selectedColumns?: boolean;
  selectedFieldsOnChange?: (newSelecteds: any) => void;
  filterColumns?: string[];
  selectedColumnsName?: string;
  selectedActionsOnChange?: (el: DataInterface | string | undefined) => void;
  withNumbersColumn?: boolean;
  firstOption?: boolean;
  minWidth?: number;
  customRows?: CustomRowInterface[];
  getRowsData?: (rowsData: any) => void;
  singleUserActionsTable?: ActionsTableInterface[];
  multiUserActionsTable?: ActionsTableInterface[];
  pageSize?: number;
  initFilter?: any;
  orderDirection?: 'asc' | 'desc';
}

export interface CustomTableRef {
  forceSetData(newRows: any): void;
  closeActions(): void;
}

export interface CustomRowInterface {
  prop: string;
  row: (row: any, value: string) => React.ReactNode;
}

export interface OrderColumnInterface {
  name: string;
  order: 'desc' | 'asc';
}

export const USERS_SINGLE_ACTIONS_TABLE = [
  {
    text: 'Ver',
    value: 'view',
  },
  {
    text: 'Editar',
    value: 'edit',
  },
  {
    text: 'Activar',
    value: 'activate',
  },
  {
    text: 'Desactivar',
    value: 'deactivate',
  },
];

export const USERS_ACTIONS_TABLE = [
  {
    text: 'Activar',
    value: 'activate',
  },
  {
    text: 'Desactivar',
    value: 'deactivate',
  },
];

export interface FilterValueInterface {
  [key: string]: string[];
}

export interface FilterRowValueInterface {
  [key: string]: any;
}

export const IGNORE_COLUMNS_FOR_FILTER = ['date', 'status'];
