import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATION_ES_CL from 'assets/lang/es_CL.json';

const EN_US = 'en_US';
const ES_CO = 'es_CO';
const ES_CL = 'es_CL';
const ES_MX = 'es_MX';
const ES_PE = 'es_PE';
const ES_US = 'es';

export const getCountryLanguage = (country: string) => {
  switch (country) {
    case 'es_PE':
    case 'PE':
      return ES_PE;
    case 'es_MX':
    case 'MX':
      return ES_MX;
    case 'es_CO':
    case 'CO':
      return ES_CO;
    case 'en_US':
    case 'US':
      return EN_US;
    case 'none':
    case 'es_US':
      return ES_US;
    default:
    case 'es_CL':
    case 'CL':
      return ES_CL;
  }
};

export const t = (value: string) => i18n.t(value);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: 'es_CL',
    fallbackLng: ['en_US', 'es_CL'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      /*
      en: {
        translation: TRANSLATION_EN,
      },
      en_US: {
        translation: TRANSLATION_EN_US,
      },
      es_CO: {
        translation: TRANSLATION_ES_CO,
      },
      es_MX: {
        translation: TRANSLATION_ES_MX,
      },
      es_PE: {
        translation: TRANSLATION_ES_PE,
      },
      */
      es_CL: {
        translation: TRANSLATION_ES_CL,
      },
      /*
      es: {
        translation: TRANSLATION_ES,
      },
      */
    },
  });

export default i18n;
