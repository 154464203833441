import React, { useEffect, useState } from 'react';
import useForm from 'hooks/useForm';
import Table from 'components/Organisms/Table/Table';
import MultipleSelect, {
  MultipleDataInterface,
} from 'components/Atoms/MultipleSelect/MultipleSelect';
import { SelectChangeEvent } from '@mui/material';
import { Container } from 'components/Atoms/Container/Container';
import { ColumnInterface } from 'components/Organisms/Table/Table.utils';
import UsersService from '../services/UsersService';
import { ITasks, transformTasksDataForSelect } from '../Users.utils';
import { DataInterface } from 'components/Atoms/Select/Select';
import LoaderLocal from 'components/Atoms/LoaderLocal/LoaderLocal';

interface TasksFormInterface {
  onSubmit: (e: any) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  tasks_list: '',
};

const TasksForm = ({ onSubmit }: TasksFormInterface) => {
  const [tasksList, setTasksList] = useState([]);
  const [rowsFromTable, setRowsFromTable] = useState([]);
  const [tasksListForSelect, setTasksListForSelect] = useState<DataInterface[]>(
    []
  );
  const [refreshTable, setRefreshTable] = useState(false);
  const [rows, setRows] = useState<ITasks[]>([]);
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { onChange, validFields } = useForm(INITIAL_EMPTY_FORM_VALUES);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const response = await UsersService.getTasksList();

    if (response.data?.length > 0) {
      setTasksListForSelect(transformTasksDataForSelect(response.data));
      setTasksList(response.data);
    }
  };

  const columns: ColumnInterface[] = [
    {
      id: 'number',
      prop: 'number',
      sort: false,
      label: 'N°',
      type: 'numeric_list',
    },
    {
      id: 'task_name',
      prop: 'task_name',
      sort: false,
      label: 'Nombre de Tarea',
    },
    {
      id: 'client',
      prop: 'client',
      sort: false,
      label: 'Cliente',
    },
    {
      id: 'status',
      prop: 'status',
      sort: false,
      label: 'Estado',
      type: 'switch',
    },
  ];

  const formOnSubmit = (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    console.log(errors);
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const newData = rowsFromTable.map((row: any) => ({
        taskId: row.number,
        isDisabled: !row.status,
      }));
      onSubmit({
        tasks: newData,
        stores: [],
      });
    }
  };

  const onChangeSelect = (
    newValue?: MultipleDataInterface | string | undefined | string[]
  ) => {
    setRefreshTable(true);
    onChange(newValue, 'tasks_list');
    const newRows: ITasks[] = [];
    //let index = 0;
    if (Array.isArray(newValue) && newValue.length > 0) {
      tasksList.forEach((task: any) => {
        if (newValue.includes(task.id)) {
          //index++;
          newRows.push({
            number: task.id,
            task_name: task.name,
            client: task.companyName,
            status: true,
          });
        }
      });
    }
    setRows(newRows);
    setTimeout(() => {
      setRefreshTable(false);
    }, 10);
  };

  const getRowsData = (rowsData: any) => {
    setRowsFromTable(rowsData);
  };

  return (
    <>
      <h2>Asignación de tareas</h2>
      <p>Elige las tareas que podrá visualizar el usuario</p>
      <div className="user-form__main user-form__main--tasks">
        <Container maxWidth="sm">
          <form method="POST" onSubmit={formOnSubmit} id="active-form-3">
            <div className="fields-group">
              <div className="field-item">
                <MultipleSelect
                  data={tasksListForSelect}
                  label={'Tareas'}
                  onChange={(
                    _event: SelectChangeEvent<any>,
                    newValue?:
                      | MultipleDataInterface
                      | string
                      | undefined
                      | string[]
                  ) => {
                    if (newValue) onChangeSelect(newValue);
                    else onChangeSelect([]);
                  }}
                  placeholder={'Seleccionar tarea'}
                  required
                />
              </div>
            </div>
          </form>
        </Container>
        <div className="tasks__main">
          {!refreshTable ? (
            <Table columns={columns} rows={rows} getRowsData={getRowsData} />
          ) : (
            <LoaderLocal />
          )}
        </div>
      </div>
    </>
  );
};

export default TasksForm;
