import React, { useState, useEffect } from 'react';
import Accordion from 'components/Atoms/Accordion/Accordion';
import { MultipleDataInterface } from 'components/Atoms/MultipleSelect/MultipleSelect';
import AddIcon from '@mui/icons-material/Add';
import FilterOption from 'components/Organisms/Table/FilterOption';
import MultiCheckbox, {
  MulticheckboxItemsInterface,
} from 'components/Atoms/Multicheckbox/Multicheckbox';
import {
  ColumnInterface,
  FilterValueInterface,
} from 'components/Organisms/Table/Table.utils';
import TasksService from '../services/TasksService';

interface MaterialsSectionInterface {
  filter: any;
  changeFilter: (name: string, newFilter: any) => void;
  dataMaterials?: any;
  name?: string;
  subName: string;
  actualData: any[];
}

interface MaterialsFormInterface {
  data: any;
  onSubmit: (e: any) => void;
  formId: string;
}

const columns: ColumnInterface[] = [
  {
    id: 'outfit',
    label: 'Vestimenta',
    prop: 'outfit',
  },
  {
    id: 'general_materials',
    label: 'Materiales generales',
    prop: 'general_materials',
  },
  {
    id: 'transport',
    label: 'Vehículo',
    prop: 'transport',
  },
];

const addDataForSelect = (dataForSelect: MultipleDataInterface[], row: any) => {
  dataForSelect.push({
    text: row.label,
    value: row.value,
    type: row.id,
  });
};

const getDataForSelect = (data: any, prop: string) => {
  const dataForSelect: MultipleDataInterface[] = [];

  data.forEach((row: any) => {
    if (row.value.toLowerCase().includes(prop.toLowerCase())) {
      addDataForSelect(dataForSelect, row);
    }
  });

  const removeDuplicates = dataForSelect.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.text === value.text)
  );
  return Array.from(removeDuplicates);
};

const MaterialView = ({
  filter,
  changeFilter,
  dataMaterials,
  name,
  subName,
  actualData,
}: MaterialsSectionInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemsForSelect, setItemsForSelect] = useState<string[]>([]);
  const [selectData, setSelectData] = useState<any>([]);

  useEffect(() => {
    if (dataMaterials.length > 0) loadActualData();
  }, [dataMaterials]);

  const loadActualData = () => {
    if (actualData.length > 0) {
      const newData: any[] = [];
      const iForSelect: any[] = [];
      dataMaterials.forEach((item: any) => {
        const isFound = actualData.find((el) => el.name === item.label);
        if (isFound) {
          newData.push({
            text: item.label,
            value: item.label,
            id: isFound.id,
          });
          iForSelect.push(item.label);
        }
      });
      setSelectData(newData);
      changeItemsForSelect(iForSelect);
      if (newData.length > 0) {
        changeFilter(subName, newData);
      }
    }
  };

  const changeItemsForSelect = (newItems: string[] = []) => {
    setItemsForSelect(newItems);
  };
  const openOptionOnClick = () => {
    setIsOpen(!isOpen);
  };

  const changeSelectFilter = (newFilter: FilterValueInterface) => {
    if (itemsForSelect.length > 0) {
      const newItems: MulticheckboxItemsInterface[] = [];
      itemsForSelect.forEach((item) => {
        newItems.push({
          text: item,
          value: item,
          id: dataMaterials.find((el: any) => el.label === item).id ?? 0,
        });
      });
      setSelectData(newItems);
      if (newItems.length > 0) {
        changeFilter(subName, newItems);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newItems: MulticheckboxItemsInterface[] = [
        {
          text: event.target.name,
          value: event.target.name,
        },
      ];
      if (itemsForSelect.length > 0) {
        itemsForSelect.forEach((item) => {
          newItems.push({
            text: item,
            value: item,
          });
        });
      }
      setSelectData(newItems);
    } else {
      const newItems: string[] = itemsForSelect.filter(
        (item: string) => item !== event.target.name
      );
      changeItemsForSelect(newItems);
      if (selectData.length > 0 && newItems.length > 0) {
        const newList: MulticheckboxItemsInterface[] = [];
        newItems.forEach((item) => {
          newList.push({
            text: item,
            value: item,
          });
        });
        setSelectData(newList);
        const newFilter: string[] = filter.filter(
          (item: any) => item.text !== event.target.name
        );
        changeFilter(subName, newFilter);
      } else {
        setSelectData([]);
        setItemsForSelect([]);
        changeFilter(subName, []);
      }
    }
  };

  const saveFilterOnClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="select__main">
        <div className="select__button" onClick={openOptionOnClick}>
          <p>Seleccionar {name}</p>
          <AddIcon />
        </div>
        <div className="select__options">
          {isOpen && dataMaterials.length > 0 && (
            <>
              <FilterOption
                columns={columns}
                filter={filter}
                filterColumns={[subName]}
                isFilterOpen={isOpen}
                getDataForSelect={(prop: string, _search?: string | null) =>
                  getDataForSelect(dataMaterials, prop)
                }
                itemsForSelect={itemsForSelect}
                changeItemsForSelect={changeItemsForSelect}
                changeFilter={changeSelectFilter}
                isSimple
                type="checkbox"
                saveFilter={saveFilterOnClick}
              />
            </>
          )}
        </div>
        {itemsForSelect?.length > 0 && (
          <div className="checkboxes__list">
            <MultiCheckbox
              items={selectData}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
              }}
              itemsForSelect={itemsForSelect}
            />
          </div>
        )}
      </div>
    </>
  );
};

const MaterialsForm = ({ data, onSubmit, formId }: MaterialsFormInterface) => {
  const [filterG, setFilterG] = useState([]);
  const [filterO, setFilterO] = useState([]);
  const [filterT, setFilterT] = useState([]);
  const [dataMaterials, setDataMaterials] = useState([] as any);
  const [dataMaterialsG, setDataMaterialsG] = useState([] as any);
  const [dataMaterialsT, setDataMaterialsT] = useState([] as any);

  const loadDataMateriales = async () => {
    const responseMaterials = await TasksService.getMaterials();

    if (responseMaterials.data.length > 0) {
      console.log(responseMaterials.data);
      const dataOutfit: any = responseMaterials.data.find(
        (value: any) => value.name === 'Vestimenta'
      );
      setDataMaterials(setDataMaterial(dataOutfit));
      const dataOutfitG: any = responseMaterials.data.find(
        (value: any) => value.name === 'Materiales Generales'
      );

      setDataMaterialsG(setDataMaterial(dataOutfitG));
      const dataOutfitT: any = responseMaterials.data.find(
        (value: any) => value.name === 'Vehículo'
      );
      setDataMaterialsT(setDataMaterial(dataOutfitT));
    }
  };

  const setDataMaterial = (valor: any) => {
    const dataOufitMaterial: any = [];
    if (valor?.materials.length > 0) {
      valor.materials?.forEach((detail: any) => {
        const value = {} as any;
        value.label = detail.name;
        value.value = detail.name;
        value.materialCategoryId = detail.materialCategoryId;
        value.id = detail.id;
        value.type = detail.id;
        dataOufitMaterial.push(value);
      });
    } else {
      valor?.materialSubcategories.forEach((material: any) => {
        const value = {} as any;
        material?.materials.forEach((detail: any) => {
          value.label = detail.name;
          value.value = detail.name;
          value.materialCategoryId = detail.materialCategoryId;
          value.id = detail.id;
        });
        value.type = material.titleEs;
        dataOufitMaterial.push(value);
      });
    }

    return dataOufitMaterial;
  };

  useEffect(() => {
    loadDataMateriales();
  }, []);

  const changeFilter = (subName: string, newFilter: any) => {
    if (subName === 'general_materials') {
      setFilterG(newFilter);
    } else if (subName === 'outfit') {
      setFilterO(newFilter);
    } else if (subName === 'transport') {
      setFilterT(newFilter);
    }
  };

  const materialsOnSubmit = (e: any) => {
    e.preventDefault();

    const materialsIds: number[] = [];
    if (filterO.length > 0) {
      filterO.forEach((material: any) => {
        materialsIds.push(material.id);
      });
    }
    if (filterT.length > 0) {
      filterT.forEach((material: any) => {
        materialsIds.push(material.id);
      });
    }
    if (filterG.length > 0) {
      filterG.forEach((material: any) => {
        materialsIds.push(material.id);
      });
    }

    onSubmit(materialsIds);
  };

  return (
    <div className="materials__main">
      <p className="materials__tile">Materiales necesarios</p>
      <form onSubmit={materialsOnSubmit} id={formId}>
        <Accordion
          items={[
            {
              label: 'Vestimenta',
              children: (
                <MaterialView
                  name={'Vestimenta'}
                  subName="outfit"
                  filter={filterO}
                  dataMaterials={dataMaterials}
                  changeFilter={changeFilter}
                  actualData={data}
                />
              ),
            },
            {
              label: 'Materiales generales',
              children: (
                <MaterialView
                  name={'Materiales generales'}
                  subName="general_materials"
                  filter={filterG}
                  dataMaterials={dataMaterialsG}
                  changeFilter={changeFilter}
                  actualData={data}
                />
              ),
            },
            {
              label: 'Vehiculo',
              children: (
                <MaterialView
                  name={'Vehículo'}
                  subName="transport"
                  filter={filterT}
                  dataMaterials={dataMaterialsT}
                  changeFilter={changeFilter}
                  actualData={data}
                />
              ),
            },
          ]}
          icon="plus"
          noBorder
        />
      </form>
    </div>
  );
};

export default MaterialsForm;
