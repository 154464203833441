import React from 'react';
import { Button } from '../Button/Button';
//resources
import './BasicModal.scss';
import CloseIcon from '@material-ui/icons/Close';
import { MAIN_COLOR } from '../../../utils/utils';

interface ButtonInterface {
  buttonText?: string;
  children: React.ReactNode;
  closeColor?: string;
  title?: string;
  closeModal: () => void;
  buttonOnClick?: () => void;
  openModal?: () => void;
  className?: string;
}

const BasicModal = ({
  buttonText,
  children,
  closeColor = MAIN_COLOR,
  title,
  closeModal,
  buttonOnClick,
  className = '',
  ...rest
}: ButtonInterface) => {
  const btnOnClick = () => {
    if (buttonOnClick) buttonOnClick();
    closeModal();
  };

  return (
    <>
      {title ? (
        <div className="modal_main__header">
          <p className="modal_main__header__title">{title}</p>
          <p className="modal_main__header__close" onClick={closeModal}>
            <CloseIcon className="modal_main__header__close__icon" />
          </p>
        </div>
      ) : (
        <p className="modal_main__header__close" onClick={closeModal}>
          <CloseIcon className="modal_main__header__close__icon" />
        </p>
      )}
      <div className={`modal_main__content ${className}`}>{children}</div>
      {buttonText && (
        <div className="modal_main__footer">
          <Button
            fromModal
            large
            onClick={btnOnClick}
            text={buttonText}
            type="primary"
            size="large"
          />
        </div>
      )}
    </>
  );
};

export default BasicModal;
