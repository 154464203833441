/* eslint-disable prettier/prettier */
import React, { useState, useEffect, createRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import {Dialog} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'react-simple-hook-modal';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';
import {generateUniqueId, sleep} from 'utils/utils';
import { DataInterface } from 'components/Atoms/Select/Select';
import IconButton from 'components/Atoms/IconButton/IconButton';
import {
  CustomTableRef,
} from 'components/Organisms/Table/Table.utils';
import BasicModal from 'components/Templates/BasicModal/BasicModal';

import PaymentService from 'services/PaymentService';
import TurnsService from '../services/TurnsService';

import { ReactComponent as FileImportIcon } from 'assets/images/icons/file-import-green.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { transformDataForSelect } from '../Task.utils';
import ImportModal from 'components/Molecules/ImportModal/ImportModal';
import CreateTurnForm from './forms/CreateTurnForm';
import UpdateTurnForm from './forms/UpdateTurnForm';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import SimpleTable from "components-kit/Organisms/SimpleTable/SimpleTable";
import {
  ColumnSimpleTableSchema,
  CustomSimpleTableRef,
  DataSourceSimpleTableSchema
} from "components-kit/Organisms/SimpleTable/SimpleTable.types";
import {useNavigate} from "react-router-dom";
import {ActionSimpleTableInterface} from "components-kit/Organisms/SimpleTable/SimpleTableHeader";
import FilterDetail from "components-kit/Organisms/FilterDetail/FilterDetail";
import CertificationsTabFilterOption from "scenes/Tasks/Actions/components/TurnsTabFilterOption";
import {FilterDetailData} from "components-kit/Organisms/FilterDetail/FilterDetail.types";
import {SimpleActionItem} from "components-kit/Organisms/SimpleTable/SimpleActionMenuList";
import CountryService from "../../../services/CountryService";
import {useSelector} from "react-redux";
import TurnsTabLoading from "scenes/Tasks/Actions/components/TurnsTabLoading";

const columns: ColumnSimpleTableSchema[] = [
  {
    title: 'N°',
    index: 'id',
    align: 'left',
  },
  {
    title: 'Tienda',
    index: 'storeNameFormat',
    align: 'left',
  },
  {
    title: 'Fecha',
    index: 'startOfExecutionDateFormat',
    align: 'left',
  },
  {
    title: 'Hora inicio',
    index: 'startOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Hora fin',
    index: 'endOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Turno',
    index: 'turnModeFormat',
    align: 'left',
  },
  {
    title: 'Estado',
    index: 'statusFormat',
    align: 'left',
  },
  {
    title: 'Visibilidad',
    index: 'visibility',
    align: 'left',
  },
];

export interface TurnsTableI {
  taskId: number;
  permissions: any;
}

const TurnsTable = ({ taskId, permissions }: TurnsTableI) => {
  const mainTableTurnRef = createRef<CustomSimpleTableRef<any>>();
  const navigate = useNavigate();
  const [tableLoading, setTableLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const mainTableRef = createRef<CustomTableRef>();
  const [isFromCreateSingleTurn, setIsFromCreateSingleTurn] = useState(true);
  const [modalType, setModalType] = useState('create');
  const [modalData, setModalData] = useState<any>({});
  const [disabledFields, setDisabledFields] = useState(true);
  const [fieldsSelected, setFieldsSelected] = useState<any>([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState<DataInterface[]>(
    []
  );
  const [turnsList, setTurnsList] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const { isModalOpen, openModal, closeModal } = useModal();
  const { isModalOpen: isUpdateMassiveTurnModalOpen, openModal: openUpdateMassiveTurnModal, closeModal: closeUpdateMassiveTurnModal } = useModal();
  const [modalTitle, setModalTitle] = useState('Crear turno');
  const [data, setData] = useState([] as any);
  const [selectedImportFile, setSelectedImportFile] = useState<File>();
  const [selectedImportUpdateMassiveTurnFile, setSelectedImportUpdateMassiveTurnFile] = useState<File>();

  const [regionTitle, setRegionTitle] = useState('Región');
  const [cityTitle, setCityTitle] = useState('Ciudad');

  const userAuth = useSelector((state: any) =>
      state?.auth ? state?.auth : []
  );

  const [defaultCountryTimezone, setDefaultCountryTimezone] = useState('UTC');

  /* Filters */
  const [startDateFilter, setStartDateFilter] = useState(
      moment().add(-2, 'weeks').format('YYYY-MM-DD')
  );
  const [startDateFilterTemp, setStartDateFilterTemp] = useState(
      moment().add(-2, 'weeks').toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
      moment().add(2, 'weeks').format('YYYY-MM-DD')
  );
  const [endDateFilterTemp, setEndDateFilterTemp] = useState(moment().add(2, 'weeks').toDate());

  const [storeIdFilter, setStoreIdFilter] = useState<number | undefined>(
      undefined
  );
  const [storeIdFilterTemp, setStoreIdFilterTemp] =
      useState<DataInterface | null>(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [singleActionList, setSingleActionList] = useState<
      ActionSimpleTableInterface[]
      >([]);
  const [multipleActionList, setMultipleActionList] = useState<
      ActionSimpleTableInterface[]
      >([]);
  const [actionList, setActionList] = useState<SimpleActionItem[]>([]);

  const [selected, setSelected] = useState<string[]>([]);

  const [stores, setStores] = useState<DataInterface[]>([]);

  const [tableRefreshUqId, setTableRefreshUqId] = useState(generateUniqueId());

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      mainTableTurnRef.current?.fetchData(1);
    }
  }, [
    storeIdFilter,
    `${startDateFilter}-${endDateFilter}`,
  ]);

  useEffect(() => {
    if (!loading) {
      mainTableTurnRef.current?.fetchData();
    }
  }, [tableRefreshUqId]);

  const loadActions = () => {
    const toastOption = {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    } as any;

    const localActionList = [];
    const localSinglePermissions = [];
    const localMultiplePermissions = [];

    if(verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.LOOK_TURN_DATA)) {
      localSinglePermissions.push({
        value: '1',
        text: 'Ver',
        action: (selectedIds: any) => {
          const selectedId = selectedIds[0];
          showTurnData(selectedId);
        },
      });
    }

    if(verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.EDIT_TURN_DATA)) {
      localSinglePermissions.push({
        value: '2',
        text: 'Editar',
        action: (selectedIds: any) => {
          const selectedId = selectedIds[0];
          editTurnData(selectedId);
        },
      });
    }

    if(verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.UPDATE_TURN_STATUS)) {
      localMultiplePermissions.push({
        value: '23',
        text: 'Activar',
        action: (selectedIds: any) => {
          activateTurnMultiple(selectedIds.map((selectedRow: any) => parseInt(selectedRow)));
        },
      });
    }

    if(verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.UPDATE_TURN_STATUS)) {
      localMultiplePermissions.push({
        value: '24',
        text: 'Desactivar',
        action: (selectedIds: any) => {
          deactivateTurnMultiple(selectedIds.map((selectedRow: any) => parseInt(selectedRow)));
        },
      });
    }

    if(verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.UPDATE_TURN_STATUS)) {
      localActionList.push({ text: 'Desactivar', action: (row: any) => { deactivateTurn(row.id) }, fontColor: 'red', hidden: (row: any) => {
        return row.isActive;
        } });

      localActionList.push({ text: 'Activar', action: (row: any) => { activateTurn(row.id) }, fontColor: 'green', hidden: (row: any) => {
          return !row.isActive;
        } });
    }

    setActionList(localActionList);
    setSingleActionList(localSinglePermissions);
    setMultipleActionList(localMultiplePermissions);
  }

  const loadData = async () => {
    loadActions();

    const [countryResponse, storeResponse, responsePaymentMethodsList] = await Promise.all([
      CountryService.getCountryByCode(userAuth.countryCode),
      TurnsService.getStoresByTask(taskId),
      PaymentService.getPaymentsList()
    ]);


    if (storeResponse.success && countryResponse.success && responsePaymentMethodsList.success) {
      if(countryResponse.data.data?.mainTimezone) {
        setDefaultCountryTimezone(countryResponse.data.data?.mainTimezone);
      }

      setStores(
          storeResponse.data.map((storeRaw: any) => {
            return {
              text: storeRaw.name,
              value: `${storeRaw.id}`,
            };
          })
      );

      setStoresList(storeResponse.data);

      let localRegionTitle = 'Región';
      let localCityTitle = 'Ciudad';

      if (
          countryResponse.data?.data?.additionalData?.city_data?.region?.name?.es
      ) {
        localRegionTitle =
            countryResponse.data?.data?.additionalData?.city_data?.region?.name
                ?.es;
        setRegionTitle(localRegionTitle);
      }

      if (
          countryResponse.data?.data?.additionalData?.city_data?.city?.name?.es
      ) {
        localCityTitle =
            countryResponse.data?.data?.additionalData?.city_data?.city?.name?.es;
        setCityTitle(localCityTitle);
      }

        setPaymentMethodsList(
            transformDataForSelect(responsePaymentMethodsList.data)
        );

      setLoading(false);
    }
  };

  const importTurnsOnClick = () => {
    setModalTitle("Crear turnos (Masivo)")
    setIsFromCreateSingleTurn(false);
    openModal();
  };

  const openUpdateMassiveTurnsOnClick = () => {
    setSelectedImportUpdateMassiveTurnFile(undefined);
    openUpdateMassiveTurnModal();
  };

  const getTurnsList = async () => {
    const responseCertificados = await TurnsService.getTurnsByTask(taskId);

    if (responseCertificados.data?.data.length > 0) {
      const values = responseCertificados.data?.data.map((value: any) => {

        return {
          id: value.id,
          store: value.storeName,
          date: moment(value.startOfExecutionDate).format('DD-MM-YYYY'),
          start_time: moment(value.startOfExecutionDate).format('hh:mm A'),
          end_time: moment(value.endOfExecutionDate).format('hh:mm A'),
          turn: value.turnMode ? value.turnMode.toUpperCase() : '',
          status: value.isActive === true ? '1' : '0',
          actions: [
            {
              label: value.isActive === true ? 'Desactivar' : 'Activar',
              onClick: (row: any) =>
                actionRow(value.isActive ? 'deactivate' : 'activate', row),
              fontColor: value.isActive === true ? 'red' : 'green',
            },
          ],
        };
      });

      return values;
    }

    return [];
  };

  const actionRow = async (isActive: string, row: any) => {
    await sendSelectedAction(isActive, [row]);
  };

  const createTurnsOnClick = () => {
    setModalTitle('Crear turno');
    setIsFromCreateSingleTurn(true);
    setModalType('create');
    openModal();
  };

  const getTurnInfo = async (id: number) => {
    console.log("satisfaccion")
    setModalData({});
    const response = await TurnsService.getTurnById(id);
    console.log("so weird", response.data)
    if (response.data) {
      const data = response.data;
      let newPaymentTypes = [];
      if (data.paymentTypes.length > 0) {
        newPaymentTypes = data.paymentTypes.map((paymentType: any) => {
          return {
            paymentTypeId: {
              text: paymentType.paymentTypeName,
              value: paymentType.paymentTypeId,
            },
            amount: paymentType.amount,
          };
        });
      }
      setModalData({
        store: data.storeId,
        start_date: data.startOfExecutionDateLocalFormat,
        start_date_two: data.startOfExecutionDate,
        start_time: data.startOfExecutionTimeLocalFormat,
        end_time: data.endOfExecutionTimeLocalFormat,
        people_quantity: data.availableTurns,
        turn: data.turnMode,
        id: data.id,
        region: data.storeRegionName,
        storeId: data.storeId,
        city: data.storeCityName,
        paymentTypes: newPaymentTypes,
        isPrivate: data.isPrivate,
        original_quantity_required: data.originalQuantityRequired || 0,
        store_city_timezone: data.storeCityTimezone,
        raw_start_date: data.startOfExecutionDate,
        raw_end_date: data.endOfExecutionDate
      });
      sleep(50);
    }
  };

  const showTurnData = async (turnId: number) => {
    await getTurnInfo(turnId);
    setModalTitle('Ver turno');
    setModalType('show');
    setDisabledFields(true);
    openModal();
  }

  const editTurnData = async (turnId: number) => {
    await getTurnInfo(turnId);
    setModalTitle('Editar turno');
    setModalType('edit');
    setDisabledFields(false);
    openModal();
  }

  const sendSelectedAction = async (action: string, data: any) => {
    const actionName = action === 'activate' ? 'activaron' : 'desactivaron';
    if (data.length > 0) {
      const promises = data.map(({ id }: any) => {
        return TurnsService.selectedActionByTaskId(action, id);
      });

      Promise.all(promises)
        .then(() => {
          document?.getElementById('secret-update-turns-button')?.click();

          toast.success(`Se ${actionName} los turnos satisfactoriamente`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        })
        .catch((err: any) => {
          toast.warning(`¡UPS! Un error ha ocurrido`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    }
  };

  const cancelOnClick = () => {
    setModalData({});
    closeModal();
  };

  const importOnClick = async () => {
    if (selectedImportFile) {
      const importToastId = toast.info('Cargando información ...', {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });

      const response = await TurnsService.importMassiveTurnsByTaskId(
        taskId,
        selectedImportFile
      );

      toast.dismiss(importToastId);

      if (response.success) {
        toast.success('Se crearon los turnos satisfactoriamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });

        refreshDataTable();

        cancelOnClick();
      }
    } else {
      toast.error('Requiere cargar un archivo excel (.xlsx).', {
        position: 'top-right',
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const fileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      setSelectedImportFile(e.target.files[0]);
  };

  const downloadTemplateOnClick = async () => {
    const downloadToastId = toast.info('Iniciando la descarga ...', {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });

    const response =
      await TurnsService.downloadTemplateToCreateMassiveTurnsByTask(taskId);

    toast.dismiss(downloadToastId);

    if (response.data) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        'create_massive_turns_template_by_task.xlsx'
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const refreshDataTable = () => {
    setTableRefreshUqId(generateUniqueId());
  };

  const fetchData = async (
      pageSize: number,
      currentPage: number
  ): Promise<DataSourceSimpleTableSchema<any>> => {
    setTableLoading(true);

    const turnsResponse =
        await TurnsService.getTurnsByTaskWithFilters(
            taskId,
            startDateFilter,
            endDateFilter,
            currentPage,
            pageSize,
            storeIdFilter ? [storeIdFilter] : []
        );

    setTableLoading(false);

    if (turnsResponse.success) {
      //hh:mm A
      const turnsData = turnsResponse.data.data.map((row: any) => {
        const style = 'filled';
        let color = 'red';
        let textColor = 'red';

        if (row.isActive) {
          color = 'purple';
          textColor = 'purple';
        }

        return {
          ...row,
          storeNameFormat: (
              <p
                  className="hyperlink-row"
                  onClick={() => {
                    console.log('Send store id: ', row.storeId);
                    // navigate(`/stores/${row.storeId}`);
                  }}
              >{`${row.storeName}`}</p>
          ),
          startOfExecutionDateFormat: moment(row.startOfExecutionDate).format(
              'DD-MM-YYYY'
          ),
          startOfExecutionTimeFormat: moment(row.startOfExecutionDate).format(
              'hh:mm A'
          ),
          endOfExecutionTimeFormat: moment(row.endOfExecutionDate).format(
              'hh:mm A'
          ),
          turnModeFormat: (row.turnMode ? row.turnMode.toUpperCase() : ''),
          statusFormat: (
              <div
                  className={`status__main status__${style} status__${style}--bg-${color}`}
              >
                <p className={`status__text status__text--${textColor}`}>
                  {row.isActive ? 'Activo' : 'Inactivo'}
                </p>
              </div>
          ),
          visibility: (
              <>
                {
                  !row.isPrivate && (
                        <div
                            className={`status__main status__filled status__filled--bg-darkgray`}
                        >
                          <p className={`status__text status__text--black`}>
                            Pública
                          </p>
                        </div>
                    )
                }
                {
                    row.isPrivate && (
                        <div
                            className={`status__main status__filled status__filled--bg-green`}
                        >
                          <p className={`status__text status__text--white`}>
                            Privada
                          </p>
                        </div>
                    )
                }
              </>
          )
        };
      });

      return {
        items: turnsData,
        totalRows: parseInt(turnsResponse.data.totalRowsFiltered),
      };
    }

    return {
      items: [],
      totalRows: 0,
    };
  };

  const clearFilters = () => {
    setStoreIdFilter(undefined);
  };

  const removeFilter = (key: string) => {
    switch (key) {
      case 'STORE_FILTER':
        setStoreIdFilter(undefined);
        break;
    }
  };

  const executeAdditionalFilters = async (
      startDateParam: string,
      endDateParam: string,
      storeParam: number | undefined,
  ) => {
    setStartDateFilter(startDateParam);
    setEndDateFilter(endDateParam);
    setStoreIdFilter(storeParam);
  };

  const getActiveFilters = (): FilterDetailData[] => {
    const sd = moment(startDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const ed = moment(endDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');

    const filters: FilterDetailData[] = [
      {
        key: 'DATE_RANGE_FILTER',
        label: 'Fecha',
        detail: `Del ${sd} al ${ed}`,
        disableRemove: true,
      },
    ];

    if (storeIdFilter) {
      const storeFilter = stores.find((storeLocal) => {
        return Number.parseInt(storeLocal.value) === storeIdFilter;
      });

      if (storeFilter) {
        filters.push({
          key: 'STORE_FILTER',
          label: 'Punto de venta',
          detail: storeFilter.text,
        });
      }
    }

    return filters;
  };

  const activateTurn = async (turnId: number) => {
    const response = await TurnsService.activateTurn(turnId);

    if(response.success) {
      toast.success(`Se activó el turno con ID: ${turnId} satifactoriamente`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      refreshDataTable()
    }
  }

  const deactivateTurn = async (turnId: number) => {
    const response = await TurnsService.deactivateTurn(turnId);

    if(response.success) {
      toast.success(`Se desactivó el turno con ID: ${turnId} satifactoriamente`,{
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      refreshDataTable()
    }
  }

  const activateTurnMultiple = async (turnIds: number[]) => {
    const errorTurnIds: number[] = [];

    await Promise.all(
        turnIds.map(async (turnId) => {
          try {
            return await TurnsService.activateTurn(turnId);
          } catch (error) {
            errorTurnIds.push(turnId);
            console.error(`Error en el turno ${turnId}:`, error);
          }
        })
    );

    const successTurnIds = turnIds.filter((id) => {
      return !errorTurnIds.includes(id)
    });

    console.log("Ids que no actualizaron su estado", errorTurnIds);

    if(successTurnIds.length === 0) {
      toast.info(`No se encontró ningún turno apto para esta opción`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }else if(successTurnIds.length === 1) {
      toast.success(`Se activó el turno con ID: ${successTurnIds[0]} satifactoriamente`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }else {
      toast.success(`Se activaron los turnos con IDs: ${successTurnIds.join(', ')}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    refreshDataTable()
  }

  const deactivateTurnMultiple = async (turnIds: number[]) => {
    const errorTurnIds: number[] = [];

    await Promise.all(
        turnIds.map(async (turnId) => {
          try {
            return await TurnsService.deactivateTurn(turnId);
          } catch (error) {
            errorTurnIds.push(turnId);
            console.error(`Error en el turno ${turnId}:`, error);
          }
        })
    );

    const successTurnIds = turnIds.filter((id) => {
      return !errorTurnIds.includes(id)
    });

    console.log("Ids que no actualizaron su estado", errorTurnIds);

    if(successTurnIds.length === 0) {
      toast.info(`No se encontró ningún turno apto para esta opción`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }else if(successTurnIds.length === 1) {
      toast.success(`Se desactivó el turno con ID: ${successTurnIds[0]} satifactoriamente`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }else {
      toast.success(`Se desactivaron los turnos con IDs: ${successTurnIds.join(', ')}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    refreshDataTable()
  }

  const updateMassiveTurnFileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      setSelectedImportUpdateMassiveTurnFile(e.target.files[0]);
  };

  const downloadUpdateMassiveTurnTemplateOnClick = async () => {
    const downloadTempToastId = toast.info('Iniciando la descarga ...', {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });


    const response =
        await TurnsService.getTemplateFotUpdateTurnsQuantityRequiredColumnByTask(
            taskId,
            startDateFilter,
            endDateFilter,
            storeIdFilter ? [storeIdFilter] : []
        );


    toast.dismiss(downloadTempToastId);

    if (response.data) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
          'download',
          'update_turns_required_client_data_template_by_task.xlsx'
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const updateMassiveTurnsOnClick = async () => {
    if (selectedImportUpdateMassiveTurnFile) {
      const tempToastId = toast.info('Cargando información ...', {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });

      const response = await TurnsService.updateTurnOriginalQuantityRequiredColumnMassiveMode(
          selectedImportUpdateMassiveTurnFile
      );

      toast.dismiss(tempToastId);

        if (response.success) {
          toast.success('Se realizó la operación de manera satisfactoria.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          closeUpdateMassiveTurnModal();
        }
      } else {
        toast.error('Requiere cargar un archivo excel (.xlsx).', {
          position: 'top-right',
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        });
      }

  };

  return (
    <>
      <Container>
        <div className="turns__main">
          {!loading &&
              verifyPermissions(
                  permissions,
                  ROLE_PERMISSIONS.tasks.LOOK_TURN_DATA
              ) && (
                  <>

                    <div className="header__options">
                      <div
                          className="first__options"
                      >
                        <div className="searchbar__main">
                          <div className="searchbar__content">
                            <Button
                                className="additional__buttons__simple__table"
                                onClick={() => { createTurnsOnClick() }}
                                text={'Crear turno'}
                                type="primary"
                                startIcon={<AddIcon />}
                                variant="outlined"
                                border="square"
                                large
                            />
                          </div>
                        </div>
                      </div>
                      <div className="second__options">
                        <CertificationsTabFilterOption
                            isFilterOpen={isFilterOpen}
                            setIsFilterOpen={setIsFilterOpen}
                            startDate={startDateFilterTemp}
                            currentStartDate={startDateFilter}
                            setStartDate={setStartDateFilterTemp}
                            endDate={endDateFilterTemp}
                            currentEndDate={endDateFilter}
                            storeId={storeIdFilterTemp}
                            setStoreId={setStoreIdFilterTemp}
                            currentStoreId={storeIdFilter}
                            setEndDate={setEndDateFilterTemp}
                            submit={executeAdditionalFilters}
                            stores={stores}
                        />

                        <div className="turns-options">
                          {verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.CUSTOMER_REQUIREMENTS) && (
                              <IconButton
                                  onClick={openUpdateMassiveTurnsOnClick}
                                  className={`filter-button`}
                                  ariaLabel="update_massive"
                                  tooltipText={'Editar requerimiento de clientes'}
                              >
                                <EditIcon />
                              </IconButton>
                          )}
                          {verifyPermissions(permissions, ROLE_PERMISSIONS.tasks.CREATE_TURN) && (
                              <IconButton
                                  onClick={importTurnsOnClick}
                                  className={`filter-button`}
                                  ariaLabel="import"
                                  tooltipText={'Importar turnos'}
                              >
                                <FileImportIcon />
                              </IconButton>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* filter details */}
                    <FilterDetail
                        filters={getActiveFilters()}
                        cleanFilters={clearFilters}
                        removeFilter={removeFilter}
                        loading={tableLoading}
                    />

                    <SimpleTable
                        ref={mainTableTurnRef}
                        columns={columns}
                        request={fetchData}
                        initialDefaultPageSize={5}
                        loading={tableLoading}
                        selected={selected}
                        setSelected={setSelected}
                        singularSelectedItemTitle="turno"
                        pluralSelectedItemTitle="turnos"
                        singleActionsTable={singleActionList}
                        multiActionsTable={multipleActionList}
                        id="id"
                        actionsList={actionList}
                    />
                  </>
              )}
          {loading && <TurnsTabLoading/>}
        </div>
        <Dialog
          className="modal__main turns__modal__main"
          onClose={closeModal}
          open={isModalOpen}
        >
          <BasicModal title={modalTitle} closeModal={closeModal}>
            {isFromCreateSingleTurn ? (
              <div className="turns-form__main">
                {modalType === 'create' ? (
                  <CreateTurnForm
                    disabledFields={disabledFields}
                    cancelOnClick={cancelOnClick}
                    taskId={taskId}
                    storesList={storesList}
                    paymentMethodsList={paymentMethodsList}
                    onSubmit={() => {
                      closeModal();
                      refreshDataTable();
                    }}
                    regionTitle={regionTitle}
                    cityTitle={cityTitle}
                  />
                ) : modalData.id ? (
                  <UpdateTurnForm
                    disabledFields={disabledFields}
                    cancelOnClick={cancelOnClick}
                    taskId={taskId}
                    storesList={storesList}
                    paymentMethodsList={paymentMethodsList}
                    data={modalData.id ? modalData : {}}
                    onSubmit={() => {
                      closeModal();
                      refreshDataTable();
                    }}
                    regionTitle={regionTitle}
                    cityTitle={cityTitle}
                    defaultCountryTimezone={defaultCountryTimezone}
                  />
                ) : null}
              </div>
            ) : (
              <div className="create-multi-turns__main">
                <ImportModal
                  fileOnChange={fileOnChange}
                  importOnClick={importOnClick}
                  downloadTemplateOnClick={downloadTemplateOnClick}
                  acceptFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                />
              </div>
            )}
          </BasicModal>
        </Dialog>
        <Dialog
            className="modal__main turns__modal__main"
            onClose={closeUpdateMassiveTurnModal}
            open={isUpdateMassiveTurnModalOpen}
        >
          <BasicModal title="Editar requerimiento de clientes (Masivo)" closeModal={closeUpdateMassiveTurnModal}>
            <div className="create-multi-turns__main">
              <ImportModal
                  fileOnChange={updateMassiveTurnFileOnChange}
                  importOnClick={updateMassiveTurnsOnClick}
                  downloadTemplateOnClick={downloadUpdateMassiveTurnTemplateOnClick}
                  acceptFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              />
            </div>
          </BasicModal>
        </Dialog>
      </Container>
    </>
  );
};

export default TurnsTable;
