import React from 'react';
import { Container } from '@material-ui/core';

import './Link.scss';

interface LinkInterface {
  className?: string;
  disabled?: boolean;
  large?: boolean;
  text: string | React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function handleClickEvent(
  event?: React.MouseEvent<HTMLDivElement, MouseEvent>
) {
  event?.preventDefault();
}

const Link = ({
  className = '',
  disabled = false,
  large = true,
  text,
  onClick = handleClickEvent,
}: LinkInterface) => {
  if (className) className = ` ${className}`;

  return large ? (
    <p
      className={`link__main${
        disabled ? ' link__main--disabled' : ''
      }${className}`}
      onClick={disabled ? handleClickEvent : onClick}
    >
      {text}
    </p>
  ) : (
    <Container maxWidth="sm">
      <p
        className={`link__main${
          disabled ? ' link__main--disabled' : ''
        }${className}`}
        onClick={disabled ? handleClickEvent : onClick}
      >
        {text}
      </p>
    </Container>
  );
};

export default Link;
