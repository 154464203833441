import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ReactComponent as CheckDocSvg } from '../../../assets/images/icons/check-doc.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './DocItem.scss';

export interface MenuItemOptionsInterface {
  label: string;
  onClick: () => void;
}

interface DocItemInterface {
  text: string;
  extraText?: string;
  menuOptions: MenuItemOptionsInterface[];
}

const DocItem = ({ text, extraText = '', menuOptions }: DocItemInterface) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ItemOnClick = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  const open = Boolean(anchorEl);
  return (
    <div className="doc-item__main">
      <div className="doc-item__icon">
        <CheckDocSvg />
      </div>
      <div className="doc-item__text">
        <p>
          {`${text} `}
          {extraText ? <span>{extraText}</span> : ''}
        </p>
      </div>
      <div className="doc-item__options">
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          className="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {menuOptions?.length > 0 &&
            menuOptions.map(({ label, onClick }: MenuItemOptionsInterface) => (
              <MenuItem key={label} onClick={() => ItemOnClick(onClick)}>
                {label}
              </MenuItem>
            ))}
        </Menu>
      </div>
    </div>
  );
};

export default DocItem;
