import React, { useState } from 'react';
import { Accordion as MaterialAccordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import './Accordion.scss';

interface ItemInterface {
  label: string;
  children: React.ReactNode;
}

interface AccordionInterface {
  items: ItemInterface[];
  icon?: string;
  startExpanded?: boolean;
  noBorder?: boolean;
}

const Accordion = ({
  items,
  icon = 'expand',
  startExpanded = false,
  noBorder = false,
}: AccordionInterface) => {
  const [expanded, setExpanded] = useState(startExpanded ? 1 : 0);

  const handleChange = (expan: number) => {
    let accordionExpanded = expan;
    if (expanded === expan) accordionExpanded = 0;
    setExpanded(accordionExpanded);
  };

  return (
    <div
      className={`accordion__main${
        icon === 'plus' ? ' accordion__main--plus' : ''
      }${noBorder ? ' accordion__main--no-border' : ''}`}
    >
      {items?.length > 0 &&
        items.map(({ label, children }, index) => {
          const iconExpanded =
            expanded === index + 1 ? <RemoveIcon /> : <AddIcon />;
          return (
            <MaterialAccordion
              key={index + 1}
              expanded={expanded === index + 1}
              onChange={() => handleChange(index + 1)}
            >
              <AccordionSummary
                expandIcon={icon === 'plus' ? iconExpanded : <ExpandMoreIcon />}
                aria-controls={`panel-${label}-content`}
                id={`panel-${label}-header`}
              >
                <p className="accordion-label">{label}</p>
              </AccordionSummary>
              <AccordionDetails>{children}</AccordionDetails>
            </MaterialAccordion>
          );
        })}
    </div>
  );
};

export default Accordion;
