import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import IconButton from 'components/Atoms/IconButton/IconButton';
import { Button } from 'components/Atoms/Button/Button';
import Accordion from 'components/Atoms/Accordion/Accordion';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select, { DataInterface } from 'components-kit/Atoms/Select/Select';

interface CertificationsTabFilterOptionSchema {
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
  grayColor?: boolean;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  currentStartDate: string;
  currentEndDate: string;
  regionId: DataInterface | null;
  setRegionId: (value: DataInterface | null) => void;
  currentRegionId: string | undefined;
  cityId: DataInterface | null;
  setCityId: (value: DataInterface | null) => void;
  currentCityId: string | undefined;
  submit: (
    startDate: string,
    endDate: string,
    region: string | undefined,
    city: string | undefined
  ) => void;
  regions: DataInterface[];
  cities: DataInterface[];
  regionTitle: string;
  cityTitle: string;
}

const CertificationsTabFilterOption = ({
  startDate,
  endDate,
  isFilterOpen,
  setIsFilterOpen,
  grayColor = false,
  setStartDate,
  setEndDate,
  currentStartDate,
  currentEndDate,
  regionId,
  setRegionId,
  currentRegionId,
  cityId,
  setCityId,
  currentCityId,
  submit,
  regions,
  cities,
  regionTitle,
  cityTitle,
}: CertificationsTabFilterOptionSchema): JSX.Element => {
  const actionClickFilterContainer = () => {
    if (!isFilterOpen) {
      //Asignar los valores actuales
      setStartDate(moment(currentStartDate, 'YYYY-MM-DD').toDate());
      setEndDate(moment(currentEndDate, 'YYYY-MM-DD').toDate());

      if (currentRegionId) {
        const currentRegion = regions.find((regionLocal) => {
          return regionLocal.value === currentRegionId;
        });

        if (currentRegion) {
          setRegionId(currentRegion);
        } else {
          setRegionId(null);
        }
      } else {
        setRegionId(null);
      }

      if (currentCityId) {
        const currentCity = cities.find((cityLocal) => {
          return cityLocal.value === currentCityId;
        });

        if (currentCity) {
          setCityId(currentCity);
        } else {
          setCityId(null);
        }
      } else {
        setCityId(null);
      }
    }

    setIsFilterOpen(!isFilterOpen);
  };

  const cancelOnClick = () => {
    setIsFilterOpen(false);
  };

  const submitOnClick = async () => {
    const startDateParam = moment(startDate).format('YYYY-MM-DD');
    const endDateParam = moment(endDate).format('YYYY-MM-DD');
    let regionParam = undefined;
    let cityParam = undefined;

    if (regionId) {
      regionParam = regionId.value;
    }

    if (cityId) {
      cityParam = cityId.value;
    }

    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error(
        'La primera fecha del rango no debe de ser mayor a la fecha final',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return;
    }

    await submit(startDateParam, endDateParam, regionParam, cityParam);
    setIsFilterOpen(false);
  };

  const startOnChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const endOnChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  return (
    <>
      {/* Button */}
      <IconButton
        className={`filter-button${grayColor ? ' filter-button--gray' : ''}`}
        onClick={actionClickFilterContainer}
      >
        <FilterIcon />
      </IconButton>

      {/* Container */}
      <div
        id="filter-menu"
        className={`filter-menu${isFilterOpen ? ' filter-menu--open' : ''}${
          !actionClickFilterContainer ? ' filter-menu--no-handle' : ''
        }`}
      >
        <div className="label-filter-menu-item">
          <p>Filtros adicionales</p>
        </div>

        {/* Filter: dates */}
        <div className="filter-menu-item" key="filter-dates">
          <Accordion
            startExpanded
            items={[
              {
                label: 'Fecha',
                children: (
                  <>
                    <div className="dates__main">
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={startDate}
                          onChange={startOnChange}
                        />
                      </div>
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={endDate}
                          onChange={endOnChange}
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        <div className="filter-menu-item" key="filter-regions">
          <Accordion
            items={[
              {
                label: regionTitle,
                children: (
                  <>
                    <div className="dates__main">
                      <Select
                        className="filter__selector__simple__table"
                        data={regions}
                        name="region"
                        value={regionId}
                        onChange={(_e: any, newValue: any) => {
                          setRegionId(newValue);
                        }}
                        placeholder={'-SELECCIONE-'}
                        required
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Filter: Cities */}

        <div className="filter-menu-item" key="filter-cities">
          <Accordion
            items={[
              {
                label: cityTitle,
                children: (
                  <>
                    <div className="dates__main">
                      <Select
                        className="filter__selector__simple__table"
                        data={cities}
                        name="city"
                        value={cityId}
                        onChange={(_e: any, newValue: any) => {
                          setCityId(newValue);
                        }}
                        placeholder={'-SELECCIONE-'}
                        required
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Botones: submit */}
        <div className="filter-footer">
          <Button
            onClick={cancelOnClick}
            text={'Cerrar'}
            type="primary"
            variant="outlined"
            border="square"
            large
          />
          <Button
            onClick={submitOnClick}
            text={'Aplicar'}
            type="primary"
            variant="contained"
            border="square"
            large
          />
        </div>
      </div>
    </>
  );
};

export default CertificationsTabFilterOption;
