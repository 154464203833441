import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';

import AppTemplate from 'components/Templates/App/App';

import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/left-arrow-green.svg';
import AvatarImg from 'assets/images/avatar-img.png';
import './TaskerSelected.scss';
import BasicBreadcrumbs, {
  ItemInterface,
} from 'components/Atoms/Breadcrumbs/Breadcrumbs';
import BasicTabs from 'components/Molecules/Tabs/Tabs';
import UserForm from './UserForm';
import VehicleForm from './VehicleForm';
import TaskersService from '../services/TaskersService';
import {
  transformDataForSelect,
  transformDataBankForSelect,
  transformDataAccountForSelectNew,
  transformDataVehicleTypeForSelect,
  transformDataAdditionalForSelectNew,
} from '../TaskersUtils';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import LoaderLocal from 'components/Atoms/LoaderLocal/LoaderLocal';
import { AdditionalProperty } from 'utils/utils';

const TaskerSelected = () => {
  const { id } = useParams<{ id: string }>();
  const { helper } = useSelector((state: any) => state);
  const [detailSelected, setDetailSelected] = useState<any>(null);
  const [bankList, setBank] = useState<any>(null);
  const [typeAccountsList, settypeAccountsList] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [vehiclesType, setvehiclesType] = useState<any>(null);
  const [additionalFieldsVehicle, setadditionalFieldsVehicle] =
    useState<any>(null);
  const [contract, setcontract] = useState<any>(null);
  const [disabledForm, setDisabledForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const documentTypes = transformDataForSelect(helper.document_types);
  const [additionalProperties, setAdditionalProperties] = useState<
    AdditionalProperty[]
  >([]);
  const permissions = useSelector((state: any) =>
    state.auth?.position?.permissions ? state.auth?.position?.permissions : []
  );
  const history = useNavigate();

  useEffect(() => {
    const userId = parseInt(id || '') || 0;
    loadData(userId);
  }, []);

  const validPermissions = () => {
    return (
      verifyPermissions(
        permissions,
        ROLE_PERMISSIONS.taskers.LOOK_TASKER_DATA
      ) ||
      verifyPermissions(permissions, ROLE_PERMISSIONS.taskers.EDIT_TASKER_DATA)
    );
  };
  const loadData = async (userId: number) => {
    const [
      getDetailResponse,
      getBank,
      getAccountBank,
      getTypeVehicle,
      getAdditionalFieldsVechile,
      getContract,
    ] = await Promise.all([
      TaskersService.getTaskerById(userId),
      TaskersService.getAvailablesBank(),
      TaskersService.getAvailablesAccountType(),
      TaskersService.getAvailablesVehicleType(),
      TaskersService.getAdditionalFieldsVechile(),
      TaskersService.getContract(userId),
    ]);
    if (getDetailResponse.success) {
      setDetailSelected(getDetailResponse.data);
      if (getDetailResponse.data.additionalFields.length > 0) {
        setAdditionalProperties(getDetailResponse.data.additionalFields);
      }
    }
    if (getBank.success) {
      setBank(transformDataBankForSelect(getBank.data));
    }
    if (getAccountBank.success) {
      settypeAccountsList(
        transformDataAccountForSelectNew(getAccountBank.data)
      );
    }
    if (getTypeVehicle.success) {
      setvehiclesType(transformDataVehicleTypeForSelect(getTypeVehicle.data));
    }
    if (getAdditionalFieldsVechile.success) {
      setadditionalFieldsVehicle(getAdditionalFieldsVechile.data);
    }
    if (getContract.success) {
      setcontract(getContract.data);
    }
    setLoading(false);
  };
  const onSubmitPersonalDataForm = async (form: any) => {
    const response = await TaskersService.updateUserData(form);
    if (response.data) {
      //TODO: success message
    } else {
      //TODO: failure message
    }
  };
  const onSubmitVehicleDataForm = async (form: any) => {
    const response = await TaskersService.updateVehicleData(form);
    if (response.data) {
      //TODO: success message
    } else {
      //TODO: failure message
    }
  };
  const uploadFile = async (file: File): Promise<string | null> => {
    setDisabledForm(true);
    const responseUploadVehicleFile = await TaskersService.uploadVehicleFile(
      file
    );

    if (!responseUploadVehicleFile.success) {
      setDisabledForm(false);
      return null;
    }
    setDisabledForm(false);
    return responseUploadVehicleFile.data.s3Key;
  };

  const cancelOnClick = () => {
    console.log('e');
  };

  const breadcrumbsItems: ItemInterface[] = [
    {
      text: 'Taskers',
      link: '/taskers',
    },
    {
      text: 'Taskers',
      link: '/taskers',
    },
    {
      text: currentTab === 0 ? 'Datos personales' : 'Vehículo',
      link: '/create-certification',
    },
  ];

  const goBack = () => {
    history('/taskers');
  };

  const changeCurrentTab = (tab: number) => {
    setCurrentTab(tab);
  };

  const tabsData = [
    {
      label: 'Datos personales',
      children: (
        <UserForm
          bankList={bankList}
          documentTypes={documentTypes}
          typeAccountsList={typeAccountsList}
          data={detailSelected}
          contracts={contract}
          onSubmit={onSubmitPersonalDataForm}
        />
      ),
    },
    {
      label: 'Vehículo',
      children: (
        <VehicleForm
          data={detailSelected}
          vehiclesTypeList={vehiclesType}
          additionalFieldsVehicle={additionalFieldsVehicle}
          additionalProperties={additionalProperties}
          setAdditionalProperties={setAdditionalProperties}
          uploadFile={uploadFile}
          onSubmit={onSubmitVehicleDataForm}
        />
      ),
    },
  ];

  return (
    <AppTemplate>
      {detailSelected && !loading ? (
        <Container>
          <div className="tasker-selected-options">
            <div className="left-options">
              <div className="go-back-main" onClick={goBack}>
                <LeftArrowIcon />
                <span>Volver</span>
              </div>
            </div>
            <div className="right-options">
              <BasicBreadcrumbs items={breadcrumbsItems} color="secondary" />
            </div>
          </div>
          <div className="tasker-selected-avatar">
            <div className="avatar-image">
              <img src={AvatarImg} alt="user avatar" />
            </div>
            <p className="avatar-name">{detailSelected.profile.name}</p>
          </div>
          <div className="tasker-selected-tabs">
            <BasicTabs data={tabsData} changeCurrentTab={changeCurrentTab} />
          </div>
          <div className="extra__buttons__main">
            <Button
              onClick={cancelOnClick}
              text={'Cancelar'}
              type="primary"
              size="large"
              border="circular"
            />
            {verifyPermissions(
              permissions,
              ROLE_PERMISSIONS.taskers.EDIT_TASKER_DATA
            ) && (
              <Button
                text={'Guardar'}
                type="submit"
                size="large"
                variant="contained"
                border="circular"
                submit
                form={`tasker-selected-form-${currentTab}`}
              />
            )}
          </div>
        </Container>
      ) : (
        <LoaderLocal />
      )}
    </AppTemplate>
  );
};

export default TaskerSelected;
