import { useSelector } from 'react-redux';
import { Permission } from 'models/Permission';

export const hasPermissions = (permissions: string | string[]) => {
  const currentUser = useSelector((state: any) => state.auth);

  if (currentUser.id === 0) {
    return false;
  }

  const currentPermissions: Permission[] =
    currentUser.position?.permissions || [];

  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  if (permissions.length === 0) {
    return true;
  } else {
    let check = 0;

    for (let permission of permissions) {
      permission = permission.trim().toUpperCase();

      if (
        currentPermissions.find((currentPermission: Permission) => {
          return currentPermission.id === permission;
        })
      ) {
        check++;
      }
    }

    return check === permissions.length;
  }
};
