import React from 'react';
import { Link } from '@mui/material';
import FileUpload from 'components/Atoms/FileUpload/FileUpload';
import Text from 'components/Atoms/Text/Text';

import './ImportModal.scss';

interface ImportModalInterface {
  fileOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  importOnClick?: () => void;
  downloadTemplateOnClick: () => void;
  acceptFiles?: string;
  loading?: boolean;
}

const ImportModal = ({
  fileOnChange,
  importOnClick,
  downloadTemplateOnClick,
  acceptFiles = '*',
  loading,
}: ImportModalInterface) => {
  return (
    <div className="import-schedule__main">
      <Text className="import-schedule-title" text="1. Descargar plantilla" />
      <Text
        className="import-schedule-text"
        text={
          <>
            Una vez descargada la plantilla excel, remplazar los datos de prueba
            con los requeridos.{' '}
            <Link
              onClick={downloadTemplateOnClick}
              className={'cursor-pointer-link'}
            >
              Descargar plantilla
            </Link>
          </>
        }
      />
      <Text className="import-schedule-title" text="2. Cargar Archivo" />
      <Text
        className="import-schedule-text"
        text="Seleccione el archivo con los datos ingresados, luego de clic en subir información."
      />
      <FileUpload
        onChange={fileOnChange}
        withTextField
        importOnClick={importOnClick}
        acceptFiles={acceptFiles}
        loading={loading}
      />
    </div>
  );
};

export default ImportModal;
