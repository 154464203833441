/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { AdditionalProperty } from './AdditionalProperty';
import TextInput from '../../../components/Atoms/TextInput/TextInput';
import { InputAdornment } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TaskersService from '../services/TaskersService';
import { toast } from 'react-toastify';
import { t } from '../../../utils/i18n';
import DocItem from 'components/Molecules/DocItem/DocItem';
import { Dialog } from '@mui/material';
import { useModal } from 'react-simple-hook-modal';
import BasicModal from 'components/Templates/BasicModal/BasicModal';
import FileUpload from 'components/Atoms/FileUpload/FileUpload';
import '../Actions/TaskerSelected.scss';

interface CustomFileInputSchema {
  id: number;
  additionalProperties: AdditionalProperty[];
  setAdditionalProperties: (additionalProperties: AdditionalProperty[]) => void;
  text: string;
  uploadFile: (file: File) => Promise<string | null>;
}

const CustomFileInput = ({
  id,
  additionalProperties,
  text,
  setAdditionalProperties,
  uploadFile,
}: CustomFileInputSchema): JSX.Element => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [selectedImportFile, setSelectedImportFile] = useState<File>();
  const [isFromCreateSingleTurn, setIsFromCreateSingleTurn] = useState(true);
  const [bytes, setBytes] = useState<any>(null);
  const [typeFile, setTypeFileComplet] = useState('');
  const [typeF, setTypeF] = useState('');
  const importDocOnClick = () => {
    setIsFromCreateSingleTurn(false);
    openModal();
  };
  const viewDocOnClick = async () => {
    if (value) {
      setIsFromCreateSingleTurn(true);
      setLoading(true);
      await loadData();
      setLoading(false);
      openModal();
    } else {
      toast.warn(
        '¡UPS! No hay documento para visualizar, carga un documento',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    }
  };
  useEffect(() => {
    const property = additionalProperties.find((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });
    if (property) {
      setValue(property.value || '');
    }
  }, []);
    const loadData = async () => {
      if (value) {
        const val = value.split('.').pop();
        setTypeF(val ? val : '');
        if (val == 'pdf') {
          setTypeFileComplet(val ? 'application/' + val : '');
        } else {
          setTypeFileComplet(val ? 'image/' + val : '');
        }
        const response = await TaskersService.getDoc({
          key: value,
        });
        setBytes(response.data.base64Encoded);
      }
    };
  const fileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      setSelectedImportFile(e.target.files[0]);
  };
  const importOnClick = async () => {
    const propertyIdx = additionalProperties.findIndex((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });
    let isSuccessfully = false;
    if (selectedImportFile) {
      const newS3Key = await uploadFile(selectedImportFile);
      setValue(newS3Key || '');
      if (propertyIdx < 0) {
        additionalProperties.push({
          fieldSchemaId: id,
          value: newS3Key,
        });
        const newAdditionalProperties: AdditionalProperty[] = [
          ...additionalProperties,
        ];
        setAdditionalProperties(newAdditionalProperties);
      } else {
        const newAdditionalProperties: AdditionalProperty[] = [
          ...additionalProperties,
        ];
        newAdditionalProperties[propertyIdx] = {
          fieldSchemaId: id,
          value: newS3Key,
        };
        setAdditionalProperties(newAdditionalProperties);
      }

      if (newS3Key) {
        isSuccessfully = true;
      }
      if (isSuccessfully) {
        toast.success('Se cargo correctamente el documento', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.warn(
          '¡UPS! No pudo cargar el documento, inténtalo nuevamente por favor',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }
    }
  };
  const menuOptions = [
    {
      label: 'Ver',
      onClick: () => viewDocOnClick(),
    },
    {
      label: 'Adjuntar',
      onClick: () => importDocOnClick(),
    },
  ];

  return (
    <>
      <DocItem
        text={text + `: ${value!=''? ' si':' no'}`}
        menuOptions={menuOptions}
      />
      <Dialog
        className="modal__main turns__modal__main"
        onClose={closeModal}
        open={isModalOpen}
      >
        <BasicModal title={text} closeModal={closeModal}>
          <div className="form-col form-col--file-upload">
            {isFromCreateSingleTurn ? (
              <embed
                src={`data:application/${typeF};base64,${bytes}`}
                type={typeFile}
                width="100%"
                height="600px"
              />
            ) : (
              <div className="margin_input">
                <FileUpload
                  onChange={fileOnChange}
                  withTextField
                  importOnClick={importOnClick}
                  textfile="Cargar"
                  textInput="Seleccionar Documento"
                  typeFile="pdf"
                  withIcon="pdf"
                />
              </div>
            )}
          </div>
        </BasicModal>
      </Dialog>
    </>
  );
};

export default CustomFileInput;
