import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';

const AppService = {
  verifySession: async (token: string): Promise<BffApiResponse> => {
    try {
      const session = await api.get(`auth/current-user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return new BffApiResponse(true, session.data.data);
    } catch (error: any) {
      return formattingTitaskBffResponseErrors(error, [], false);
    }
  },
  logOut: async (): Promise<BffApiResponse> => {
    try {
      await api.post(`auth/logout`, {});
      return new BffApiResponse(true, {});
    } catch (error: any) {
      return formattingTitaskBffResponseErrors(error);
    }
  },
};

export default AppService;
