import { IAction } from '../../models/action.model';
import User from '../../../models/User';
import { DEFAULT_COUNTRY } from '../../../config/constants';
import { SIGN_IN, SIGN_OUT } from 'redux/actions/authActions/actionTypes';
import { UPDATE_AUTH_INFO } from '../../actions/authActions/actionTypes';

const defaultStore: User = {
  id: 0,
  email: '',
  token: '',
  countryCode: DEFAULT_COUNTRY,
  avatarS3Key: null,
  positionId: '',
  profile: undefined,
  create_user: null,
  modal_data: null,
  position: null,
  country: undefined,
};

export default function user(state: User = defaultStore, action: IAction) {
  switch (action.type) {
    case SIGN_IN:
      return {
        id: action.payload.id,
        email: action.payload.email,
        positionId: action.payload.positionId,
        countryCode: action.payload.countryCode,
        token: action.payload.token,
        avatarS3Key: action.payload.avatarS3Key,
        profile: action.payload.profile,
        position: action.payload.position,
        country: action.payload.country,
      };
    case UPDATE_AUTH_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        ...defaultStore,
      };
    default:
      return state;
  }
}
