import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import './LoaderLocal.scss';

const LoaderLocal = () => {
  return (
    <div className="loader-local__container">
      <Box className="loader-local__main" sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: 'rgba(37, 182, 191, 0.4)' }}
          size={40}
          thickness={4}
          value={100}
        />
        <CircularProgress
          sx={{ color: '#00AA80' }}
          disableShrink
          value={10}
          size={40}
          thickness={4}
        />
      </Box>
    </div>
  );
};

export default LoaderLocal;
