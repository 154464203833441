import React from 'react';
import { Chip } from '@mui/material';

import './FilterDetail.scss';
import { FilterDetailSchema } from 'components-kit/Organisms/FilterDetail/FilterDetail.types';

const FilterDetail = ({
  filters,
  cleanFilters,
  removeFilter,
  loading,
  removeFiltersNumber = false,
}: FilterDetailSchema): JSX.Element => {
  const showClean = () => {
    return (
      filters.filter((filterRow) => {
        return !filterRow.disableRemove;
      }).length > 0
    );
  };

  return (
    <div className="show-filters__main">
      {!removeFiltersNumber && (
        <div className="filters__options">
          <p
            className="filters-length"
            style={{
              display: filters.length === 0 ? 'none' : 'block',
            }}
          >
            Filtros: <span>{filters.length}</span>
          </p>
          {showClean() && (
            <div
              className="clean-filter__content"
              onClick={loading ? undefined : cleanFilters}
            >
              <p className="clean-filter">
                {loading ? 'Cargando...' : 'Limpiar Filtros'}
              </p>
            </div>
          )}
        </div>
      )}
      <div className="filters__items">
        {filters.map((filter) => (
          <Chip
            key={filter.key}
            label={
              <>
                <span className="key-text">{filter.label}: </span>
                <span className="value-text">{filter.detail}</span>
              </>
            }
            onDelete={
              filter.disableRemove || loading
                ? undefined
                : () => removeFilter(filter.key)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default FilterDetail;
