/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import BasicModal from 'components/Templates/BasicModal/BasicModal';
import { InputAdornment } from '@mui/material';
import Select from 'components/Atoms/Select/Select';
import TextInput from 'components/Atoms/TextInput/TextInput';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import DocItem from 'components/Molecules/DocItem/DocItem';
import { useNavigate } from 'react-router-dom';
import useForm from 'hooks/useForm';
import { ITypesList, OnChangeValueType } from 'utils/utils';
import TaskersService from '../services/TaskersService';
import { toast } from 'react-toastify';
import FileUpload from 'components/Atoms/FileUpload/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from 'utils/utils';
import moment from 'moment';

interface UserFormInterface {
  bankList: ITypesList[];
  typeAccountsList: ITypesList[];
  documentTypes: ITypesList[];
  data: any;
  contracts: any;
  onSubmit: (e: any) => void;
}

const INITIAL_EMPTY_FORM_VALUES = {
  name: '',
  last_name: '',
  document_number: '',
  birth_date: '',
  phone: '',
  email: '',
  genre: '',
  nacionality: '',
  state: '',
  commune: '',
  address: '',
  bank_name: {
    text: '',
    value: '',
  },
  account_type: {
    text: '',
    value: '',
  },
  number_account: '',
  document_type: {
    text: '',
    value: '',
  },
};
const UserForm = ({
  bankList,
  documentTypes,
  typeAccountsList,
  data = {},
  contracts,
  onSubmit,
}: UserFormInterface) => {
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { isModalOpen, openModal, closeModal } = useModal();
  const authUser = useSelector((state: any) => state.auth);
  const [modalTitle, setModalTitle] = useState('');
  const [contractDate, setContractDate] = useState(
    contracts
      ? contracts.contractDate.replace('T00:00:00.000Z', '')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState(false);
  const [bytes, setBytes] = useState<any>(null);
  const [selectedImportFile, setSelectedImportFile] = useState<File>();
  useEffect(() => {
    loadData();
  }, []);
  let userDocumentType: any;
  let bank: any;
  let userAccountType: any;
  let contratos: any;
  if (data.bankAccount) {
    userAccountType = typeAccountsList.find(
      (userAccountType: any) =>
        userAccountType.value === data.bankAccount.accountTypeId
    );
    bank = bankList.find((bank: any) => bank.value === data.bankAccount.bankId);
  }
  if (data.profile.documentType) {
    userDocumentType = documentTypes.find(
      (userDocumentType: any) =>
        userDocumentType.value === data.profile.documentType
    );
  }
  const menuOptions = [
    {
      label: 'Ver',
      onClick: () => viewDocs(),
    },
  ];

  const loadData = async () => {
    if (contracts != null) {
      const response = await TaskersService.getDoc({
        key: contracts.fileS3Key,
      });
      setBytes(response.data.base64Encoded);
    }
    if (authUser.countryCode == 'CL') {
      setDoc(true);
    }
  };
  const importOnClick = async () => {
    if (selectedImportFile) {
      const importToastId = toast.info('Cargando información ...', {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
      const response = await TaskersService.uploadContractTasker(
        selectedImportFile,
        contractDate,
        data.profile.userId
      );

      toast.dismiss(importToastId);

      if (response.data) {
        toast.success('Se registro el contrato satisfactoriamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setLoading(true);
        await sleep(50);
        setLoading(false);
        useNavigate();
      }
    } else {
      toast.error('Requiere cargar un archivo excel (.pdf).', {
        position: 'top-right',
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const viewDocs = async () => {
    if (bytes) {
      setLoading(true);
      setLoading(false);
      openModal();
      setModalTitle('Ver Documento');
    } else {
      toast.warn(
        '¡UPS! No hay documento para visualizar, carga un documento',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    }
  };
  if (contracts) {
    contratos = false;
  } else {
    contratos = true;
  }
  const { form, onChange, validFields } = useForm(
    data
      ? {
        name: data.profile.name ? data.profile.name : '',
        last_name: data.profile.lastname ? data.profile.lastname : '',
        document_type: {
          text: data.profile.documentType ? userDocumentType?.text : '',
          value: data.profile.documentType ? data.profile.documentType : '',
        },
        document_number: data.profile.documentNumber
          ? data.profile.documentNumber
          : '',
        birth_date: data.profile.birthDate ? data.profile.birthDate : null,
        phone: data.profile.phone ? data.profile.phone : '',
        email: data.email ? data.email : '',
        address: data.profile.address ? data.profile.address : '',
        account_type: {
          text: data.bankAccount ? userDocumentType?.text : '',
          value: data.bankAccount ? data.bankAccount.accountTypeId : '',
        },
        bank_name: {
          text: data.bankAccount ? bank.text : '',
          value: data.bankAccount ? data.bankAccount.bankId : '',
        },
        number_account: data.bankAccount
          ? data.bankAccount.accountNumber
          : '',
      }
      : INITIAL_EMPTY_FORM_VALUES
  );
  const fileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      setSelectedImportFile(e.target.files[0]);
  };
  const history = useNavigate();

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      const sendData = {
        profile: {
          address: form.address,
          birthDate: form.birth_date,
          documentNumber: form.document_number,
          documentType: form.document_type.value,
          nationality: data.countryCode,
          lastname: form.last_name,
          name: form.name,
          phone: form.phone,
          cityId: null,
          fullname: form.name + ' ' + form.last_name,
          userId: data.profile.userId,
        },
        bankAccount: {
          accountNumber: form.number_account,
          accountTypeId: form.account_type.value,
          bankId: form.bank_name.value,
          userId: data.profile.userId,
        },
      };
      let isSuccessfully = false;
      if (data) {
        const response = await TaskersService.updateTasker(
          data.profile.userId,
          sendData
        );
        if (response.statusCode === 200) {
          isSuccessfully = true;
        }
      }
      if (isSuccessfully) {
        toast.success('Se actualizaron los datos del tasker correctamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit(form);
        //history('/tasks');
      } else {
        toast.warn(
          '¡UPS! No se pudo actualizar esta tarea, inténtalo nuevamente por favor',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }
    }
  };
  return (
    <div className="tasker-form__main">
      <form method="POST" onSubmit={onSubmitForm} id="tasker-selected-form-0">
        <div className="fields-group fields-group--cols-2 fields-group--rows-2">
          <div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Nombre'}
              name="name"
              onChange={({ target }) => onChange(target.value, 'name')}
              placeholder={'Escribe el nombre del tasker'}
              value={form.name}
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Apellido'}
              name="last_name"
              onChange={({ target }) => onChange(target.value, 'last_name')}
              placeholder={'Escribe los apellidos del tasker'}
              value={form.last_name}
            />
          </div>
          <div className="field-item">
            <Select
              data={documentTypes}
              errors={errors}
              label={'Tipo de documento'}
              name="document_type"
              onChange={(_e: any, newValue?: OnChangeValueType) => {
                if (newValue) onChange(newValue, 'document_type');
                else onChange('', 'document_type');
              }}
              placeholder={'Selecciona un tipo de documento'}
              value={form.document_type}
              required
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              inputProps={{ required: true }}
              label={'Número Documento'}
              name="document_number"
              onChange={({ target }) =>
                onChange(target.value, 'document_number')
              }
              placeholder={`Escribe el ${form.document_number}`}
              defaultValue={form.document_number}
            />
          </div>
          <div className="field-item">
            <DatePicker
              label="Fecha de nacimiento"
              value={form.birth_date ? form.birth_date : null}
              onChange={(newValue?: Date | null) => {
                if (newValue) onChange(newValue.toString(), 'birth_date');
                else onChange('', 'birth_date');
              }}
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              label={'Celular'}
              name="phone"
              inputPropsAdornments={{
                startAdornment: (
                  <InputAdornment position="start">+56</InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                required: true,
              }}
              onChange={({ target }) => onChange(target.value, 'phone')}
              placeholder={'Escribe el celular'}
              value={form.phone}
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              label={'Correo electrónico'}
              name="email"
              onChange={({ target }) => onChange(target.value, 'email')}
              placeholder={'Escribe el correo electrónico'}
              value={form.email}
            />
          </div>
          <div className="field-item">
            <TextInput
              errors={errors}
              label={'Dirección'}
              name="address"
              onChange={({ target }) => onChange(target.value, 'address')}
              placeholder={'Escribe la dirección'}
              value={form.address}
            //defaultValue={data.address}
            />
          </div>
        </div>
        <div className="bank__main">
          <h2>Datos bancarios</h2>
          <div className="fields-group fields-group--cols-2 fields-group--rows-2">
            <div className="field-item">
              <Select
                data={bankList}
                errors={errors}
                label={'Banco'}
                name="bank_name"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChange(newValue, 'bank_name');
                  else onChange('', 'bank_name');
                }}
                value={form.bank_name}
                placeholder={'Selecciona un banco'}
                required
              />
            </div>
            <div className="field-item">
              <Select
                data={typeAccountsList}
                errors={errors}
                label={'Tipo de cuenta'}
                name="account_type"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChange(newValue, 'account_type');
                  else onChange('', 'account_type');
                }}
                value={form.account_type}
                placeholder={'Selecciona un tipo de cuenta'}
                required
              />
            </div>
            <div className="field-item">
              <TextInput
                errors={errors}
                label={'Número de cuenta'}
                name="number_account"
                onChange={({ target }) =>
                  onChange(target.value, 'number_account')
                }
                placeholder={'Escribe el número de cuenta'}
                value={form.number_account}
              />
            </div>
          </div>
        </div>
        <Modal
          modalClassName="modal__main clients-modal__main"
          id="clients-modal"
          isOpen={isModalOpen}
          transition={ModalTransition.SCALE}
        >
          <BasicModal title={modalTitle} closeModal={closeModal}>
            <div>
              <embed
                src={`data:application/pdf;base64,${bytes}`}
                type="application/pdf"
                width="100%"
                height="600px"
              />
              {/*<object
                data={`data:application/pdf;base64,${bytes}`}
                type="application/pdf"
                width="100%"
                height="600px"
            ></object>*/}
            </div>
          </BasicModal>
        </Modal>
      </form>
      {doc && (
        <div className="documents__main">
          <h2>Documentos</h2>
          <div className="fields-group fields-group--cols-2 fields-group--rows-2">
            {/*             <div className="field-item">
              <DocItem text="Cedula de ciudadanía" menuOptions={menuOptions} />
            </div>
            <div className="field-item">
              <DocItem
                text="Certificado de antecedentes"
                menuOptions={menuOptions}
              />
            </div> */}
            <div className="field-item">
              <DocItem
                text={`Contrato firmado: ${contratos ? ' no' : ' si'}`}
                menuOptions={menuOptions}
              />
            </div>
            <div className="form-col form-col--file-upload">
              <FileUpload
                onChange={fileOnChange}
                withTextField
                importOnClick={importOnClick}
                textfile="Cargar"
                textInput="Seleccionar Contrato"
                typeFile="pdf"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
