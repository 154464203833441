import React, { useState } from 'react';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import IconButton from 'components/Atoms/IconButton/IconButton';
import { Button } from 'components/Atoms/Button/Button';
import Accordion from 'components/Atoms/Accordion/Accordion';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select, { DataInterface } from 'components-kit/Atoms/Select/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface InscribedTabFilterOptionSchema {
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
  grayColor?: boolean;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  storeId: DataInterface | null;
  setStoreId: (value: DataInterface | null) => void;
  statusesSelected: string[];
  setStatusesSelected: (value: string[]) => void;
  currentStatusesSelected: string[];
  currentStoreId: number | undefined;
  currentStartDate: string;
  currentEndDate: string;
  submit: (
    startDate: string,
    endDate: string,
    store: number | undefined,
    statuses: string[]
  ) => void;
  stores: DataInterface[];
  statuses: DataInterface[];
}

const InscribedTabFilterOption = ({
  startDate,
  endDate,
  isFilterOpen,
  setIsFilterOpen,
  grayColor = false,
  setStartDate,
  setEndDate,
  currentStartDate,
  currentEndDate,
  storeId,
  setStoreId,
  currentStoreId,
  statusesSelected,
  setStatusesSelected,
  currentStatusesSelected,
  submit,
  stores,
  statuses,
}: InscribedTabFilterOptionSchema): JSX.Element => {
  const actionClickFilterContainer = () => {
    if (!isFilterOpen) {
      //Asignar los valores actuales
      setStartDate(moment(currentStartDate, 'YYYY-MM-DD').toDate());
      setEndDate(moment(currentEndDate, 'YYYY-MM-DD').toDate());
    }

    if (currentStoreId) {
      const currentStore = stores.find((storeLocal) => {
        return Number.parseInt(storeLocal.value) === currentStoreId;
      });

      if (currentStore) {
        setStoreId(currentStore);
      } else {
        setStoreId(null);
      }
    } else {
      setStoreId(null);
    }

    if (currentStatusesSelected) {
      setStatusesSelected([...currentStatusesSelected]);
    }

    setIsFilterOpen(!isFilterOpen);
  };

  const cancelOnClick = () => {
    setIsFilterOpen(false);
  };

  const submitOnClick = async () => {
    const startDateParam = moment(startDate).format('YYYY-MM-DD');
    const endDateParam = moment(endDate).format('YYYY-MM-DD');
    let storeParam = undefined;
    const statusesParam = [...statusesSelected];

    if (storeId) {
      storeParam = Number.parseInt(storeId.value);
    }

    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error(
        'La primera fecha del rango no debe de ser mayor a la fecha final',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return;
    }

    await submit(startDateParam, endDateParam, storeParam, statusesParam);
    setIsFilterOpen(false);
  };

  const startOnChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const endOnChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  return (
    <>
      {/* Button */}
      <IconButton
        className={`filter-button${grayColor ? ' filter-button--gray' : ''}`}
        onClick={actionClickFilterContainer}
      >
        <FilterIcon />
      </IconButton>

      {/* Container */}
      <div
        id="filter-menu"
        className={`filter-menu${isFilterOpen ? ' filter-menu--open' : ''}${
          !actionClickFilterContainer ? ' filter-menu--no-handle' : ''
        }`}
      >
        <div className="label-filter-menu-item">
          <p>Filtros adicionales</p>
        </div>

        {/* Filter: dates */}
        <div className="filter-menu-item" key="filter-dates">
          <Accordion
            startExpanded
            items={[
              {
                label: 'Fecha',
                children: (
                  <>
                    <div className="dates__main">
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={startDate}
                          onChange={startOnChange}
                        />
                      </div>
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={endDate}
                          onChange={endOnChange}
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Filter: stores */}

        <div className="filter-menu-item" key="filter-stores">
          <Accordion
            items={[
              {
                label: 'Tienda',
                children: (
                  <>
                    <div className="dates__main">
                      <Select
                        className="filter__selector__simple__table"
                        data={stores}
                        name="store"
                        value={storeId}
                        onChange={(_e: any, newValue: any) => {
                          setStoreId(newValue);
                        }}
                        placeholder={'-SELECCIONE-'}
                        required
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Filter: statuses */}

        <div className="filter-menu-item" key="filter-status">
          <Accordion
            items={[
              {
                label: 'Estado',
                children: (
                  <>
                    {statuses.map((statusRow) => (
                      <div
                        className="dates__main"
                        key={`container____${statusRow.value}`}
                        style={{ marginBottom: '20px' }}
                      >
                        <FormControlLabel
                          key={statusRow.value}
                          control={
                            <Checkbox
                              checked={statusesSelected.includes(
                                statusRow.value
                              )}
                              onChange={(e) => {
                                const statusesSelectedLocal = e.target.checked
                                  ? [
                                      ...(new Set([
                                        ...statusesSelected,
                                        statusRow.value,
                                      ]) as any),
                                    ]
                                  : statusesSelected.filter(
                                      (statusSelectedRow) => {
                                        return (
                                          statusSelectedRow !== statusRow.value
                                        );
                                      }
                                    );

                                console.log(
                                  'El poder =>',
                                  statusRow.value,
                                  e.target.checked,
                                  statusesSelectedLocal
                                );
                                setStatusesSelected(statusesSelectedLocal);
                              }}
                              name={`input__state_dinamic_${statusRow.value}`}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label={
                            <p
                              className="text__main"
                              style={{ fontSize: '14px' }}
                            >
                              {statusRow.text}
                            </p>
                          }
                          labelPlacement="start"
                          style={{ width: '100%' }}
                        />
                      </div>
                    ))}
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Botones: submit */}
        <div className="filter-footer">
          <Button
            onClick={cancelOnClick}
            text={'Cerrar'}
            type="primary"
            variant="outlined"
            border="square"
            large
          />
          <Button
            onClick={submitOnClick}
            text={'Aplicar'}
            type="primary"
            variant="contained"
            border="square"
            large
          />
        </div>
      </div>
    </>
  );
};

export default InscribedTabFilterOption;
