import { TASKER_SELECTED } from 'redux/actions/actionTypes';
import { ReducerInterface } from '../index.utils';

export const INITIAL_STATE = {
  tasker_selected: null,
};

export default (state = INITIAL_STATE, { type, payload }: ReducerInterface) => {
  switch (type) {
    case TASKER_SELECTED:
      return { ...state, tasker_selected: payload };
    default:
      return { ...state };
  }
};
