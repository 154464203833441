import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//  Components
import AppBar from '@material-ui/core/AppBar';
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//  Resources
import './Navbar.scss';
import avatarImg from 'assets/images/avatar-menu.png';
import LogoPng from 'assets/images/logo_go_by_touch.svg';

import { Avatar, Badge } from '@mui/material';
import IconButton from 'components/Atoms/IconButton/IconButton';
import { useSelector } from 'react-redux';
import {
  ITEM_LIST,
  getIcon,
  ItemsListInterface,
  NavbarInferface,
} from './Navbar.utils';
import ProfileService from 'scenes/Profile/services/ProfileService';
import { POSITIONS } from 'routes/ProtectedRoutes.utils';
import { hasPermissions } from 'utils/permissions';

const toggleDrawer: any = () => (event: any) => {
  if (
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }

  //setOpenDrawer(open);
};

const verifyPermissions = (positionId: string, show: any) => {
  if (show.length === 0) return true;
  return show.find((key: string) => key === positionId);
};

const ListItems = ({ items, withIcon, positionId }: any) => {
  const isActivePath = (item: ItemsListInterface) => {
    let currentPath = location.pathname.toLowerCase();
    currentPath = currentPath.startsWith('/')
      ? currentPath.substring(1, currentPath.length)
      : currentPath;
    let path = item.path.toLowerCase();
    path = path.startsWith('/') ? path.substring(1, path.length) : path;

    if (!item.allSimilarRoutes) {
      return currentPath === path;
    }

    return currentPath.startsWith(path);
  };

  return (
    <div
      className="top"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List className="list-main">
        {items.length > 0 &&
          items.map((item: any, index: number) => {
            // const isEnabledItem = verifyPermissions(
            //   positionId,
            //   item.show ?? []
            // );
            const isEnabledItem = hasPermissions(item.show ?? []);

            if (positionId === POSITIONS.LOCAL_ADMINISTRATOR || isEnabledItem) {
              return (
                <Link key={index} to={`/${item.path}`}>
                  <ListItem
                    className={`list-main__item${
                      isActivePath(item) ? ' list-main__item--active' : ''
                    }`}
                    button
                  >
                    {withIcon && (
                      <ListItemIcon className="list-main__item--icon">
                        {getIcon(item.path)}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      className="list-main__item--text"
                      primary={item.text}
                    />
                  </ListItem>
                </Link>
              );
            }
            return null;
          })}
      </List>
    </div>
  );
};

export const Navbar = ({
  withIcon = false,
  notAuth = false,
  logOutOnClick,
}: NavbarInferface) => {
  const { avatarS3Key, email, profile, positionId, position } = useSelector(
    (state: any) => state.auth
  );
  const [avatarImage, setAvatarImage] = React.useState(
    avatarS3Key || avatarImg
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useNavigate();
  const items: ItemsListInterface[] = ITEM_LIST;

  useEffect(() => {
    loadData();
  }, [history, notAuth]);

  useEffect(() => {
    if (avatarS3Key) {
      setAvatarImage(avatarS3Key);
      loadData();
    }
  }, [avatarS3Key]);

  const loadData = async () => {
    if (avatarS3Key) {
      const responseAvatar = await ProfileService.getAvatar({
        key: avatarS3Key,
      });
      if (responseAvatar.statusCode === 200) {
        setAvatarImage(
          `data:image/jpeg;base64,${responseAvatar.data.base64Encoded}`
        );
      }
    }
  };

  const goToProfile = () => {
    history('/profile');
  };

  const logOut = async () => {
    logOutOnClick();
    handleClose();
    history('/');
  };

  return (
    <div className="toolbar__main">
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar className="toolbar__main__content__go">
            <a href="#hamburguer" className="hamburguer__go">
              <div className="line__hbg"></div>
              <div className="line__hbg"></div>
              <div className="line__hbg"></div>
            </a>

            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
            >
              <IconButton ariaLabel="delete">
                <NotificationsIcon />
              </IconButton>
            </Badge>

            <div className="toolbar__profile__content">
              <Avatar
                className="toolbar__avatar"
                alt="avatar"
                src={avatarImage}
              />
              <p>{`${profile.name} ${profile.lastname}`}</p>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                className="profile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className="profile-menu__container">
                  <div className="profile-menu__content">
                    <p className="profile-menu__name">{`${profile.name} ${profile.lastname}`}</p>
                    <p className="profile-menu__email">{`${email}`}</p>
                  </div>
                  <div className="profile-menu__items">
                    <p className="profile-menu__option" onClick={goToProfile}>
                      Perfil
                    </p>
                    <p className="profile-menu__option" onClick={logOut}>
                      Cerrar sesión
                    </p>
                  </div>
                </div>
              </Menu>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        className="drawer__main"
        open={true}
        onClose={toggleDrawer(false)}
      >
        <div className="header_drawer">
          <div className="header_drawer__profile">
            <div className="header_drawer__profile-content">
              <Link to="/home">
                <img
                  className="toolbar__main__content__img"
                  src={LogoPng}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="menu_items__container">
          {
            <ListItems
              items={items}
              withIcon={withIcon}
              positionId={positionId}
            />
          }
        </div>
      </Drawer>
    </div>
  );
};
