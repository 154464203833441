export const TaskersListData = [
  {
    status: 0,
    name: 'Tasker Name',
    rut: '27097657-3',
    datetime: '17-02-2022 09:36',
    state: 'Metropolitana de Santiago',
    commune: 'Santiago',
  },
  {
    status: 1,
    name: 'Tasker Name',
    rut: '27097657-3',
    datetime: '17-02-2022 09:36',
    state: 'Metropolitana de Santiago',
    commune: 'Santiago',
  },
  {
    status: 2,
    name: 'Tasker Name',
    rut: '27097657-3',
    datetime: '17-02-2022 09:36',
    state: 'Metropolitana de Santiago',
    commune: 'Santiago',
  },
  {
    status: 0,
    name: 'Tasker Name',
    rut: '27097657-3',
    datetime: '17-02-2022 09:36',
    state: 'Metropolitana de Santiago',
    commune: 'Santiago',
  },
  {
    status: 1,
    name: 'Tasker Name',
    rut: '27097657-3',
    datetime: '17-02-2022 09:36',
    state: 'Metropolitana de Santiago',
    commune: 'Santiago',
  },
];
