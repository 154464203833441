import {
  FETCH,
  SAVE,
  UPDATE,
  REMOVE,
  SAVE_DOCUMENT_TYPES,
  SAVE_ROLES,
  SAVE_POSITIONS,
} from './actionTypes';

export function save(payload: any) {
  return {
    type: SAVE,
    payload,
  };
}

export function fetch(payload: any) {
  return {
    type: FETCH,
    payload,
  };
}

export function update(payload?: any) {
  return {
    type: UPDATE,
    payload,
  };
}

export function remove(payload: any) {
  return {
    type: REMOVE,
    payload,
  };
}

export function saveDocumentsType(payload: any) {
  return {
    type: SAVE_DOCUMENT_TYPES,
    payload,
  };
}

export function savePositions(payload: any) {
  return {
    type: SAVE_POSITIONS,
    payload,
  };
}
export function saveRoles(payload: any) {
  return {
    type: SAVE_ROLES,
    payload,
  };
}

const HelperAction = {
  save,
  fetch,
  update,
  remove,
  saveDocumentsType,
  savePositions,
  saveRoles,
};

export default HelperAction;
