import * as React from 'react';
import { Tooltip as ToolTipMaterial } from '@mui/material';
import './Tooltip.scss';

interface ToolTipInterface {
  children: React.ReactElement<any, any>;
  title: string | React.ReactElement<any, any>;
  placement?:
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | undefined;
  disableHoverListener?: boolean;
  className?: string;
  hidden?: boolean;
}

const Tooltip = ({
  children,
  title,
  placement = 'top-start',
  disableHoverListener = false,
  className = '',
}: ToolTipInterface) => {
  return (
    <ToolTipMaterial
      disableHoverListener={disableHoverListener}
      placement={placement}
      arrow
      title={title}
      classes={{ popper: `tooltip___go ${className}` }}
    >
      {children}
    </ToolTipMaterial>
  );
};

export default Tooltip;
