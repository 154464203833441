import React from 'react';
import { Dialog, Link } from '@mui/material';
import Text from 'components/Atoms/Text/Text';
import FileUpload from 'components/Atoms/FileUpload/FileUpload';
import BasicModal from 'components/Templates/BasicModal/BasicModal';

interface NewImportModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  fileOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  importOnClick?: () => void;
  downloadTemplateOnClick: () => void;
  acceptFiles?: string;
}

function NewImportModal({
  isModalOpen,
  closeModal,
  fileOnChange,
  importOnClick,
  downloadTemplateOnClick,
  acceptFiles = '*',
}: NewImportModalProps) {
  const downloadUri = () => {
    const url =
      'https://lookerstudio.google.com/reporting/4d3c9efb-f5d3-4cd1-b582-a444f44e89d9';
    window.open(url, '_blank');
  };

  return (
    <Dialog
      className="modal__main turns__modal__main"
      onClose={closeModal}
      open={isModalOpen}
    >
      <BasicModal
        title="Guía paso a paso para importar taskers"
        closeModal={closeModal}
      >
        <div className="create-multi-turns__main">
          <div className="import-schedule__main">
            <Text
              className="import-schedule-title"
              text="1. Descargar plantilla"
            />
            <Text
              className="import-schedule-text"
              text={
                <>
                  Una vez descargada la plantilla, seleccione los
                  identificadores (ids) los turnos a los cuales tiene interés
                  por asignar a taskers de la pestaña &#34;turns&#34;
                  {'  '}
                  <Link
                    onClick={downloadTemplateOnClick}
                    className={'cursor-pointer-link'}
                  >
                    Descargar plantilla
                  </Link>
                </>
              }
            />
            <Text
              className="import-schedule-title"
              text="2. Complete los ids de los taskers"
            />
            <Text
              className="import-schedule-text"
              text={
                <>
                  Una vez descargada la plantilla excel, remplazar los datos de
                  prueba con los ids de los taskers y turnos previamente
                  seleccionados en el paso 1.{'  '}
                  <Link onClick={downloadUri} className={'cursor-pointer-link'}>
                    Reporte de usuarios
                  </Link>
                </>
              }
            />
            <Text className="import-schedule-title" text="3. Cargar Archivo" />
            <Text
              className="import-schedule-text"
              text="Seleccione el archivo con los datos ingresados, luego de clic en subir información."
            />
            <FileUpload
              onChange={fileOnChange}
              withTextField
              importOnClick={importOnClick}
              acceptFiles={acceptFiles}
            />
          </div>
        </div>
      </BasicModal>
    </Dialog>
  );
}

export default NewImportModal;
