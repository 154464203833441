export default class BffApiResponse<T = any> {
  public success: boolean;
  public data: T;
  public statusCode: number;
  public errorMessage: string;
  public additionalData: any;

  constructor(
    success: boolean,
    data: any,
    statusCode = 200,
    errorMessage = '',
    additionalData = undefined
  ) {
    this.success = success;
    this.data = data;
    this.statusCode = statusCode;
    this.errorMessage = errorMessage;
    this.additionalData = additionalData;
  }
}
