import React, { useEffect, useState } from 'react';
import TextInput from '../../../components/Atoms/TextInput/TextInput';
import { AdditionalProperty } from './AdditionalProperty';
import { t } from '../../../utils/i18n';

interface CustomTextInputSchema {
  label: string;
  id: number;
  placeholder: string;
  errors: any;
  additionalProperties: AdditionalProperty[];
  setAdditionalProperties: (additionalProperties: AdditionalProperty[]) => void;
  isRequired: boolean;
  setErrors: (errors: any) => void;
  evalValidation: string;
  evalErrorMessage: string;
  disabled: boolean;
}

const CustomTextInput = ({
  id,
  label,
  placeholder,
  errors,
  additionalProperties,
  setAdditionalProperties,
  setErrors,
  isRequired,
  evalValidation,
  evalErrorMessage,
  disabled,
}: CustomTextInputSchema): JSX.Element => {
  const name = `additional_input_${id}`;
  const [value, setValue] = useState('');
  useEffect(() => {
    const property = additionalProperties.find((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });

    if (property) {
      setValue(property.value || '');
    }
  }, []);

  const handleChange = (newValue: string) => {
    const localErrors: any = { ...errors };
    setValue(newValue);

    const propertyIdx = additionalProperties.findIndex((additionalProperty) => {
      return additionalProperty.fieldSchemaId === id;
    });
    if (propertyIdx < 0) {
      additionalProperties.push({
        fieldSchemaId: id,
        value: newValue,
      });
      const newAdditionalProperties: AdditionalProperty[] = [
        ...additionalProperties,
      ];
      setAdditionalProperties(newAdditionalProperties);
    } else {
      const newAdditionalProperties: AdditionalProperty[] = [
        ...additionalProperties,
      ];
      newAdditionalProperties[propertyIdx] = {
        fieldSchemaId: id,
        value: newValue,
      };
      setAdditionalProperties(newAdditionalProperties);

      if (isRequired) {
        if (!newValue) {
          localErrors[name] = t(
            'scenes.my_vehicle.components.vehicle_form.messages.required_field'
          );
        } else {
          delete localErrors[name];
        }
      }
      setErrors(localErrors);
    }
  };

  return (
    <div className="auth__main__content__input">
      <TextInput
        inputProps={{
          required: isRequired,
        }}
        errors={errors}
        className="auth__field"
        label={label}
        name={name}
        onChange={({ target }) => {
          handleChange(target.value);
        }}
        placeholder={placeholder}
        type="text"
        errorClassName="vehicle-form-error-class"
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomTextInput;
