import React, { useEffect, useState } from 'react';
import AppTemplate from 'components/Templates/App/App';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';
import Title from 'components/Atoms/Title/Title';
import Stepper from 'components/Atoms/Stepper/Stepper';

import './Create.scss';
import TaskDataForm from './TaskDataForm';
import Link from 'components/Atoms/Link/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { ItemInterface } from 'components/Atoms/Breadcrumbs/Breadcrumbs';
import { useSelector } from 'react-redux';

const CreateTask = () => {
  const [formData, setFormData] = useState({});
  const steps = [''];
  const [activeStep, setActiveStep] = useState(1);
  const permissions = useSelector((state: any) =>
    state.auth?.position?.permissions ? state.auth?.position?.permissions : []
  );
  const history = useNavigate();
  const MAX_STEPS = 1;

  useEffect(() => {
    if (permissions.length === 0) history('/');
  }, []);

  const onSubmit = async (payload: any) => {
    if (activeStep <= MAX_STEPS) {
      setFormData({
        ...formData,
        ...payload,
      });
      setActiveStep(activeStep + 1);
    } else {
      const response: any = [];
      if (response.data.length > 0) {
        // success message
      }
    }
  };

  const goBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const cancelTabOnClick = () => {
    history('/tasks');
  };

  const breadcrumbsItems: ItemInterface[] = [
    {
      text: 'Tareas',
      link: '/tasks',
    },
    {
      text: 'Tareas / Crear',
      link: '/task/create',
    },
  ];

  return (
    <AppTemplate>
      <Container>
        <div className="title__main">
          <Title
            text="Crear Tarea"
            type="primary"
            breadcrumbsItems={breadcrumbsItems}
          />
        </div>
        <div className="create__users__main">
          <div className="stepper__create__users">
            <div className="stepper__content">
              <Stepper steps={steps} activeStep={activeStep} />
            </div>
          </div>
          <div className="create__users__template">
            {activeStep === 1 && (
              <TaskDataForm
                onSubmit={onSubmit}
                taskSelected={undefined}
                permissions={permissions}
                formId="current-tab-form-1"
              />
            )}
          </div>
        </div>
        <div className="create__users__btn__main">
          <div className="actions__btn__main">
            <div className="left__part">
              {activeStep > 1 && (
                <Button
                  onClick={goBack}
                  text={'Anterior'}
                  type="primary"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                  border="circular"
                  large
                />
              )}
              <Link text="Cancelar" onClick={cancelTabOnClick} />
            </div>
            <div className="right__part">
              {activeStep === 1 && (
                <Button
                  text={'Crear Tarea'}
                  type="submit"
                  size="large"
                  variant="contained"
                  border="circular"
                  submit
                  form="current-tab-form-1"
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </AppTemplate>
  );
};

export default CreateTask;
