export const TIMEZONE_LIST = [
  {
    text: 'AM',
    value: 'am',
  },
  {
    text: 'PM',
    value: 'pm',
  },
];

export const INITIAL_EMPTY_FORM_VALUES = {
  store: { text: '', value: '' },
  start_date: '',
  start_time: '',
  end_time: '',
  people_quantity: 0,
  turn: { text: '', value: '' },
  original_quantity_required: '',
};
