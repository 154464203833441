import React from 'react';
//resources
import CloseIcon from '@material-ui/icons/Close';
import { MAIN_COLOR } from '../../../utils/utils';
import { ReactComponent as AlertIcon } from 'assets/images/icons/alert-triangle.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-green.svg';
import './AlertModal.scss';
import { Button } from 'components-kit/Atoms/Button/Button';

export interface ButtonI {
  text: string;
  onClick: () => void;
  type: string | undefined;
  color: 'slow' | 'hard';
  loading?: boolean;
  hidden?: boolean;
}
interface AlertModalInterface {
  buttons?: ButtonI[];
  children: React.ReactNode;
  closeColor?: string;
  title?: string;
  closeModal: () => void;
  buttonOnClick?: () => void;
  openModal?: () => void;
  className?: string;
  withIcon?: boolean;
  type?: string;
  notClose?: boolean;
}

const AlertModal = ({
  buttons,
  children,
  closeColor = MAIN_COLOR,
  title,
  closeModal,
  className = '',
  withIcon = true,
  type = 'warning',
  notClose,
}: AlertModalInterface) => {
  const btnOnClick = (onClick: any) => {
    if (!notClose) {
      closeModal();
    }
    onClick();
  };

  return (
    <>
      {title ? (
        <div className="alert_main__header">
          <div className="alert_main__header__left-side">
            {withIcon && (
              <div className="alert_main--icon">
                {type === 'success' ? <CheckIcon /> : <AlertIcon />}
              </div>
            )}
            <p className="alert_main__header__title">{title}</p>
          </div>
          <p className="alert_main__header__close" onClick={closeModal}>
            <CloseIcon className="alert_main__header__close__icon" />
          </p>
        </div>
      ) : (
        <p className="alert_main__header__close" onClick={closeModal}>
          <CloseIcon className="alert_main__header__close__icon" />
        </p>
      )}

      <div className={`alert_main__content ${className}`}>{children}</div>
      <div className="alert_main__footer">
        {buttons &&
          buttons?.length > 0 &&
          buttons?.map((btn) => (
            <div
              className={`alert_main__button alert_main__button--${btn.color} ${type}`}
              key={btn.text}
              style={{ display: btn.hidden ? 'none' : undefined }}
            >
              <Button
                fromModal
                large
                onClick={() => btnOnClick(btn.onClick)}
                text={btn.text}
                type={btn.type}
                size="large"
                disabled={btn.loading}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default AlertModal;
