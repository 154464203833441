import axios, { AxiosInstance } from 'axios';
import { API_TITASK_URL } from '../config/constants';

const instance: AxiosInstance = axios.create({
  baseURL: API_TITASK_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const get = (url: string, config?: any) => {
  if (config) return instance.get(url, config);
  return instance.get(url);
};

const post = (url: string, payload: any, headers?: any) => {
  return instance.post(url, payload, { ...headers });
};

export { get, post };

export default instance;
