import React from 'react';
import { IconButton as MaterialIconButton } from '@mui/material';
import Tooltip from '../../../components-go/Atoms/Tooltip/Tooltip';
import './IconButton.scss';

interface IconButtonInterface {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  ariaLabel?: string;
  size?: 'small' | 'large' | 'medium' | undefined;
  tooltipText?: string;
}

const IconButton = ({
  className = '',
  children,
  onClick,
  disabled = false,
  ariaLabel = 'button',
  size = 'large',
  tooltipText,
}: IconButtonInterface) => {
  return tooltipText ? (
    <Tooltip title={tooltipText} placement="top">
      <MaterialIconButton
        onClick={onClick}
        disabled={disabled}
        className={`icon-button ${className}`}
        aria-label={ariaLabel}
        size={size}
      >
        {children}
      </MaterialIconButton>
    </Tooltip>
  ) : (
    <MaterialIconButton
      onClick={onClick}
      disabled={disabled}
      className={`icon-button ${className}`}
      aria-label={ariaLabel}
      size={size}
    >
      {children}
    </MaterialIconButton>
  );
};

export default IconButton;
